import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { ChargePoint, ChargePointEdge } from '../../graphql/generated/cp-graphql_types';
import { ChargepointService } from '../chargepoint-list.service';
import { FindError } from '../../../shared/errors';
import { HttpStatusCode } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';

@Component({
    selector: 'app-overview-card',
    templateUrl: './overview-card.component.html',
    styleUrls: ['./overview-card.component.scss'],
})
export class OverviewCardComponent implements OnChanges {
    // List of all Chargepoints
    @Input() chargepoints!: ChargePoint[];

    // Values for the Overview Cards
    privateChargepoints: number | undefined = undefined;

    publicChargepoints: number | undefined = undefined;

    freeChargepoints: number | undefined = undefined;

    chargedEnergy: number | undefined = undefined;

    errorMsgFind: string | undefined;

    constructor(private readonly chargepointService: ChargepointService, private readonly logger: NGXLogger) {
        logger.debug('ChargepointListComponent.constructor()');
    }

    ngOnChanges() {
        if (this.chargepoints.length > 0) {
            this.searchChargePointNumbers(this.chargepoints);
        }
    }

    private searchChargePointNumbers(result: ChargePoint[] | FindError) {
        if (result instanceof FindError) {
            this.handleError(result);
            return;
        }

        // this.chargepoints = result.map((edge: ChargePointEdge) => edge.node);
        this.logger.debug('ChargepointListComponent.searchChargePointNumbers(): chargepoints=', this.chargepoints);
        this.errorMsgFind = undefined;
        this.privateChargepoints = this.chargepoints.filter(cp => cp.owner.firstName !== undefined).length;
        this.publicChargepoints = this.chargepoints.filter(cp => cp.owner.name !== undefined).length;
        this.freeChargepoints = this.chargepoints.filter(cp => this.chargepointService.hasIdleEvse(cp)).length;
    }

    /* istanbul ignore next */
    /**
     * Handles find errors.
     * @param err The error occurred.
     */
    private handleError(err: FindError) {
        this.chargepoints = [];
        const { statuscode } = err;
        this.logger.debug('ChargepointListComponent.handleError(): statuscode=', statuscode);
        this.setErrorMsg(statuscode);
    }

    /* istanbul ignore next */
    /**
     * Maps the statuscode of an occurred error to a message.
     * @param statuscode The statuscode of the occurred error.
     */
    private setErrorMsg(statuscode: number) {
        // The real messages are defined in assets/i18n for translation
        switch (statuscode) {
            case HttpStatusCode.NotFound:
                this.errorMsgFind = 'notFoundChargepoints';
                break;
            case HttpStatusCode.TooManyRequests:
                this.errorMsgFind = 'tooManyRequests';
                break;
            case HttpStatusCode.GatewayTimeout:
                this.errorMsgFind = 'timeOut';
                this.logger.error('Is the app server running? Port forwarding?');
                break;
            default:
                this.errorMsgFind = 'unknownError';
                break;
        }
    }
}
