import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Invoice, User } from '../graphql/generated/accounting_graphql_types';
import { AccountingService } from '../service/accounting.service';
import { BreadcrumbService } from 'src/app/layout/breadcrumb/breadcrumb.service';

@Component({
    selector: 'app-accounting-user-period-list',
    templateUrl: './user-period-list.component.html',
    styleUrls: ['./user-period-list.component.scss'],
})
export class UserPeriodListComponent implements OnInit, OnDestroy {
    loadingPeriods: boolean = true;
    user: User = {} as User;
    invoices: Array<Pick<Invoice, 'id' | 'year' | 'month'>> = [];

    constructor(
        private readonly accountingService: AccountingService,
        private readonly route: ActivatedRoute,
        private breadcrumbService: BreadcrumbService,
    ) {}

    ngOnInit() {
        const userId = this.route.snapshot.params['id'];
        this.getInvoiceByUserId(userId).then((user: User) => {
            this.breadcrumbService.accountingCrumbs(2, user, userId);
        });
    }

    ngOnDestroy() {
        this.breadcrumbService.accountingCrumbs(0);
    }

    getInvoiceByUserId(userId: string): Promise<User> {
        const promise = new Promise<User>((resolve, reject) => {
            this.accountingService.getInvoicesByUserId(userId).subscribe({
                next: (value: any) => {
                    this.invoices = value.invoices.content;
                    this.loadingPeriods = false;
                    this.user.firstName = value.firstName;
                    this.user.lastName = value.lastName;
                    resolve(this.user);
                },
                error: e => reject(),
            });
        });
        return promise;
    }
}
