import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Invoice} from "../graphql/generated/accounting_graphql_types";

@Component({
  selector: 'app-accounting-user-period',
  template: `
      <div class="card accounting-card">
          <div class="card-body accounting-card-body d-flex flex-column justify-content-center align-items-center text-center">
              <h1 class="user-select-none">{{ 'accounting.invoiceCardTitle' | translate }}</h1>
              <a class="stretched-link user-select-none cursor-pointer" (click)="toPeriodInvoiceList()">
                  {{ date | localizedDate: 'date-month-year' }}
              </a>
          </div>
      </div>
  `,
  styleUrls: ['./user-period.component.scss']
})
export class UserPeriodComponent implements OnInit {
    @Input() invoice!: Pick<Invoice, 'id' | 'year' | 'month'>;
    date: Date = new Date();

    constructor(private readonly router: Router,
                private readonly route: ActivatedRoute) {
    }

    ngOnInit() {
        this.date = new Date(this.invoice.year, this.invoice.month - 1);
    }

    toPeriodInvoiceList() {
        const userId = this.route.snapshot.params['id'];
        this.router.navigate(['accounting', 'user', userId, 'invoices', this.invoice.id]);
    }
}
