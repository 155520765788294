import { Component, Input, OnInit } from '@angular/core';
import {
    AllCompaniesGQL,
    AllCompaniesQuery,
    AllUsersGQL,
    AllUsersQuery,
    Vehicle,
} from '../../graphql/generated/mdm_graphql_types';
import { AbstractControlOptions, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VehicleService } from '../vehicle-list.service';
import { DriverService } from '../../driver-list/driver-list.service';

@Component({
    selector: 'app-edit-vehicle-dialog',
    templateUrl: './edit-vehicle-dialog.component.html',
    styleUrls: ['./edit-vehicle-dialog.component.scss'],
})
export class EditVehicleDialogComponent implements OnInit {
    @Input() vehicleId: string | undefined;
    initialVehicle = {} as Vehicle;
    selectedCompanyIdTokenGroups: any = [];
    vehicleForm!: FormGroup;
    editing = new BehaviorSubject(false);
    errorMsg: string | undefined;
    companies: Observable<AllCompaniesQuery['companies']>;
    users: Observable<AllUsersQuery['users']>;

    constructor(
        private vehicleService: VehicleService,
        private driverService: DriverService,
        private logger: NGXLogger,
        private companiesQl: AllCompaniesGQL,
        private usersQl: AllUsersGQL,
        private fb: FormBuilder,
        public activeModal: NgbActiveModal,
    ) {
        logger.debug('EditVehicleDialogComponent.constructor()');
        logger.debug('data:', this.vehicleId);

        this.vehicleForm = this.fb.group(
            {
                companyId: [null],
                licensePlate: ['', [Validators.required, Validators.minLength(3)]],
                model: [null],
                owner: ['user'],
                ownerId: [null],
                vendor: [''],
                id: [''],
                version: [''],
            },
            {
                validator: this.ownerIdOrCompanyIdValidator,
            } as AbstractControlOptions,
        );

        this.companies = companiesQl.watch().valueChanges.pipe(map(result => result.data.companies));

        this.users = usersQl.watch().valueChanges.pipe(map(result => result.data.users));
    }
    get owner() {
        return this.vehicleForm.controls.owner;
    }

    get licensePlate() {
        return this.vehicleForm.controls.licensePlate;
    }

    ngOnInit() {
        if (this.vehicleId) {
            this.logger.debug('Load Vehicle from server ID=', this.vehicleId);
            this.vehicleService.getVehicle(this.vehicleId).subscribe((result: any) => {
                this.initialVehicle = result;
                this.setFormData(this.initialVehicle);
            });
        }
    }

    setPrevIdToNull(owner: string) {
        if (owner == 'user') {
            this.vehicleForm.controls['companyId'].setValue(null);
            this.vehicleForm.controls['companyId'].markAsUntouched();
        } else {
            this.vehicleForm.controls['ownerId'].setValue(null);
            this.vehicleForm.controls['ownerId'].markAsUntouched();
        }
    }

    ownerIdOrCompanyIdValidator(formGroup: FormGroup): ValidationErrors | null {
        const ownerId = formGroup.get('ownerId')?.value;
        const companyId = formGroup.get('companyId')?.value;

        if ((!ownerId && !companyId) || (ownerId && companyId)) {
            return {
                ownerOrCompanyError: 'Please select either a Person or a Company as Owner',
            };
        }
        return null;
    }

    addVehicle() {
        this.vehicleService
            .createVehicle({
                licensePlate: this.vehicleForm.value.licensePlate,
                model: this.vehicleForm.value.model,
                ownerId: this.vehicleForm.value.ownerId,
                companyId: this.vehicleForm.value.companyId,
                vendor: this.vehicleForm.value.vendor,
            })
            .subscribe((data: {}) => {
                this.logger.debug('create new Vehicle RC=', data);
                this.vehicleService.refresh();
                this.driverService.refresh();
            });
    }

    editVehicle() {
        this.vehicleService
            .updateVehicle({
                id: this.vehicleForm.value.id,
                licensePlate: this.vehicleForm.value.licensePlate,
                vendor: this.vehicleForm.value.vendor,
                model: this.vehicleForm.value.model,
                ownerId: this.vehicleForm.value.ownerId,
                companyId: this.vehicleForm.value.companyId,
                version: this.vehicleForm.value.version,
            })
            .subscribe((data: {}) => {
                this.logger.debug('edit Vehicle RC=', data);
                this.vehicleService.refresh();
                this.driverService.refresh();
            });
        this.isEditing(false);
    }

    isEditing(editStatus: boolean) {
        this.editing.next(editStatus);
    }

    setFormData(vehicle: Vehicle) {
        this.isEditing(true);
        this.getOwner(vehicle);
        this.vehicleForm.patchValue({
            ownerId: vehicle.owner?.id,
            vendor: vehicle.vendor,
            model: vehicle.model,
            licensePlate: vehicle.licensePlate,
            companyId: vehicle.company?.id,
            id: vehicle.id,
            version: vehicle.version,
        });
    }

    getOwner(vehicle: Vehicle) {
        if (!vehicle.owner && !vehicle.company) {
            return;
        } else if (vehicle.owner) {
            this.vehicleForm.patchValue({ owner: 'user' });
        } else if (vehicle.company) {
            this.vehicleForm.patchValue({ owner: 'company' });
        }
    }
}
