import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WaitingComponent } from './waiting.component';

@NgModule({
    declarations: [WaitingComponent],
    imports: [CommonModule, TranslateModule],
    exports: [WaitingComponent],
})
export class WaitingModule {}
