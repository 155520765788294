import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import {
    AllUsersGQL,
    AllUsersQuery,
    CreateUserGQL,
    CreateUserInput,
    DeleteUserGQL,
    OneUserGQL,
    OneUserQuery,
    UpdateUserGQL,
    UpdateUserInput,
} from '../graphql/generated/mdm_graphql_types';
import { QueryRef } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { User } from '../graphql/generated/mdm_graphql_types';

@Injectable()
export class DriverService {
    usersQueryRef: QueryRef<AllUsersQuery, {}> | undefined;

    constructor(
        private logger: NGXLogger,
        private allUsersGQL: AllUsersGQL,
        private oneUserGQL: OneUserGQL,
        private delUserGQL: DeleteUserGQL,
        private createUserGQL: CreateUserGQL,
        private updateUserGQL: UpdateUserGQL,
    ) {}

    flattenUsers(users: User[]) {
        let flatUserArray = [];
        let permissionArray = [];
        for (let i = 0; i < users.length; i++) {
            flatUserArray.push([
                users[i].id,
                users[i].firstName,
                users[i].lastName,
                users[i].vehicles.length,
                users[i].chargePoints.length,
                users[i].groups[0].idTokens.length,
            ]);
            permissionArray.push(1);
        }
        return [flatUserArray, permissionArray];
    }
    getUsers(): Observable<AllUsersQuery['users']> {
        this.usersQueryRef = this.allUsersGQL.watch();
        return this.usersQueryRef.valueChanges.pipe(map(result => result.data.users));
    }

    getUser(userId: string): Observable<OneUserQuery['user']> {
        return this.oneUserGQL.watch({ userId: userId }).valueChanges.pipe(map(result => result.data.user));
    }

    refresh() {
        this.logger.debug('call refresh for reloading data');
        this.usersQueryRef?.refetch();
    }
    deleteUser(users: string[]): Observable<any> {
        this.logger.debug('call service delete users with ids:', users);
        return this.delUserGQL.mutate({ userId: users });
    }
    createUser(user: CreateUserInput): Observable<any> {
        this.logger.debug('call service create User with userName', user);
        return this.createUserGQL.mutate({ createUser: user });
    }

    updateUser(user: UpdateUserInput): Observable<any> {
        this.logger.debug('call service update User with user-ID', user);
        return this.updateUserGQL.mutate({ updateUser: user });
    }
}
