import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An RFC-3339 compliant Full Date Scalar */
  Date: any;
  /** Instant data type support */
  Instant: any;
  /** A 64-bit signed integer */
  Long: any;
  /** A universally unique identifier compliant UUID Scalar */
  UUID: any;
}

export interface ChargePoint {
  __typename?: 'ChargePoint';
  client: Client;
  company?: Maybe<Company>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  owner?: Maybe<User>;
  version: Scalars['Long'];
}

export enum ChargingStatus {
  Charging = 'CHARGING',
  EvConnected = 'EV_CONNECTED',
  Idle = 'IDLE',
  SuspendedEv = 'SUSPENDED_EV',
  SuspendedEvse = 'SUSPENDED_EVSE'
}

export interface Client {
  __typename?: 'Client';
  id: Scalars['UUID'];
  name: Scalars['String'];
  version: Scalars['Long'];
}

export interface Company {
  __typename?: 'Company';
  client: Client;
  id: Scalars['UUID'];
  name: Scalars['String'];
  version: Scalars['Long'];
}

export interface CreateDocumentInput {
  format: DocumentFormat;
  name: Scalars['String'];
}

export interface CreateManualTransactionInput {
  companyId?: InputMaybe<Scalars['UUID']>;
  cost?: InputMaybe<Scalars['Float']>;
  date: Scalars['Date'];
  location?: InputMaybe<Scalars['String']>;
  receipt?: InputMaybe<CreateDocumentInput>;
  userId?: InputMaybe<Scalars['UUID']>;
}

export interface Document {
  __typename?: 'Document';
  format: DocumentFormat;
  id: Scalars['UUID'];
  name: Scalars['String'];
  version: Scalars['Long'];
}

export enum DocumentFormat {
  Gif = 'GIF',
  Jpg = 'JPG',
  Pdf = 'PDF',
  Png = 'PNG',
  Svg = 'SVG',
  Tiff = 'TIFF'
}

export interface IdToken {
  __typename?: 'IdToken';
  client: Client;
  company?: Maybe<Company>;
  createdAt: Scalars['Instant'];
  id: Scalars['UUID'];
  idTag: Scalars['String'];
  name: Scalars['String'];
  type: IdTokenType;
  user?: Maybe<User>;
}

export enum IdTokenType {
  Central = 'CENTRAL',
  EMaid = 'E_MAID',
  Iso14443 = 'ISO14443',
  Iso15693 = 'ISO15693',
  KeyCode = 'KEY_CODE',
  Local = 'LOCAL',
  MacAddress = 'MAC_ADDRESS',
  NoAuthorization = 'NO_AUTHORIZATION'
}

export interface ManualTransaction {
  __typename?: 'ManualTransaction';
  client: Client;
  company?: Maybe<Company>;
  cost?: Maybe<Scalars['Float']>;
  date: Scalars['Date'];
  id: Scalars['UUID'];
  location?: Maybe<Scalars['String']>;
  receipt?: Maybe<Document>;
  user?: Maybe<User>;
  version: Scalars['Long'];
}

export enum Measurand {
  CurrentExport = 'CURRENT_EXPORT',
  CurrentImport = 'CURRENT_IMPORT',
  CurrentOffered = 'CURRENT_OFFERED',
  EnergyActiveExportInterval = 'ENERGY_ACTIVE_EXPORT_INTERVAL',
  EnergyActiveExportRegister = 'ENERGY_ACTIVE_EXPORT_REGISTER',
  EnergyActiveImportInterval = 'ENERGY_ACTIVE_IMPORT_INTERVAL',
  EnergyActiveImportRegister = 'ENERGY_ACTIVE_IMPORT_REGISTER',
  EnergyApparentExport = 'ENERGY_APPARENT_EXPORT',
  EnergyApparentImport = 'ENERGY_APPARENT_IMPORT',
  EnergyApparentNet = 'ENERGY_APPARENT_NET',
  EnergyReactiveExportInterval = 'ENERGY_REACTIVE_EXPORT_INTERVAL',
  EnergyReactiveExportRegister = 'ENERGY_REACTIVE_EXPORT_REGISTER',
  EnergyReactiveImportInterval = 'ENERGY_REACTIVE_IMPORT_INTERVAL',
  EnergyReactiveImportRegister = 'ENERGY_REACTIVE_IMPORT_REGISTER',
  EnergyReactiveNet = 'ENERGY_REACTIVE_NET',
  Frequency = 'FREQUENCY',
  PowerActiveExport = 'POWER_ACTIVE_EXPORT',
  PowerActiveImport = 'POWER_ACTIVE_IMPORT',
  PowerFactor = 'POWER_FACTOR',
  PowerOffered = 'POWER_OFFERED',
  PowerReactiveExport = 'POWER_REACTIVE_EXPORT',
  PowerReactiveImport = 'POWER_REACTIVE_IMPORT',
  SoC = 'SO_C',
  Voltage = 'VOLTAGE'
}

export interface MeterValue {
  __typename?: 'MeterValue';
  id: Scalars['UUID'];
  timestamp: Scalars['Instant'];
  values: Array<SampledValue>;
}

export interface Mutation {
  __typename?: 'Mutation';
  createManualTransaction: ManualTransaction;
  deleteManualTransaction: Array<Scalars['UUID']>;
  updateManualTransaction: ManualTransaction;
}


export interface MutationCreateManualTransactionArgs {
  manualTransaction: CreateManualTransactionInput;
}


export interface MutationDeleteManualTransactionArgs {
  id: Array<Scalars['UUID']>;
}


export interface MutationUpdateManualTransactionArgs {
  manualTransaction: UpdateManualTransactionInput;
}

export interface Query {
  __typename?: 'Query';
  manualTransaction?: Maybe<ManualTransaction>;
  manualTransactions: Array<ManualTransaction>;
  trackedTransaction?: Maybe<TrackedTransaction>;
  trackedTransactions: Array<TrackedTransaction>;
}


export interface QueryManualTransactionArgs {
  id: Scalars['UUID'];
}


export interface QueryManualTransactionsArgs {
  page?: Scalars['Int'];
  pageSize?: Scalars['Int'];
  sort?: InputMaybe<Array<InputMaybe<SortInput>>>;
}


export interface QueryTrackedTransactionArgs {
  id: Scalars['UUID'];
}


export interface QueryTrackedTransactionsArgs {
  page?: Scalars['Int'];
  pageSize?: Scalars['Int'];
  sort?: InputMaybe<Array<InputMaybe<SortInput>>>;
}

export interface SampledValue {
  __typename?: 'SampledValue';
  id: Scalars['UUID'];
  measurand?: Maybe<Measurand>;
  multiplier?: Maybe<Scalars['Int']>;
  unit?: Maybe<Scalars['String']>;
  value: Scalars['Float'];
}

export interface SortInput {
  field: Scalars['String'];
  order?: InputMaybe<SortOrder>;
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum StoppedReason {
  DeAuthorized = 'DE_AUTHORIZED',
  EmergencyStop = 'EMERGENCY_STOP',
  EnergyLimitReached = 'ENERGY_LIMIT_REACHED',
  EvDisconnected = 'EV_DISCONNECTED',
  GroundFault = 'GROUND_FAULT',
  ImmediateReset = 'IMMEDIATE_RESET',
  Local = 'LOCAL',
  LocalOutOfCredit = 'LOCAL_OUT_OF_CREDIT',
  MasterPass = 'MASTER_PASS',
  Other = 'OTHER',
  OvercurrentFault = 'OVERCURRENT_FAULT',
  PowerLoss = 'POWER_LOSS',
  PowerQuality = 'POWER_QUALITY',
  Reboot = 'REBOOT',
  Remote = 'REMOTE',
  SocLimitReached = 'SOC_LIMIT_REACHED',
  StoppedByEv = 'STOPPED_BY_EV',
  Timeout = 'TIMEOUT',
  TimeLimitReached = 'TIME_LIMIT_REACHED'
}

export interface TrackedTransaction {
  __typename?: 'TrackedTransaction';
  chargePoint: ChargePoint;
  client: Client;
  endDate: Scalars['Instant'];
  events: Array<TransactionEvent>;
  id: Scalars['UUID'];
  idToken: IdToken;
  startDate: Scalars['Instant'];
  transactionId: Scalars['String'];
  version: Scalars['Long'];
}

export interface TransactionEvent {
  __typename?: 'TransactionEvent';
  cableMaxCurrent?: Maybe<Scalars['Int']>;
  chargingStatus?: Maybe<ChargingStatus>;
  connectorId?: Maybe<Scalars['Int']>;
  evseId?: Maybe<Scalars['Int']>;
  id: Scalars['UUID'];
  idToken?: Maybe<IdToken>;
  meterValues: Array<MeterValue>;
  numberOfPhasedUsed?: Maybe<Scalars['Int']>;
  offline?: Maybe<Scalars['Boolean']>;
  remoteStartId?: Maybe<Scalars['Int']>;
  reservationId?: Maybe<Scalars['Int']>;
  sequenceNumber: Scalars['Int'];
  stoppedReason?: Maybe<StoppedReason>;
  timeSpentCharging?: Maybe<Scalars['Int']>;
  timestamp: Scalars['Instant'];
  triggerReason: TriggerReason;
  type: TransactionEventType;
}

export enum TransactionEventType {
  Ended = 'ENDED',
  Started = 'STARTED',
  Updated = 'UPDATED'
}

export enum TriggerReason {
  AbnormalCondition = 'ABNORMAL_CONDITION',
  Authorized = 'AUTHORIZED',
  CablePluggedIn = 'CABLE_PLUGGED_IN',
  ChargingRateChanged = 'CHARGING_RATE_CHANGED',
  ChargingStateChanged = 'CHARGING_STATE_CHANGED',
  Deauthorized = 'DEAUTHORIZED',
  EnergyLimitReached = 'ENERGY_LIMIT_REACHED',
  EvCommunicationLost = 'EV_COMMUNICATION_LOST',
  EvConnectTimeout = 'EV_CONNECT_TIMEOUT',
  EvDeparted = 'EV_DEPARTED',
  EvDetected = 'EV_DETECTED',
  MeterValueClock = 'METER_VALUE_CLOCK',
  MeterValuePeriodic = 'METER_VALUE_PERIODIC',
  RemoteStart = 'REMOTE_START',
  RemoteStop = 'REMOTE_STOP',
  ResetCommand = 'RESET_COMMAND',
  SignedDataReceived = 'SIGNED_DATA_RECEIVED',
  StopAuthorized = 'STOP_AUTHORIZED',
  TimeLimitReached = 'TIME_LIMIT_REACHED',
  Trigger = 'TRIGGER',
  UnlockCommand = 'UNLOCK_COMMAND'
}

export interface UpdateDocumentInput {
  format?: InputMaybe<DocumentFormat>;
  id: Scalars['UUID'];
  name?: InputMaybe<Scalars['String']>;
  version: Scalars['Long'];
}

export interface UpdateManualTransactionInput {
  companyId?: InputMaybe<Scalars['UUID']>;
  cost?: InputMaybe<Scalars['Float']>;
  date?: InputMaybe<Scalars['Date']>;
  id: Scalars['UUID'];
  location?: InputMaybe<Scalars['String']>;
  receipt?: InputMaybe<UpdateDocumentInput>;
  userId?: InputMaybe<Scalars['UUID']>;
  version: Scalars['Long'];
}

export interface User {
  __typename?: 'User';
  client: Client;
  company: Company;
  firstName: Scalars['String'];
  id: Scalars['UUID'];
  lastName: Scalars['String'];
  version: Scalars['Long'];
}

export type GetManualTransactionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetManualTransactionsQuery = { __typename?: 'Query', manualTransactions: Array<{ __typename?: 'ManualTransaction', id: any, date: any, cost?: number | null, location?: string | null, user?: { __typename?: 'User', id: any, firstName: string, lastName: string } | null }> };

export type GetTrackedTransactionQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetTrackedTransactionQuery = { __typename?: 'Query', trackedTransaction?: { __typename?: 'TrackedTransaction', id: any, startDate: any, endDate: any, chargePoint: { __typename?: 'ChargePoint', id: any, name: string }, idToken: { __typename?: 'IdToken', id: any, idTag: string, type: IdTokenType }, events: Array<{ __typename?: 'TransactionEvent', id: any, timestamp: any, sequenceNumber: number, chargingStatus?: ChargingStatus | null, meterValues: Array<{ __typename?: 'MeterValue', id: any, values: Array<{ __typename?: 'SampledValue', id: any, measurand?: Measurand | null, value: number, multiplier?: number | null }> }> }> } | null };

export type GetTrackedTransactionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTrackedTransactionsQuery = { __typename?: 'Query', trackedTransactions: Array<{ __typename?: 'TrackedTransaction', id: any, startDate: any, endDate: any, chargePoint: { __typename?: 'ChargePoint', id: any, name: string }, idToken: { __typename?: 'IdToken', id: any, user?: { __typename?: 'User', id: any, firstName: string, lastName: string } | null }, events: Array<{ __typename?: 'TransactionEvent', id: any, meterValues: Array<{ __typename?: 'MeterValue', id: any, values: Array<{ __typename?: 'SampledValue', id: any, measurand?: Measurand | null, value: number, multiplier?: number | null }> }> }> }> };

export const GetManualTransactionsDocument = gql`
    query GetManualTransactions {
  manualTransactions {
    id
    date
    cost
    location
    user {
      id
      firstName
      lastName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetManualTransactionsGQL extends Apollo.Query<GetManualTransactionsQuery, GetManualTransactionsQueryVariables> {
    document = GetManualTransactionsDocument;
    client = 'reportingQlClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTrackedTransactionDocument = gql`
    query GetTrackedTransaction($id: UUID!) {
  trackedTransaction(id: $id) {
    id
    startDate
    endDate
    chargePoint {
      id
      name
    }
    idToken {
      id
      idTag
      type
    }
    events {
      id
      timestamp
      sequenceNumber
      chargingStatus
      meterValues {
        id
        values {
          id
          measurand
          value
          multiplier
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTrackedTransactionGQL extends Apollo.Query<GetTrackedTransactionQuery, GetTrackedTransactionQueryVariables> {
    document = GetTrackedTransactionDocument;
    client = 'reportingQlClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTrackedTransactionsDocument = gql`
    query GetTrackedTransactions {
  trackedTransactions {
    id
    chargePoint {
      id
      name
    }
    startDate
    endDate
    idToken {
      id
      user {
        id
        firstName
        lastName
      }
    }
    events {
      id
      meterValues {
        id
        values {
          id
          measurand
          value
          multiplier
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTrackedTransactionsGQL extends Apollo.Query<GetTrackedTransactionsQuery, GetTrackedTransactionsQueryVariables> {
    document = GetTrackedTransactionsDocument;
    client = 'reportingQlClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }