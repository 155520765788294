<div class="container">
  <h4>
    {{ 'companyDialog.titleAdd' | translate }}
  </h4>
  <form [formGroup]="companyForm">
    <div class="row">
      <div class="col-md-6 mb-3">
        <label class="form-label es-form-lable required">{{
          'companyDialog.nameInputLabel' | translate
        }}</label>
        <input
          type="text"
          class="form-control es-form-input"
          formControlName="name"
          [ngClass]="{
            'is-invalid': name.invalid && name.touched
          }"
        />
      </div>
    </div>
    <fieldset class="mb-3">
      <legend class="w-auto">{{ 'companyDialog.address' | translate }}</legend>
      <div class="row" formGroupName="address">
        <div class="col-md-9 mb-3">
          <label class="form-label es-form-lable required">{{
            'companyDialog.streetInputLabel' | translate
          }}</label>
          <input
            type="text"
            class="form-control es-form-input"
            formControlName="street"
            [ngClass]="{
              'is-invalid':
                address('street')?.invalid && address('street')?.touched
            }"
          />
        </div>
        <div class="col-md-3 mb-3">
          <label class="form-label es-form-lable required">{{
            'companyDialog.houseNumberInputLabel' | translate
          }}</label>
          <input
            type="text"
            class="form-control es-form-input"
            formControlName="houseNumber"
            [ngClass]="{
              'is-invalid':
                address('houseNumber')?.invalid &&
                address('houseNumber')?.touched
            }"
          />
        </div>
      </div>
      <div class="row" formGroupName="address">
        <div class="col-md-6 mb-3">
          <label class="form-label es-form-lable required">{{
            'companyDialog.townInputLabel' | translate
          }}</label>
          <input
            type="text"
            class="form-control es-form-input"
            formControlName="town"
            [ngClass]="{
              'is-invalid': address('town')?.invalid && address('town')?.touched
            }"
          />
        </div>
        <div class="col-md-6 mb-3">
          <label class="form-label es-form-lable required">{{
            'companyDialog.zipCodeInputLabel' | translate
          }}</label>
          <input
            type="text"
            class="form-control es-form-input"
            formControlName="zipCode"
            [ngClass]="{
              'is-invalid':
                address('zipCode')?.invalid && address('zipCode')?.touched
            }"
          />
        </div>
      </div>
      <div class="row" formGroupName="address">
        <div class="col-md-6 mb-3">
          <label class="form-label es-form-lable required">{{
            'companyDialog.countryInputLabel' | translate
          }}</label>
          <input
            type="text"
            class="form-control es-form-input"
            formControlName="country"
            [ngClass]="{
              'is-invalid':
                address('country')?.invalid && address('country')?.touched
            }"
          />
        </div>
      </div>
    </fieldset>
    <fieldset class="mb-3">
      <legend class="w-auto">{{ 'companyDialog.contact' | translate }}</legend>
      <div class="row" formGroupName="contact">
        <div class="col mb-3">
          <label class="form-label es-form-lable required">{{
            'companyDialog.nameInputLabel' | translate
          }}</label>
          <input
            type="text"
            class="form-control es-form-input"
            formControlName="name"
            [ngClass]="{
              'is-invalid': contact('name')?.invalid && contact('name')?.touched
            }"
          />
        </div>
        <div class="col mb-3">
          <label class="form-label es-form-lable required">{{
            'companyDialog.phoneInputLabel' | translate
          }}</label>
          <input
            type="text"
            class="form-control es-form-input"
            formControlName="phoneNumber"
            [ngClass]="{
              'is-invalid':
                contact('phoneNumber')?.invalid &&
                contact('phoneNumber')?.touched
            }"
          />
        </div>
      </div>
      <div class="row" formGroupName="contact">
        <div class="col mb-3">
          <label class="form-label es-form-lable required">{{
            'driverDialog.emailInputLabel' | translate
          }}</label>
          <input
            type="email"
            class="form-control es-form-input"
            formControlName="email"
            [ngClass]="{
              'is-invalid':
                contact('email')?.invalid && contact('email')?.touched
            }"
          />
        </div>
      </div>
    </fieldset>
    <div class="dialog-button-bar">
      <button
        class="bs-secondary-button text-nowrap btn"
        (click)="activeModal.dismiss('cancel')"
        type="button"
      >
        {{ 'idTokenDialog.cancel' | translate }}
      </button>
      <button
        class="bs-primary-button btn-block ml-1 btn"
        *ngIf="editing | async"
        (click)="editCompany(); activeModal.close('save')"
        type="submit"
        [disabled]="!companyForm.valid"
      >
        {{ 'idTokenDialog.save' | translate }}
      </button>
      <button
        class="bs-primary-button btn-block ml-1 btn"
        *ngIf="!(editing | async)"
        (click)="addCompany(); activeModal.close('save')"
        type="submit"
        [disabled]="!companyForm.valid"
      >
        {{ 'idTokenDialog.save' | translate }}
      </button>
    </div>
  </form>
</div>
