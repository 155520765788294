import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from '../graphql/generated/accounting_graphql_types';
import { AccountingService } from '../service/accounting.service';
import { BreadcrumbService } from 'src/app/layout/breadcrumb/breadcrumb.service';

@Component({
    selector: 'app-accounting-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent {
    private getAllUsersSubscription: Subscription | undefined;

    users: Array<Pick<User, 'id' | 'firstName' | 'lastName'>> = [];
    loadingUsers: boolean = true;

    constructor(
        private readonly accountingService: AccountingService,
        private breadcrumbService: BreadcrumbService,
    ) {}

    ngOnInit() {
        this.getAllUsersSubscription = this.accountingService.getAllUsers().subscribe(value => {
            this.users = value;
            this.loadingUsers = false;
        });
        this.breadcrumbService.accountingCrumbs(1);
    }

    ngOnDestroy() {
        this.getAllUsersSubscription?.unsubscribe();
        this.breadcrumbService.accountingCrumbs(0);
    }
}
