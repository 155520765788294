import {ManualTransaction} from "../graphql/generated/reporting_graphql_types";
import {DeepPartial} from "../../shared/typescript/deep-partial";

export type ManualTransactionTableModel = {
    root: any;
    firstName: string;
    lastName: string;
    location: string;
    time: Date;
    cost: number;
}

export function buildManualTransactionTableModels(
    transactions: DeepPartial<ManualTransaction>[]
): ManualTransactionTableModel[] {
    if (!transactions || transactions.length === 0) {
        return [];
    }
    return transactions.map(transaction => {
        const firstName = transaction?.user?.firstName;
        const lastName = transaction?.user?.lastName;
        const location = transaction?.location;
        const time = new Date(transaction?.date ?? 0);
        const cost = transaction?.cost ?? 0;
        return {
            root: transaction,
            firstName: firstName,
            lastName: lastName,
            location: location,
            time: time,
            cost: cost,
        } as ManualTransactionTableModel;
    });
}
