<div class="wrapper-top">
  <h3 class="fw-semibold">{{ vehicle?.licensePlate }}</h3>
  <button class="btn edit" (click)="openDialogEdit()">
    <img src="assets/button-edit.svg" alt="edit" />
  </button>
</div>
<div class="wrapper">
  <div class="info-container">
    <div class="item">
      <div class="entryKeys">
        {{ 'vehicleDialog.owner' | translate }}
      </div>
      <div *ngIf="vehicle?.owner; else company" class="entryValues">{{ vehicle?.owner?.firstName }} {{ vehicle?.owner?.lastName }}
      </div>
      <ng-template #company class="entryValues">{{ vehicle?.company?.name }} </ng-template>
    </div>
    <div class="item">
      <div class="entryKeys">
        {{ 'vehicleDialog.licensePlate' | translate }}
      </div>
      <div class="entryValues">{{ vehicle?.licensePlate }}</div>
    </div>
    <div class="item">
      <div class="entryKeys">
        {{ 'vehicleDialog.vendor' | translate }},
        {{ 'vehicleDialog.model' | translate }}
      </div>
      <div class="entryValues">{{ vehicle?.vendor }}, {{ vehicle?.model }}</div>
    </div>
  </div>
</div>
