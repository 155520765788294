<app-waiting *ngIf="loading; else showSuchergebnis"></app-waiting>
<ng-template #showSuchergebnis>
  <app-table
    [colNames]="colNames"
    [data]="flatUsers"
    (checkedChange)="updateChecked($event)"
    (edit)="openDialogEdit($event)"
    (delete)="delete($event)"
  ></app-table>
  <app-button-bar
    [addDialog]="addDialog"
    [checkedAmount]="checked.length"
    (add)="openDialogAdd()"
    (delete)="delete(checked)"
  ></app-button-bar>

  <ng-template #showErrorMsg>
    <app-error-message [text]="errorHandler.errorMsg"></app-error-message>
  </ng-template>
</ng-template>
