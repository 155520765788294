import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { OneChargePointGQL, OneChargePointQuery, Transaction } from '../../../graphql/generated/cp-graphql_types';

@Injectable({
    providedIn: 'root',
})
export class ChargepointDetailsService {
    constructor(private logger: NGXLogger, private oneChargePointGQL: OneChargePointGQL) {}

    getChargepoint(cpId: string): Observable<OneChargePointQuery['chargePoint']> {
        this.logger.debug(`ChargepointListService.getChargepoint()`);
        return this.oneChargePointGQL.watch({ cpId: cpId }).valueChanges.pipe(map(result => result.data.chargePoint));
    }

    getDuration(transaction: Transaction): string {
        if (transaction.events) {
            let startTimestamp = new Date(transaction.events[0].timestamp);
            let stopTimestamp = new Date(transaction.events[transaction.events?.length - 1].timestamp);
            let hours = (stopTimestamp.getTime() - startTimestamp.getTime()) / 36e5;

            return hours.toFixed(1);
        }
        return '';
    }

    getEventStart(transaction: Transaction): string {
        if (transaction.events) {
            let timestamp = new Date(transaction.events[0].timestamp);
            return this.getTimestamp(timestamp);
        }
        return '';
    }

    getEventEnd(transaction: Transaction): string {
        if (transaction.events) {
            let timestamp = new Date(transaction.events[transaction.events.length - 1].timestamp);
            return this.getTimestamp(timestamp);
        }
        return '';
    }

    getTimestamp(timestamp: Date): string {
        let day = String(timestamp.getDate());
        if (day.length < 2) {
            day = '0' + day.slice(-2);
        }
        let month = String(timestamp.getMonth() + 1);
        if (month.length < 2) {
            month = '0' + month.slice(-2);
        }
        let year = String(timestamp.getFullYear());

        let h = String(timestamp.getHours());
        if (h.length < 2) {
            h = '0' + h.slice(-2);
        }
        let m = String(timestamp.getMinutes());
        if (m.length < 2) {
            m = '0' + m.slice(-2);
        }

        return day + '.' + month + '.' + year + ', ' + h + ':' + m;
    }
}
