import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChargepointComponent } from './chargepoint-list/chargepoint/chargepoint.component';
import { ChargepointListComponent } from './chargepoint-list/chargepoint-list.component';
import { ChargepointsMainComponent } from './chargepoints-main.component';
import { ChargepointsRoutingModule } from './chargepoints-routing.module';
import { CommonModule } from '@angular/common';
import { CompatibleWallboxesComponent } from './compatible-wallboxes/compatible-wallboxes.component';
import { EditChargepointDialogComponent } from './chargepoint-list/edit-chargepoint-dialog/edit-chargepoint-dialog.component';
import { ErrorMessageModule } from '../shared/error-message.module';
import { OverviewCardComponent } from './chargepoint-list/overview-cards/overview-card.component';
import { PipesModule } from '../pipes/pipes.module';
import { SymbolBarModule } from '../shared/symbol-bar/symbol-bar.module';
import { TranslateModule } from '@ngx-translate/core';
import { WaitingModule } from '../shared/waiting.module';
import { WallboxesComponent } from './wallboxes/wallboxes.component';
import { ChargepointDetailsComponent } from './chargepoint-list/chargepoint/chargepoint-details/chargepoint-details.component';
import { NgbdSortableHeaderDirective } from './chargepoint-list/chargepoint/chargepoint-details/chargepoint-history-directive';
import { ChargepointService } from './chargepoint-list/chargepoint-list.service';
import { SharedModule } from '../shared/shared.module';
import { FilterChargepointDialogComponent } from './chargepoint-list/filter-chargepoint-dialog/filter-chargepoint-dialog.component';
import { FilterComponent } from './chargepoint-list/filter/filter.component';

@NgModule({
    declarations: [
        ChargepointComponent,
        ChargepointListComponent,
        ChargepointsMainComponent,
        CompatibleWallboxesComponent,
        EditChargepointDialogComponent,
        FilterChargepointDialogComponent,
        OverviewCardComponent,
        WallboxesComponent,
        ChargepointDetailsComponent,
        NgbdSortableHeaderDirective,
        FilterComponent,
    ],
    imports: [
        ChargepointsRoutingModule,
        CommonModule,
        ErrorMessageModule,
        FormsModule,
        PipesModule,
        ReactiveFormsModule,
        SymbolBarModule,
        TranslateModule,
        WaitingModule,
        NgbModule,
        SharedModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [PipesModule, ChargepointService, ChargepointComponent, FilterComponent],
    exports: [OverviewCardComponent],
})
export class ChargepointsModule {}
