import * as Apollo from 'apollo-angular';
import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';

export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** Instant data type support */
    Instant: any;
    /** A 64-bit signed integer */
    Long: any;
}
export interface ChargePointEdge {
    __typename: 'ChargePointEdge';
    cursor: string;
    node: ChargePoint;
}

export interface CompanyEdge {
    __typename: 'CompanyEdge';
    cursor: string;
    node: Company;
}
export interface UserEdge {
    __typename: 'UserEdge';
    cursor: string;
    node: User;
}

export interface ChargePoint {
    __typename?: 'ChargePoint';
    client: Client;
    createdAt: Scalars['Instant'];
    device?: Maybe<Device>;
    disabled?: Maybe<Scalars['Boolean']>;
    connectionStatus: ConnectionStatus;
    occupancy: Occupancy;
    evses?: Maybe<Array<Maybe<EvSupplyEquipment>>>;
    firmware?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    lastActivity?: Maybe<Scalars['Instant']>;
    location?: Maybe<Location>;
    name: Scalars['String'];
    owner: Owner;
    serialnumber?: Maybe<Scalars['String']>;
    transactions?: Maybe<Array<Maybe<Transaction>>>;
    version: Scalars['Long'];
}

export interface Owner {
    firstName?: string;
    lastName?: string;
    name?: string;
    id?: string;
    user?: User;
    company?: Company;
    __typename: string;
}
export enum Occupancy {
    Unknown = 'UNKNOWN',
    Free = 'FREE',
    Occupied = 'OCCUPIED',
    OccupiedByMe = 'OCCUPIED_BY_ME',
}
export enum ConnectionStatus {
    Connected = 'CONNECTED',
    Disconnected = 'DISCONNECTED',
    Pending = 'PENDING',
}

export interface Client {
    __typename?: 'Client';
    chargePoints?: Maybe<Array<ChargePoint>>;
    companies?: Maybe<Array<Company>>;
    id: Scalars['ID'];
    idTokens?: Maybe<Array<IdToken>>;
    name: Scalars['String'];
    users?: Maybe<Array<User>>;
    version?: Maybe<Scalars['Long']>;
}

export interface Company {
    __typename?: 'Company';
    chargePoints?: Maybe<Array<ChargePoint>>;
    client?: Client;
    id: Scalars['ID'];
    idTokens?: Maybe<Array<IdToken>>;
    name: Scalars['String'];
    users?: Maybe<Array<User>>;
    version: Scalars['Long'];
}

export interface Connector {
    __typename?: 'Connector';
    evse: EvSupplyEquipment;
    id: Scalars['ID'];
    identifier: Scalars['Int'];
    status: ConnectorStatus;
    statusChangedAt: Scalars['Instant'];
    version: Scalars['Long'];
}

export enum ConnectorStatus {
    Available = 'AVAILABLE',
    Faulted = 'FAULTED',
    Occupied = 'OCCUPIED',
    Reserved = 'RESERVED',
    Unavailable = 'UNAVAILABLE',
}

export enum Context {
    InterruptionBegin = 'INTERRUPTION_BEGIN',
    InterruptionEnd = 'INTERRUPTION_END',
    Other = 'OTHER',
    SampleClock = 'SAMPLE_CLOCK',
    SamplePeriodic = 'SAMPLE_PERIODIC',
    TransactionBegin = 'TRANSACTION_BEGIN',
    TransactionEnd = 'TRANSACTION_END',
    Trigger = 'TRIGGER',
}

export interface CreateChargePointInput {
    clientId?: InputMaybe<Scalars['ID']>;
    companyId?: InputMaybe<Scalars['ID']>;
    disabled?: InputMaybe<Scalars['Boolean']>;
    name: Scalars['String'];
    ownerId?: InputMaybe<Scalars['ID']>;
    status?: InputMaybe<ConnectionStatus>;
}

export interface Device {
    __typename?: 'Device';
    chargingPower?: Maybe<Scalars['Int']>;
    id: Scalars['ID'];
    model: Scalars['String'];
    vendor: Scalars['String'];
    version: Scalars['Long'];
}

export interface EvSupplyEquipment {
    __typename?: 'EVSupplyEquipment';
    connectors: Array<Connector>;
    id: Scalars['ID'];
    identifier: Scalars['Int'];
    status: EvseStatus;
    version: Scalars['Long'];
}

export enum EvseStatus {
    Charging = 'CHARGING',
    EvConnected = 'EV_CONNECTED',
    Idle = 'IDLE',
    SuspendedEv = 'SUSPENDED_EV',
    SuspendedEvse = 'SUSPENDED_EVSE',
}

export interface IdToken {
    __typename?: 'IdToken';
    active: Scalars['Boolean'];
    company?: Maybe<Company>;
    group?: Maybe<IdToken>;
    id: Scalars['ID'];
    idTag: Scalars['String'];
    name: Scalars['String'];
    owner?: Maybe<User>;
    type: IdTokenType;
    validAt: Scalars['Instant'];
    validUntil?: Maybe<Scalars['Instant']>;
    version: Scalars['Long'];
}

export enum IdTokenType {
    Central = 'CENTRAL',
    EMaid = 'E_MAID',
    Iso14443 = 'ISO14443',
    Iso15693 = 'ISO15693',
    KeyCode = 'KEY_CODE',
    Local = 'LOCAL',
    MacAddress = 'MAC_ADDRESS',
    NoAuthorization = 'NO_AUTHORIZATION',
}

export interface Location {
    __typename?: 'Location';
    country?: Maybe<Scalars['String']>;
    houseNumber?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
    postalCode?: Maybe<Scalars['String']>;
    street?: Maybe<Scalars['String']>;
    town?: Maybe<Scalars['String']>;
    version: Scalars['Long'];
}

export enum Measurand {
    CurrentExport = 'CURRENT_EXPORT',
    CurrentImport = 'CURRENT_IMPORT',
    CurrentOffered = 'CURRENT_OFFERED',
    EnergyActiveExportInterval = 'ENERGY_ACTIVE_EXPORT_INTERVAL',
    EnergyActiveExportRegister = 'ENERGY_ACTIVE_EXPORT_REGISTER',
    EnergyActiveImportInterval = 'ENERGY_ACTIVE_IMPORT_INTERVAL',
    EnergyActiveImportRegister = 'ENERGY_ACTIVE_IMPORT_REGISTER',
    EnergyApparentExport = 'ENERGY_APPARENT_EXPORT',
    EnergyApparentImport = 'ENERGY_APPARENT_IMPORT',
    EnergyApparentNet = 'ENERGY_APPARENT_NET',
    EnergyReactiveExportInterval = 'ENERGY_REACTIVE_EXPORT_INTERVAL',
    EnergyReactiveExportRegister = 'ENERGY_REACTIVE_EXPORT_REGISTER',
    EnergyReactiveImportInterval = 'ENERGY_REACTIVE_IMPORT_INTERVAL',
    EnergyReactiveImportRegister = 'ENERGY_REACTIVE_IMPORT_REGISTER',
    EnergyReactiveNet = 'ENERGY_REACTIVE_NET',
    Frequency = 'FREQUENCY',
    PowerActiveExport = 'POWER_ACTIVE_EXPORT',
    PowerActiveImport = 'POWER_ACTIVE_IMPORT',
    PowerFactor = 'POWER_FACTOR',
    PowerOffered = 'POWER_OFFERED',
    PowerReactiveExport = 'POWER_REACTIVE_EXPORT',
    PowerReactiveImport = 'POWER_REACTIVE_IMPORT',
    SoC = 'SO_C',
    Voltage = 'VOLTAGE',
}

export interface MeterValue {
    __typename?: 'MeterValue';
    timestamp: Scalars['Instant'];
    values?: Maybe<Array<SampledValue>>;
}

export interface Mutation {
    __typename?: 'Mutation';
    createChargePoint: ChargePoint;
    deleteChargePoint: Array<Scalars['ID']>;
    updateChargePoint: ChargePoint;
}

export interface MutationCreateChargePointArgs {
    chargePoint: CreateChargePointInput;
}

export interface MutationDeleteChargePointArgs {
    cpIds: Array<Scalars['ID']>;
}

export interface MutationUpdateChargePointArgs {
    chargePoint: UpdateChargePointInput;
}

export enum Phase {
    L_1 = 'L_1',
    L_1L_2 = 'L_1_L_2',
    L_1N = 'L_1_N',
    L_2 = 'L_2',
    L_2L_3 = 'L_2_L_3',
    L_2N = 'L_2_N',
    L_3 = 'L_3',
    L_3L_1 = 'L_3_L_1',
    L_3N = 'L_3_N',
    N = 'N',
}

export interface Query {
    __typename?: 'Query';
    chargePoint?: Maybe<ChargePoint>;
    chargePoints: Array<ChargePoint>;
    clients: Array<User>;
    companies: Array<Company>;
    idTokens: Array<User>;
    users: Array<User>;
}

export interface QueryChargePointArgs {
    id: Scalars['ID'];
}

export interface SampledValue {
    __typename?: 'SampledValue';
    context?: Maybe<Context>;
    location?: Maybe<Location>;
    measurand?: Maybe<Measurand>;
    multiplier?: Maybe<Scalars['Int']>;
    phase?: Maybe<Phase>;
    signedMeterValue?: Maybe<SignedMeterValue>;
    unit?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['Float']>;
}

export interface SignedMeterValue {
    __typename?: 'SignedMeterValue';
    encodingMethod?: Maybe<Scalars['String']>;
    publicKey?: Maybe<Scalars['String']>;
    signedMeterData?: Maybe<Scalars['String']>;
    signingMethod?: Maybe<Scalars['String']>;
}

export interface ChargePointSort {
    field: Scalars['String'];
    direction?: InputMaybe<SortOrder>;
}

export enum SortOrder {
    Asc = 'ASC',
    Desc = 'DESC',
    Init = 'INIT',
}

export enum StoppedReason {
    DeAuthorized = 'DE_AUTHORIZED',
    EmergencyStop = 'EMERGENCY_STOP',
    EnergyLimitReached = 'ENERGY_LIMIT_REACHED',
    EvDisconnected = 'EV_DISCONNECTED',
    GroundFault = 'GROUND_FAULT',
    ImmediateReset = 'IMMEDIATE_RESET',
    Local = 'LOCAL',
    LocalOutOfCredit = 'LOCAL_OUT_OF_CREDIT',
    MasterPass = 'MASTER_PASS',
    Other = 'OTHER',
    OvercurrentFault = 'OVERCURRENT_FAULT',
    PowerLoss = 'POWER_LOSS',
    PowerQuality = 'POWER_QUALITY',
    Reboot = 'REBOOT',
    Remote = 'REMOTE',
    SocLimitReached = 'SOC_LIMIT_REACHED',
    StoppedByEv = 'STOPPED_BY_EV',
    Timeout = 'TIMEOUT',
    TimeLimitReached = 'TIME_LIMIT_REACHED',
}

export interface Transaction {
    __typename?: 'Transaction';
    chargePoint: Scalars['String'];
    events?: Maybe<Array<TransactionEvent>>;
    id: Scalars['ID'];
    transactionId: Scalars['ID'];
    version: Scalars['Long'];
}

export interface TransactionEvent {
    __typename?: 'TransactionEvent';
    cableMaxCurrent?: Maybe<Scalars['Int']>;
    chargingStatus?: Maybe<EvseStatus>;
    connectorId?: Maybe<Scalars['Int']>;
    evseId?: Maybe<Scalars['ID']>;
    id: Scalars['ID'];
    idToken?: Maybe<IdToken>;
    meterValues?: Maybe<Array<Maybe<MeterValue>>>;
    numberOfPhasesUsed?: Maybe<Scalars['Int']>;
    offline?: Maybe<Scalars['Boolean']>;
    remoteStartId?: Maybe<Scalars['Int']>;
    reservationId?: Maybe<Scalars['Int']>;
    sequenceNumber?: Maybe<Scalars['Int']>;
    stoppedReason?: Maybe<StoppedReason>;
    timeSpentCharging?: Maybe<Scalars['Int']>;
    timestamp: Scalars['Instant'];
    transaction?: Maybe<Transaction>;
    triggerReason: TriggerReason;
    type: TransactionEventType;
}

export enum TransactionEventType {
    Ended = 'ENDED',
    Started = 'STARTED',
    Updated = 'UPDATED',
}

export enum TriggerReason {
    AbnormalCondition = 'ABNORMAL_CONDITION',
    Authorized = 'AUTHORIZED',
    CablePluggedIn = 'CABLE_PLUGGED_IN',
    ChargingRateChanged = 'CHARGING_RATE_CHANGED',
    ChargingStateChanged = 'CHARGING_STATE_CHANGED',
    Deauthorized = 'DEAUTHORIZED',
    EnergyLimitReached = 'ENERGY_LIMIT_REACHED',
    EvCommunicationLost = 'EV_COMMUNICATION_LOST',
    EvConnectTimeout = 'EV_CONNECT_TIMEOUT',
    EvDeparted = 'EV_DEPARTED',
    EvDetected = 'EV_DETECTED',
    MeterValueClock = 'METER_VALUE_CLOCK',
    MeterValuePeriodic = 'METER_VALUE_PERIODIC',
    RemoteStart = 'REMOTE_START',
    RemoteStop = 'REMOTE_STOP',
    ResetCommand = 'RESET_COMMAND',
    SignedDataReceived = 'SIGNED_DATA_RECEIVED',
    StopAuthorized = 'STOP_AUTHORIZED',
    TimeLimitReached = 'TIME_LIMIT_REACHED',
    Trigger = 'TRIGGER',
    UnlockCommand = 'UNLOCK_COMMAND',
}

export interface UpdateChargePointInput {
    companyId?: InputMaybe<Scalars['ID']>;
    disabled?: InputMaybe<Scalars['Boolean']>;
    id: Scalars['ID'];
    locationId?: InputMaybe<Scalars['ID']>;
    name?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Scalars['ID']>;
    version: Scalars['Long'];
}

export interface User {
    __typename?: 'User';
    client: Client;
    company: Company;
    firstName: Scalars['String'];
    id: Scalars['ID'];
    idTokens?: Maybe<Array<IdToken>>;
    lastName: Scalars['String'];
    version: Scalars['Long'];
}
export interface PageInfo {
    startCursor: any;
    endCursor: any;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
}

export type AllChargePointsQueryVariables = Exact<{
    first?: Maybe<Scalars['Int']>;
    after?: Maybe<Scalars['ID']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['ID']>;
    sort?: Maybe<ChargePointSort>;
    filter?: Maybe<ChargepointFilterInput>;
}>;

export type AllChargePointsQuery = {
    __typename?: 'Query';
    chargePoints: {
        pageInfo: {
            __typename?: 'PageInfo';
            startCursor: string;
            endCursor: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
        };
        edges: Array<{
            cursor: string;
            node: {
                id: string;
                version: number;
                name: string;
                status: ConnectionStatus;
                disabled: boolean;
                lastActivity: any;
                createdAt: string;
                serialnumber: any;
                firmware: any;
                evses: Array<any>;
                device: Device;
                owner: {
                    __typename: string;
                    User?: User;
                    Company?: Company;
                };
                location: Location;
                __typename: 'ChargePoint';
            };
            __typename: 'ChargePointEdge';
        }>;
        __typename: 'ChargePointConnection';
    };
};

export type OneChargePointQueryVariables = Exact<{
    cpId: Scalars['ID'];
}>;

export type OneChargePointQuery = {
    __typename?: 'Query';
    chargePoint?: {
        __typename?: 'ChargePoint';
        id: string;
        name: string;
        firmware?: string;
        serialnumber?: string;
        status: ConnectionStatus;
        company?: { __typename?: 'Company'; id: string; name: string };
        owner?: {
            __typename?: 'User';
            id: string;
            firstName: string;
            lastName: string;
        };
        transactions?: Array<{
            __typename?: 'Transaction';
            id: string;
            events?: Array<{
                __typename?: 'TransactionEvent';
                id: string;
                timestamp: any;
                type: TransactionEventType;
            }>;
        }>;
        device?: {
            __typename?: 'Device';
            id: string;
            vendor: string;
            model: string;
            chargingPower?: number;
        };
        location?: { __typename?: 'Location'; id: string; name?: string };
        version: string;
    };
};

export type OneCompanyQueryVariables = Exact<{ [key: string]: string }>;

export type OneCompanyQuery = {
    __typename?: 'Query';
    company: {
        __typename?: 'CompanyConnection';
        users: {
            edges: Array<UserEdge>;
            __typename: 'UserConnection';
        };
    };
};

export type UsersQueryVariables = Exact<{ [key: string]: never }>;

export type UsersQuery = {
    __typename?: 'Query';
    users: Array<{
        __typename?: 'User';
        id: string;
        firstName: string;
        lastName: string;
        company: { __typename?: 'Company'; id: string; name: string };
        idTokens?: Array<{ __typename?: 'IdToken'; id: string; name: string }>;
        client: { __typename?: 'Client'; id: string; name: string };
    }>;
};

export type CompaniesQueryVariables = Exact<{ [key: string]: never }>;

export type CompaniesQuery = {
    __typename?: 'Query';
    companies: {
        __typename?: 'CompanyConnection';
        pageInfo: Array<{
            startCursor: string;
            endCursor: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            __typename?: 'PageInfo';
        }>;
        edges: Array<CompanyEdge>;
    };
};

export type DeleteChargePointMutationVariables = Exact<{
    cpIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type DeleteChargePointMutation = {
    __typename?: 'Mutation';
    deleteChargePoint: Array<string>;
};

export type CreateChargePointMutationVariables = Exact<{
    createChargePoint: CreateChargePointInput;
}>;

export type CreateChargePointMutation = {
    __typename?: 'Mutation';
    createChargePoint: {
        __typename?: 'ChargePoint';
        clientId?: InputMaybe<Scalars['ID']>;
        companyId?: InputMaybe<Scalars['ID']>;
        disabled?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        ownerId?: InputMaybe<Scalars['ID']>;
        status?: InputMaybe<ConnectionStatus>;
    };
};

export interface ChargepointFilterInput {
    search?: TextFilterInput;
    lastActivity?: DateFilterInput;
    createdAt?: DateFilterInput;
    connectionStatus?: string;
    company?: string;
    user?: string;
}

export interface TextFilterInput {
    equals?: string;
    contains?: string;
}

export interface DateFilterInput {
    before?: Date;
    after?: Date;
    eq?: Date;
}

export const AllChargePointsDocument = gql`
    query chargePoints(
        $first: Int
        $after: String
        $last: Int
        $before: String
        $sort: ChargePointSort
        $filter: ChargePointFilterInput
    ) {
        chargePoints(first: $first, after: $after, last: $last, before: $before, sort: $sort, filter: $filter) {
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
            edges {
                cursor
                node {
                    id
                    name
                    disabled
                    lastActivity
                    createdAt
                    occupancy
                    device {
                        chargingPower
                    }
                    owner {
                        __typename
                        ... on User {
                            id
                            firstName
                            lastName
                        }
                        ... on Company {
                            name
                            id
                        }
                    }
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class AllChargePointsGQL extends Apollo.Query<AllChargePointsQuery, AllChargePointsQueryVariables> {
    document = AllChargePointsDocument;
    client = 'chargingQlClient';
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const OneChargePointDocument = gql`
    query chargePoint($cpId: UUID!) {
        chargePoint(id: $cpId) {
            id
            version
            name
            connectionStatus
            occupancy
            disabled
            lastActivity
            serialnumber
            firmware
            device {
                id
                version
                vendor
                model
                chargingPower
            }
            owner {
                __typename
                ... on User {
                    id
                    version
                    firstName
                    lastName
                }
                ... on Company {
                    id
                    version
                    name
                }
            }
            location {
                id
                version
                name
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class OneChargePointGQL extends Apollo.Query<OneChargePointQuery, OneChargePointQueryVariables> {
    document = OneChargePointDocument;
    client = 'chargingQlClient';
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}

export const OneCompanyDocument = gql`
    query company($companyId: UUID!) {
        company(id: $companyId) {
            version
            users {
                edges {
                    node {
                        id
                        version
                        firstName
                        lastName
                    }
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class OneCompanyGQL extends Apollo.Query<OneCompanyQuery, OneCompanyQueryVariables> {
    document = OneCompanyDocument;
    client = 'chargingQlClient';
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CompaniesDocument = gql`
    query companies {
        companies {
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
            edges {
                cursor
                node {
                    id
                    version
                    name
                    chargePoints {
                        edges {
                            node {
                                id
                                version
                                name
                            }
                        }
                    }
                    groups {
                        id
                        version
                        name
                    }
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class CompaniesGQL extends Apollo.Query<CompaniesQuery, CompaniesQueryVariables> {
    document = CompaniesDocument;
    client = 'chargingQlClient';
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteChargePointDocument = gql`
    mutation DeleteChargePoint($cpIds: [UUID!]!) {
        deleteChargePoint(ids: $cpIds)
    }
`;

@Injectable({
    providedIn: 'root',
})
export class DeleteChargePointGQL extends Apollo.Mutation<
    DeleteChargePointMutation,
    DeleteChargePointMutationVariables
> {
    document = DeleteChargePointDocument;
    client = 'chargingQlClient';
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateChargePointDocument = gql`
    mutation CreateChargePoint($createChargePoint: CreateChargePointInput!) {
        createChargePoint(chargePoint: $createChargePoint) {
            name
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class CreateChargePointGQL extends Apollo.Mutation<
    CreateChargePointMutation,
    CreateChargePointMutationVariables
> {
    document = CreateChargePointDocument;
    client = 'chargingQlClient';
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}

export const UpdateChargePointDocument = gql`
    mutation UpdateChargePoint($updateChargePoint: UpdateChargePointInput!) {
        updateChargePoint(chargePoint: $updateChargePoint) {
            id
            version
            name
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class UpdateChargePointGQL extends Apollo.Mutation<
    UpdateChargePointMutation,
    UpdateChargePointMutationVariables
> {
    document = UpdateChargePointDocument;
    client = 'chargingQlClient';
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}

export type UpdateChargePointMutationVariables = Exact<{
    updateChargePoint: UpdateChargePointInput;
}>;

export type UpdateChargePointMutation = {
    __typename?: 'Mutation';
    updateChargePoint: {
        __typename?: 'ChargePoint';
        id: any;
    };
};
