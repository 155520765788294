import { Component } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

/**
 * Component for the tab bar of the charging points with the tag <code>app-chargepoints-main</code>.
 */
@Component({
    selector: 'app-chargepoints-main',
    templateUrl: './chargepoints-main.component.html',
    styleUrls: ['./chargepoints-main.component.scss'],
})
export class ChargepointsMainComponent {
    /**
     * Creates an instance of ChargepointsMainComponent.
     * @param logger Service for logging.
     * @param userDataService Service to retrieve the user role.
     */
    constructor(private readonly logger: NGXLogger) {
        logger.debug('ChargepointsMainComponent.constructor()');
    }
}
