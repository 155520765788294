<div class="quickinfo">
  <div class="quickinfo-element">
    <h6 class="desc">{{ 'overviewCard.privateChargepoints' | translate }}</h6>
    <h3 class="value">{{ privateChargepoints || '-' }}</h3>
  </div>
  <div class="quickinfo-element">
    <h6 class="desc">{{ 'overviewCard.publicChargepoints' | translate }}</h6>
    <h3 class="value">{{ publicChargepoints || '-' }}</h3>
  </div>
  <div class="quickinfo-element">
    <h6 class="desc">{{ 'overviewCard.freeChargepoints' | translate }}</h6>
    <h3 class="value">{{ freeChargepoints || '-' }}</h3>
  </div>
  <div class="quickinfo-element charged-energy">
    <h6 class="desc">{{ 'overviewCard.chargedEnergy' | translate }}</h6>
    <h3 class="value">
      {{ chargedEnergy || '-' }}{{ chargedEnergy !== undefined ? ' kWh' : '' }}
    </h3>
  </div>
</div>
