<h1>{{ 'accounting.invoiceTransactionsTitle' | translate: { user: user | userName: false, month: month, year: year } }}</h1>
<app-accounting-loading-spinner *ngIf="loadingInvoice else showInvoices"
                                [loadingMessage]="'accounting.loadingInvoiceTransactions' | translate"></app-accounting-loading-spinner>

<ng-template #showInvoices>
  <div class="table-responsive">
    <table class="table">
      <thead>
      <tr>
        <th scope="col">
          <input type="checkbox"
                 aria-label="Select all"
                 (change)="selectAll($event)">
        </th>
        <th scope="col"><a>{{ 'accounting.subject' | translate }}</a></th>
        <th scope="col"><a>{{ 'accounting.chargePoint' | translate }}</a></th>
        <th scope="col"><a>{{ 'accounting.start' | translate }}</a></th>
        <th scope="col"><a>{{ 'accounting.stop' | translate }}</a></th>
        <th scope="col"><a>{{ 'accounting.duration' | translate }}</a></th>
        <th scope="col"><a>{{ 'accounting.transferredEnergy' | translate }}</a></th>
        <th scope="col"><a>{{ 'accounting.cost' | translate }}</a></th>
        <th scope="col"><a>{{ 'accounting.location' | translate }}</a></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let transaction of page; let index = index;">
        <td>
          <input type="checkbox" (change)="select($event, transaction)" [ngModel]="selected().includes(transaction)">
        </td>
        <td>{{ transaction.chargePoint }}</td>
        <td>{{ transaction.plate }}</td>
        <td>{{ transaction.timeStart | localizedDate: 'date-time-short' }}</td>
        <td>{{ transaction.timeStop | localizedDate: 'date-time-short' }}</td>
        <td>{{ transaction.duration | duration }}</td>
        <td>{{ 'accounting.transferredEnergySpecified' | translate: {amount: (transaction.root.transferredEnergy | number: '1.2-2')} }}</td>
        <td>{{ transaction.cost | currency:'EUR':'symbol':'1.2-2' }}</td>
        <td>{{ transaction.location }}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="d-flex flex-row justify-content-between align-items-center">
    <div class="d-flex flex-row justify-content-start align-items-center gap-3">
      <div class="d-flex flex-row align-items-center">
        <span class="chevron rotate--n90 cursor-pointer" (click)="pageChange(-1)"></span>
        <span class="mx-5 user-select-none">{{ pageId + 1 }}/{{ totalPages}}</span>
        <span class="chevron rotate--90 cursor-pointer" (click)="pageChange(1)"></span>
      </div>
      <select class="form-select" aria-label="PageSize Selection" (change)="pageSizeUpdate($event)">
        <option *ngFor="let pSize of pageSizeOptions" [selected]="pSize == pageSize" [value]="pSize">
          {{ pSize === -1 ? "Alle" : pSize }}
        </option>
      </select>
    </div>
    <div class="d-flex flex-row gap-3">
      <button type="button"
              class="btn btn-elastic"
              [disabled]="!hasSelected()"
              (click)="export()">
        <span *ngIf="hasSelected()">{{ 'accounting.exportInvoicesLabelAmount' | translate: {amount: selected().length} }}</span>
        <span *ngIf="!hasSelected()">{{ 'accounting.exportInvoicesLabel' | translate }}</span>
      </button>
      <button type="button"
              class="btn btn-delete"
              [disabled]="!hasSelected()"
              (click)="delete()">
        <span *ngIf="hasSelected()">{{ 'accounting.deleteInvoicesLabelAmount' | translate: {amount: selected().length} }}</span>
        <span *ngIf="!hasSelected()">{{ 'accounting.deleteInvoicesLabel' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
