import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { CompanyService } from '../company-list.service';
import { BreadcrumbService } from 'src/app/layout/breadcrumb/breadcrumb.service';
import { Company } from '../../graphql/generated/mdm_graphql_types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditCompanyDialogComponent } from '../edit-company-dialog/edit-company-dialog.component';

@Component({
    selector: 'app-details-company',
    templateUrl: './details-company.component.html',
    styleUrls: ['./details-company.component.scss'],
})
export class DetailsCompanyComponent implements OnInit {
    company: Company | undefined;

    constructor(
        private readonly companyService: CompanyService,
        private readonly route: ActivatedRoute,
        private readonly logger: NGXLogger,
        private breadcrumbService: BreadcrumbService,
        private modalService: NgbModal,

    ) {
        logger.debug('DetailsCompanyComponent.constructor()');
        logger.debug(
            'DetailsCompanyComponent.constructor(): this.company=',
            this.company,
        );
    }

    ngOnInit() {
        const id = this.route.snapshot.paramMap.get('id') ?? '';
        this.breadcrumbService.masterDataCrumbs(3, "companies", id)
        this.logger.debug('DetailsCompanyComponent.ngOnInit(): id=', id);
        this.companyService.getCompany(id).subscribe((result: any) => {
            this.company = result;
        });

    }

    openDialogEdit() {
        this.logger.debug('DetailsCompanyComponent.openDialogEdit()');
        let editDialogRef = this.modalService.open(EditCompanyDialogComponent, {
            centered: true,
            ariaLabelledBy: 'modal-basic-title',
            backdrop: 'static',
            keyboard: false,
            windowClass: 'modal',
        });
        editDialogRef.componentInstance.companyId = this.company?.id;

    }
}
