<div class="button-bar">
  <button *ngIf="showDelete" class="btn-delete btn" (click)="clickedOnDelete()" [disabled]="checkedAmount === 0">
    <span *ngIf="checkedAmount != 0">{{ checkedAmount }}</span>
    {{ 'options.delete' | translate }}
  </button>
  <button class="add-button bs-primary-button btn" (click)="clickedOnAdd()">
    <img alt="" class="plus-img" src="assets/icon-add.svg" />
    {{ addDialog | translate }}
  </button>
</div>
