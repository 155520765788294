import { Component } from '@angular/core';
import { MONTHS } from '../../../shared/predefined-values';

@Component({
    selector: 'app-content-backoffice',
    templateUrl: './content-backoffice.component.html',
    styleUrls: ['./content-backoffice.component.scss'],
})
export class ContentBackofficeComponent {
    dateTime: Date;

    months = MONTHS;

    constructor() {
        this.dateTime = new Date();
    }
}
