import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompanyService } from '../company-list.service';
import { NGXLogger } from 'ngx-logger';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    Company,
    CreateCompanyInput,
    UpdateCompanyInput,
} from '../../graphql/generated/mdm_graphql_types';
import { BehaviorSubject } from 'rxjs';

/**
 * Component for the tag <code>app-edit-company-dialog</code>
 * to display a dialog to edit a company.
 */
@Component({
    selector: 'app-edit-company-dialog',
    templateUrl: './edit-company-dialog.component.html',
    styleUrls: ['./edit-company-dialog.component.scss'],
})
export class EditCompanyDialogComponent implements OnInit {
    @Input() companyId: string | undefined;
    initialCompany = {} as Company;
    companyForm!: FormGroup;
    editing = new BehaviorSubject(false);
    errorMsg: string | undefined;

    /**
     * Creates an instance of EditCompanyDialogComponent.
     * @param dialogRef Reference to the open dialog.
     * @param companyListService Service to handle the CRUD methods for the companies.
     * @param driverListService Service to handle the CRUD methods for the drivers.
     * @param logger Service for logging.
     * @param data The given data of the company.
     */
    constructor(
        private companyService: CompanyService,
        private logger: NGXLogger,
        private fb: FormBuilder,
        public activeModal: NgbActiveModal,
    ) {
        logger.debug('EditCompanyDialogComponent.constructor()');
        logger.debug('data:', this.companyId);
    }

    ngOnInit() {
        this.companyForm = this.fb.group({
            name: ['', [Validators.required, Validators.maxLength(64)]],
            address: this.fb.group({
                country: ['', [Validators.required]],
                houseNumber: [
                    '',
                    [Validators.required, Validators.maxLength(5)],
                ],
                street: ['', [Validators.required]],
                town: ['', [Validators.required]],
                zipCode: [
                    '',
                    [Validators.required, Validators.pattern('^[0-9]*$')],
                ],
            }),
            contact: this.fb.group({
                email: [
                    '',
                    [
                        Validators.required,
                        Validators.email,
                        Validators.maxLength(64),
                    ],
                ],
                name: ['', [Validators.required, Validators.maxLength(64)]],
                phoneNumber: [
                    '',
                    [Validators.required, Validators.maxLength(20)],
                ],
            }),
        });
        if (
            this.companyId !== undefined &&
            this.companyId !== '' &&
            this.companyId !== null
        ) {
            this.logger.debug('Load Company from server ID=', this.companyId);
            this.companyService
                .getCompany(this.companyId)
                .subscribe((result: any) => {
                    this.initialCompany = result;
                    this.setFormData(this.initialCompany);
                });
        }
    }

    get name() {
        return this.companyForm.controls.name;
    }

    address(subField: string) {
        return this.companyForm.get(`address.${subField}`);
    }

    contact(subField: string) {
        return this.companyForm.get(`contact.${subField}`);
    }

    addCompany() {
        this.companyService
            .createCompany(this.companyForm.value)
            .subscribe((data: {}) => {
                this.logger.debug('create new Company RC=', data);
                this.companyService.refresh();
            });
    }

    editCompany() {
        this.companyService
            .updateCompany({
                name: this.companyForm.value.name,
                address: this.companyForm.value.address,
                contact: this.companyForm.value.contact,
                id: this.initialCompany.id,
                version: this.initialCompany.version,
            })
            .subscribe((data: {}) => {
                this.logger.debug('edit Company RC=', data);
                this.companyService.refresh();
            });
        this.isEditing(false);
    }

    isEditing(editStatus: boolean) {
        this.editing.next(editStatus);
    }

    setFormData(company: Company) {
        this.isEditing(true);
        this.companyForm.patchValue(company);
    }
}
