import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './guard/auth.guard';
import {DashboardComponent} from './dashboard/dashboard-main/dashboard.component';
import {NgModule} from '@angular/core';
import {ReservationComponent} from './reservation/reservation/reservation.component';
import {ProfileViewComponent} from "./profile/profile-view/profile-view.component";
import {BreadcrumbModule} from "./layout/breadcrumb/breadcrumb.module";

const routes: Routes = [
    {
        path: '',
        component: DashboardComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'chargepoints',
        loadChildren: () =>
            import('./chargepoints/chargepoints-routing.module').then(
                m => m.ChargepointsRoutingModule,
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'charging-operations',
        loadChildren: () =>
            import(
                './charging-operations/charging-operations-routing.module' ).then(
                m => m.ChargingOperationsRoutingModule
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'master-data',
        loadChildren: () =>
            import('./master-data/master-data-routing.module').then(
                m => m.MasterDataRoutingModule,
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'accounting',
        loadChildren: () =>
            import('./accounting/accounting-routing.module').then(
                m => m.AccountingRoutingModule,
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'reporting',
        loadChildren: () =>
            import('./reporting/reporting-routing.module').then(
                m => m.ReportingRoutingModule,
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'profile',
        component: ProfileViewComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'reservation',
        component: ReservationComponent,
        canActivate: [AuthGuard],
    },
    {
        path: '**',
        redirectTo: ''
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes), BreadcrumbModule.forRoot()],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
