<div class="card">
  <div class="card-body">
  <h5 class="card-title">
      <ng-container *ngIf="chargepoint.name !== undefined; else undefinedData">
        {{ chargepoint!.name }}
      </ng-container>
  </h5>
  <h5 class="card-subtitle">
      <ng-container
        *ngIf="chargepoint.owner.__typename === 'User'"
      >
        <img class="user-img" src="assets/icon-user.svg"/>
        {{ chargepoint!.owner.firstName }} {{ chargepoint!.owner.lastName }}
      </ng-container>
      <ng-container
        *ngIf="chargepoint.owner.__typename === 'Company'"
      >
         <img class="company-img" src="assets/icon-company.svg"/>
        {{ chargepoint!.owner.name }}
      </ng-container>
      <ng-container
        *ngIf="chargepoint.owner.__typename != 'Company' && chargepoint.owner.__typename != 'User'"
      >
      <img class="company-img" src="assets/icon-globe.svg"/>
      </ng-container>
  </h5>
  <hr />
  <div class="card-text">
    <div class="card-details">
      <div>
        <h6 class="desc">
          {{ 'chargepoints.lastTransaction' | translate }}
        </h6>
        <ng-container
          *ngIf="chargepoint.lastActivity !== undefined; else showUndefined"
        >
          <span class="text">
            {{ chargepoint!.lastActivity | localizedDate }}
          </span>
        </ng-container>
      </div>
      <div>
        <h6>
          {{ 'chargepoints.chargingPower' | translate }}
        </h6>
        <ng-container
          *ngIf="chargepoint.device?.chargingPower; else showUndefined"
        >
          <span class="text">
            {{ chargepoint!.device?.chargingPower }} kWh
          </span>
        </ng-container>
      </div>
    </div>
    <div class="status">
      <ng-container *ngIf="chargepoint.occupancy === 'UNKNOWN'">
        <span class="dot dot-unknown"></span>
        <span class="unknown">{{ 'chargepoints.unknown' | translate }}</span>
      </ng-container>
      <ng-container *ngIf="chargepoint.occupancy === 'FREE'">
        <span class="dot dot-free"></span>
        <span class="free">{{ 'chargepoints.free' | translate }}</span>
      </ng-container>
      <ng-container *ngIf="chargepoint.occupancy === 'OCCUPIED'">
        <span class="dot dot-occupied"></span>
        <span class="occupied">{{ 'chargepoints.charging' | translate }}</span>
      </ng-container>
      <ng-container *ngIf="chargepoint.occupancy === 'OCCUPIED_BY_ME'">
        <span class="dot dot-occupied-by-me"></span>
        <span class="occupied-by-me">{{ 'chargepoints.occupied_by_me' | translate }}</span>
      </ng-container>
    </div>
    <div class="card-buttons">
      <button class="btn">
        <img src="assets/button-favorite-unselected.svg" />
      </button>
      <button [routerLink]="chargepoint.id" class="btn right">
        <img src="assets/button-info.svg" />
      </button>
      <button class="btn right">
        <img src="assets/button-reservation.svg" />
      </button>
      <button *ngIf="deletePermission" class="btn" (click)="delete(chargepoint)">
        <img src="assets/button-delete.svg" />
      </button>
    </div>
  </div>
  </div>
</div>

<ng-template #undefinedData>{{ 'errorMessage.undefinedData' | translate }}</ng-template>
<ng-template #showUndefined>-</ng-template>
