import {Injectable} from '@angular/core';
import {
    GetManualTransactionsGQL,
    GetTrackedTransactionGQL,
    GetTrackedTransactionQuery,
    GetTrackedTransactionsGQL,
    ManualTransaction,
    TrackedTransaction
} from "./graphql/generated/reporting_graphql_types";
import {map, mergeMap} from "rxjs/operators";
import {Observable} from "rxjs";
import {buildCombinedTransaction, CombinedTransaction} from "./all-transactions/combined-transaction.model";
import {DeepPartial} from "../shared/typescript/deep-partial";

@Injectable({
    providedIn: 'root'
})
export class ReportingService {

    constructor(private readonly getTrackedTransactionsGQL: GetTrackedTransactionsGQL,
                private readonly getManualTransactionsGQL: GetManualTransactionsGQL,
                private readonly getTrackedTransactionGQL: GetTrackedTransactionGQL) {
    }

    getAllTransactions(): Observable<CombinedTransaction[]> {
        return this.getManualTransactions().pipe(
            mergeMap(manual => this.getTrackedTransactions().pipe(map(tracked => [...manual, ...tracked]))),
            map(transactions => transactions.map(transaction => buildCombinedTransaction(transaction)))
        );
    }

    getTrackedTransactions(): Observable<DeepPartial<TrackedTransaction>[]> {
        return this.getTrackedTransactionsGQL.watch()
            .valueChanges
            .pipe(map(value => value.data.trackedTransactions))
    }

    getManualTransactions(): Observable<DeepPartial<ManualTransaction>[]> {
        return this.getManualTransactionsGQL.watch()
            .valueChanges
            .pipe(map(value => value.data.manualTransactions))
    }

    getTrackedTransactionById(id: string): Observable<GetTrackedTransactionQuery['trackedTransaction']> {
        return this.getTrackedTransactionGQL.watch({id: id})
            .valueChanges
            .pipe(map(value => value.data.trackedTransaction));
    }
}
