import {
    DeleteError,
    FindError,
    SaveError,
    UpdateError,
} from '../../shared/errors';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ErrorHandler {
    /**
     * The error message to be passed to {@link ErrorMessageComponent} or to the snack bar.
     */
    public errorMsg: string | undefined;

    /**
     * Creates an instance of IdTokenListComponent.
     * @param logger Service for logging.
     * @param snackBar Service to display a snack bar.
     * @param translate Service to translate the text of the snack bar.
     */
    constructor(
        private readonly logger: NGXLogger,
        private readonly translate: TranslateService,
    ) {
        logger.debug('ErrorHandler.constructor()');
    }

    public handleError(err: DeleteError | FindError | SaveError | UpdateError) {
        const { statuscode } = err;

        if (
            err instanceof FindError ||
            err instanceof SaveError ||
            err instanceof UpdateError
        ) {
            const { cause } = err;
            this.errorMsg =
                cause instanceof HttpErrorResponse
                    ? cause.error
                    : JSON.stringify(cause);
        }

        this.logger.debug(
            'ErrorHandler.handleError(): statuscode=',
            statuscode,
        );

        switch (statuscode) {
            case HttpStatusCode.BadRequest:
                break;

            case HttpStatusCode.NotFound:
                this.errorMsg = 'notFoundidTokens';
                break;

            case HttpStatusCode.Conflict:
                this.errorMsg = 'conflict';
                break;

            case HttpStatusCode.TooManyRequests:
                this.errorMsg = 'tooManyRequests';
                break;

            case HttpStatusCode.ServiceUnavailable:
                this.errorMsg = 'serviceUnavailable';
                break;

            case HttpStatusCode.GatewayTimeout:
                this.logger.error(
                    'Is the app server running? Port forwarding?',
                );
                this.errorMsg = 'timeOut';
                break;

            default:
                this.errorMsg = 'unknownError';
                break;
        }
    }
}
