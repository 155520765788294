<footer class="text-center position-relative elastic-charge-footer">
  <section class="title">
    elastic
    <img
      ngSrc="./assets/logo.svg"
      alt="Elastic Charge Icon"
      height="18"
      width="28">
    charge
  </section>
  <nav>
    <ul>
      <li>
        <a href="#">{{"footer.contact" | translate}}</a>
      </li>
      <li>
        <a href="#">{{"footer.imprint" | translate}}</a>
      </li>
      <li>
        <a href="#">{{"footer.privacy" | translate}}</a>
      </li>
    </ul>
  </nav>
</footer>
