import { environment } from '../../environments/environment';

/**
 * Base path for the GQL server.
 */
export const BASE_PATH_GQL_CHARGE_ENGINE =
    environment.chargingEngineGraphqlEndpoint;

/**
 * Base path for the GQL server.
 */
export const BASE_PATH_GQL_MASTER_DATA_MANAGEMENT =
    environment.masterDataGraphqlEndpoint;

/**
 * Base path for the GQL server.
 */
export const BASE_PATH_GQL_INVOICE_SYSTEM =
    environment.invoiceSystemGraphqlEndpoint;

/**
 * Base path for the GQL server.
 */
export const BASE_PATH_GQL_REPORTING_SYSTEM =
    environment.reportingSystemGraphqlEndpoint;

/**
 * Path at GQL server for drivers.
 */
export const DRIVERS_PATH_GQL = 'drivers';

/**
 * Path at GQL server for companies.
 */
export const COMPANIES_PATH_GQL = 'companies';

/**
 * Path at GQL server for id-token.
 */
export const ID_TOKEN_PATH_GQL = 'id-tokens';
