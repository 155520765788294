<div class="container">
  <h4>
    {{ 'idTokenDialog.titleEdit' | translate }}
  </h4>
  <form [formGroup]="idTokenForm" class="id-token-edit-dialog">
    <div class="row mb-3">
      <div class="col">
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            id="idTokenActive"
            formControlName="active"
          />
          <label class="form-check-label es-form-lable" for="idTokenActive">{{
            'idTokenDialog.id_active' | translate
          }}</label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="mb-3">
          <label class="form-label es-form-lable required" for="idTokenName">{{
            'idTokenDialog.name' | translate
          }}</label>
          <input
            type="text"
            class="form-control es-form-input"
            id="idTokenName"
            formControlName="name"
            [ngClass]="{
              'is-invalid': idTokenName.invalid && idTokenName.touched
            }"
          />
        </div>
      </div>
      <div class="col">
        <div class="mb-3">
          <label class="form-label es-form-lable required" for="idTokenIdTag">{{
            'idTokenDialog.identifier' | translate
          }}</label>
          <input
            type="text"
            class="form-control es-form-input"
            id="idTokenIdTag"
            formControlName="idTag"
            [ngClass]="{ 'is-invalid': idTag.invalid && idTag.touched }"
          />
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        <label class="form-label es-form-lable required">
          {{ 'idTokenDialog.validFrom' | translate }}</label
        >
        <div class="input-group">
          <input
            class="form-control"
            placeholder="dd.mm.yyyy"
            id="idTokenValidFrom"
            ngbDatepicker
            #idTokenValidFrom="ngbDatepicker"
            formControlName="validAt"
            [ngClass]="{ 'is-invalid': validAt.invalid && validAt.dirty }"
          />
          <button
            class="btn btn-outline-secondary calendar"
            (click)="idTokenValidFrom.toggle()"
            type="button"
          ></button>
        </div>
      </div>
      <div class="col">
        <label class="form-label es-form-lable">
          {{ 'idTokenDialog.validUntil' | translate }}</label
        >
        <div class="input-group">
          <input
            class="form-control"
            placeholder="dd.mm.yyyy"
            id="idTokenValidUntil"
            ngbDatepicker
            #idTokenValidUntil="ngbDatepicker"
            formControlName="validUntil"
            [ngClass]="{
              'is-invalid': validUntil.invalid && validUntil.dirty
            }"
          />
          <button
            class="btn btn-outline-secondary calendar"
            (click)="idTokenValidUntil.toggle()"
            type="button"
          ></button>
        </div>
      </div>
    </div>

    <div class="row text-left">
      <div class="col">
        <div class="form-group" id="assignGroup">
          <div class="">
            <label class="form-label es-form-lable" for="assignGroup">{{
              'idTokenDialog.assignTo' | translate
            }}</label>
          </div>
          <div class="form-check form-check-inline
          mb-3">
            <input
              class="form-check-input"
              type="radio"
              id="rb_user"
              value="user"
              formControlName="owner"
            />
            <label class="form-check-label" for="rb_user">
              {{ 'idTokenDialog.rb_user' | translate }}
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              id="rb_company"
              value="company"
              formControlName="owner"
            />
            <label class="form-check-label" for="rb_company">
              {{ 'idTokenDialog.rb_company' | translate }}
            </label>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="owner.value === 'user'">
      <div class="row mb-3">
        <div class="col">
          <label class="form-label es-form-lable required" for="idTokenUser">{{
            'idTokenDialog.sel_user' | translate
          }}</label>
          <select
            #user
            class="form-select"
            id="idTokenUser"
            formControlName="groupId"
            [ngClass]="{ 'is-invalid': groupId.invalid && groupId.touched }"
          >
          <option value=""> {{ 'idTokenDialog.sel_placeholder' | translate }}</option>
            <option
              *ngFor="let user of this.users | async"
              [value]="user.groups[0].id"
            >
              {{ user.firstName }}&nbsp;{{ user.lastName }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div *ngIf="owner.value === 'company'">
      <div class="row mb-3">
        <div class="col">
          <label
            class="form-label es-form-lable required"
            for="idTokenCompany"
            >{{ 'idTokenDialog.sel_company' | translate }}</label
          >
          <select
            #selectedCompany
            class="form-select"
            formControlName="company"
            (change)="onCompanySelectionChange(selectedCompany.value)"
            [ngClass]="{
              'is-invalid':
                selectedCompany.value.length === 0 && company.touched
            }"
          >
            <option value="">
              {{ 'idTokenDialog.sel_placeholder' | translate }}
            </option>
            <option
              *ngFor="let company of this.companies | async"
              [value]="company.id"
            >
              {{ company.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <label class="form-label es-form-lable required" for="idTokenGroup">{{
            'idTokenDialog.sel_group' | translate
          }}</label>
          <select
            #idToken
            class="form-select"
            formControlName="groupId"
            (change)="onIdTokenGroupChange(idToken.value)"
            [ngClass]="{ 'is-invalid': groupId.invalid && groupId.touched }"
          >
          <option value=""> {{ 'idTokenDialog.sel_placeholder' | translate }}</option>
            <option
              *ngFor="let group of selectedCompanyIdTokenGroups"
              [value]="group.id"
            >
              {{ group.name }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="dialog-button-bar">
      <button
        class="bs-secondary-button text-nowrap btn"
        (click)="activeModal.dismiss('cancel')"
        type="button"
      >
        {{ 'idTokenDialog.cancel' | translate }}
      </button>
      <button
        class="bs-primary-button btn-block ml-1 btn"
        *ngIf="editing | async"
        (click)="editIdToken(); activeModal.close('save')"
        type="submit"
      >
        {{ 'idTokenDialog.save' | translate }}
      </button>
      <button
        class="bs-primary-button btn-block ml-1 btn"
        *ngIf="!(editing | async)"
        (click)="addIdToken(); activeModal.close('save')"
        type="submit"
        [disabled]="!idTokenForm.valid"
      >
        {{ 'idTokenDialog.save' | translate }}
      </button>
    </div>
  </form>
</div>