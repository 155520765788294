<div class="wallboxes">
  <ng-container *ngIf="wallboxesExist; else noWallbox">
    <app-symbol-bar></app-symbol-bar>
  </ng-container>
  <ng-template #noWallbox>
    <div class="no-wallbox">
      <h3>{{ 'wallboxes.noWallbox' | translate }}</h3>
      <img src="assets/illu-wallbox.svg" />
    </div>
  </ng-template>
</div>

<div class="button-bar">
  <button
    class="add-button bs-primary-button btn"
    >
    <img
    class="plus-img"
    src="assets/icon-add.svg"
    />
    {{ 'wallboxes.addWallbox' | translate }}
  </button>
</div>
