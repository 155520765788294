<div class="wrapper-top">
  <h3 class="fw-semibold">{{ idToken?.name }}</h3>
   <button class="btn edit" (click)="openDialogEdit()">
    <img src="assets/button-edit.svg" alt="edit" />
  </button>
</div>
<div class="wrapper">
  <div class="info-container">
    <div class="item">
      <div class="entryKeys">
        {{ 'idTokenDialog.name' | translate }}
      </div>
      <div class="entryValues">{{ idToken?.name}}</div>
    </div>
    <div class="item">
      <div class="entryKeys">
        {{ 'idTokenDialog.id_active' | translate }}
      </div>
      <div class="entryValues">{{idToken?.active }}</div>
    </div>
    <div class="item">
      <div class="entryKeys">
        {{ 'idTokenDialog.validFrom' | translate }}
      </div>
      <div class="entryValues">{{idToken?.validAt}}</div>
    </div>
    <div class="item">
      <div class="entryKeys">
        {{ 'idTokenDialog.validUntil' | translate }}
      </div>
      <div class="entryValues">{{idToken?.validUntil}}</div>
    </div>
    <div class="item">
      <div class="entryKeys">
        {{ 'idTokenDialog.tokenType' | translate }}
      </div>
      <div class="entryValues">{{ idToken?.type }}</div>
    </div>
  </div>
</div>


