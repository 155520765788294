<div *ngIf="user">
  <h1>{{ 'accounting.invoiceTransactionExportTitle' | translate: { user: user | userName: false, month: month, year: year } }}</h1>

  <div class="card shadow user-select-none">
    <div class="card-header bg-white">
      <div class="d-flex flex-column align-items-start">
        <span class="text-address">{{ user | userName: true }}</span>
        <span *ngIf="address" class="text-address">{{ address['street'] }} {{ address['houseNumber'] }}</span>
        <span *ngIf="address" class="text-address">{{ address['zipCode'] }} {{ address['town'] }}</span>
      </div>
      <h4>{{ 'accounting.exportInvoicesTitle' | translate: { month: month, year: year } }}</h4>
    </div>
    <div class="card-body bg-white">
      <div class="table-responsive">
        <table class="table my-0">
          <thead class="visually-hidden">
          <tr>
            <th scope="col">{{ 'accounting.licensePlate' | translate }}</th>
            <th scope="col">{{ 'accounting.start' | translate }}</th>
            <th scope="col">{{ 'accounting.stop' | translate }}</th>
            <th scope="col">{{ 'accounting.duration' | translate }}</th>
            <th scope="col">{{ 'accounting.kwH' | translate }}</th>
            <th scope="col">{{ 'accounting.cost' | translate }}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let transaction of transactions">
            <td>{{ transaction.plate }}</td>
            <td>{{ transaction.timeStart | localizedDate: 'date-time-short' }}</td>
            <td>{{ transaction.timeStop | localizedDate: 'date-time-short' }}</td>
            <td class="text-end">{{ transaction.duration | duration }} Stunden</td>
            <td class="text-end">{{ transaction.root.transferredEnergy | number:'1.2-2' }} kWh</td>
            <td class="text-end">{{ transaction.cost | currency:'EUR':'symbol':'1.2-2' }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card-footer bg-white">
      <div class="d-flex flex-column justify-content-start align-items-start justify-content-sm-between align-items-sm-center flex-sm-row">
        <h4>{{ 'accounting.exportInvoicesFooter' | translate: { month: month, year: year } }}</h4>
        <hr class="visible-xs-block"/>
        <h4>{{ totalCost | currency:'EUR':'symbol':'1.2-2' }}</h4>
      </div>
    </div>
  </div>
  <button type="button" class="btn btn-elastic mt-4" (click)="downloadSelectedInvoices()">{{ 'accounting.download' | translate }}</button>
</div>
