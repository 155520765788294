import {
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { CompanyListComponent } from './company-list/company-list.component';
import { DetailsCompanyComponent } from './company-list/details-company/details-company.component';
import { DetailsDriverComponent } from './driver-list/details-driver/details-driver.component';
import { DriverListComponent } from './driver-list/driver-list.component';
import { EditCompanyDialogComponent } from './company-list/edit-company-dialog/edit-company-dialog.component';
import { EditDriverDialogComponent } from './driver-list/edit-driver-dialog/edit-driver-dialog.component';
import { ErrorMessageModule } from '../shared/error-message.module';
import { EditIdTokenDialogComponent } from './id-token-list/edit-id-token-dialog/edit-id-token-dialog.component';
import { IdTokenListComponent } from './id-token-list/id-token-list.component';
import { DetailsIdTokenComponent } from './id-token-list/details-id-token/details-id-token.component';
import { MasterDataComponent } from './master-data.component';
import { MasterDataRoutingModule } from './master-data-routing.module';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { VehicleListComponent } from './vehicle-list/vehicle-list.component';
import { WaitingModule } from '../shared/waiting.module';
import { IdTokenService } from './id-token-list/id-token-list.service';
import { BASE_PATH_GQL_MASTER_DATA_MANAGEMENT } from '../shared/paths';
import { NGXLogger } from 'ngx-logger';
import { PipesModule } from '../pipes/pipes.module';
import { CompanyService } from './company-list/company-list.service';
import { EditVehicleDialogComponent } from './vehicle-list/edit-vehicle-dialog/edit-vehicle-dialog.component';
import { VehicleService } from './vehicle-list/vehicle-list.service';
import { DriverService } from './driver-list/driver-list.service';
import { DetailsVehicleComponent } from './vehicle-list/details-vehicle/details-vehicle.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
    declarations: [
        EditIdTokenDialogComponent,
        DetailsIdTokenComponent,
        CompanyListComponent,
        DetailsCompanyComponent,
        DetailsDriverComponent,
        DriverListComponent,
        EditCompanyDialogComponent,
        EditDriverDialogComponent,
        IdTokenListComponent,
        MasterDataComponent,
        VehicleListComponent,
        EditVehicleDialogComponent,
        DetailsVehicleComponent,
    ],
    imports: [
        CommonModule,
        ErrorMessageModule,
        MasterDataRoutingModule,
        PipesModule,
        ReactiveFormsModule,
        TranslateModule,
        WaitingModule,
        NgbDropdownModule,
        NgbDatepickerModule,
        NgbModule,
        SharedModule
    ],
    providers: [IdTokenService, DriverService, CompanyService, VehicleService],
})
export class MasterDataModule {
    public constructor(private readonly logger: NGXLogger) {
        logger.debug(
            `Create MasterdataModule with basepath: ${BASE_PATH_GQL_MASTER_DATA_MANAGEMENT}`,
        );
    }
}
