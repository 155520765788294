<div class="wrapper-top">
  <h3 class="fw-semibold">{{ user?.lastName }}, {{ user?.firstName }}</h3>
   <button class="btn edit" (click)="openDialogEdit()">
    <img src="assets/button-edit.svg" alt="edit" />
  </button>
</div>
<div class="wrapper">
  <div class="info-container">
    <div class="item">
      <div class="entryKeys">
        {{ 'driverDialog.lastNameInputLabel' | translate }}
      </div>
      <div class="entryValues">{{ user?.lastName }}</div>
    </div>
    <div class="item">
      <div class="entryKeys">
        {{ 'driverDialog.firstNameInputLabel' | translate }}
      </div>
      <div class="entryValues">{{ user?.firstName  }}</div>
    </div>
    <div class="item">
      <div class="entryKeys">
        {{ 'driverDialog.companySelectLabel' | translate }}
      </div>
      <div class="entryValues">{{user?.company?.name }}</div>
    </div>
    <div class="item">
      <div class="entryKeys">
        {{ 'driverDialog.locationInputLabel' | translate }}
      </div>
      <div class="entryValues">{{user?.company?.address?.town}}</div>
    </div>
    <div class="item">
      <div class="entryKeys">
        {{ 'driverDialog.emailInputLabel' | translate }}
      </div>
      <div class="entryValues">{{user?.email}}</div>
    </div>
    <div class="item">
      <div class="entryKeys">
        {{ 'driverDialog.streetInputLabel' | translate }},
        {{ 'driverDialog.houseNumberInputLabel' | translate }}
      </div>
      <div class="entryValues">{{ user?.address?.street }}, {{ user?.address?.houseNumber }}</div>
    </div>
    <div class="item">
      <div class="entryKeys">
        {{ 'driverDialog.zipCodeInputLabel' | translate }},
      {{ 'driverDialog.cityInputLabel' | translate }}
      </div>
      <div class="entryValues">{{ user?.address?.zipCode }}, {{ user?.address?.town }}</div>
    </div>
    <div class="item">
      <div class="entryKeys">
        {{ 'driverDialog.vehiclesSelectLabel' | translate }}
      </div>
      <div class="entryValues">{{user?.vehicles}}</div>
    </div>
    <div class="item">
      <div class="entryKeys">
        {{ 'driverDialog.chargepointsSelectLabel' | translate }}
      </div>
      <div class="entryValues">{{user?.chargePoints}}</div>
    </div>
    <div class="item">
      <div class="entryKeys">
        {{ 'driverDialog.houseNumberInputLabel' | translate }}
      </div>
      <div class="entryValues">{{user?.address?.houseNumber}}</div>
    </div>
  </div>
</div>
