<div class="wrapper-top">
  <h3 class="fw-semibold">{{ company?.name }}</h3>
  <button class="btn edit" (click)="openDialogEdit()">
    <img src="assets/button-edit.svg" alt="edit" />
  </button>
</div>
<div class="wrapper">
  <div class="info-container">
    <div class="item">
      <div class="entryKeys">
        {{ 'companyDialog.nameInputLabel' | translate }}
      </div>
      <div class="entryValues">{{ company?.name}}</div>
    </div>
    <div class="item">
      <div class="entryKeys">
        {{ 'companyDialog.zipCodeInputLabel' | translate }},
        {{ 'companyDialog.townInputLabel' | translate }}
      </div>
      <div class="entryValues">{{company?.address?.zipCode }}, {{company?.address?.town }}</div>
    </div>
    <div class="item">
      <div class="entryKeys">
        {{ 'companyDialog.streetInputLabel' | translate }},
        {{ 'companyDialog.houseNumberInputLabel' | translate }}
      </div>
      <div class="entryValues">{{company?.address?.street }}, {{company?.address?.houseNumber }}</div>
    </div>
    <div class="item">
      <div class="entryKeys">
        {{ 'companyDialog.contact' | translate }}
      </div>
      <div class="entryValues">{{company?.contact?.name }}</div>
    </div>
    <div class="item">
      <div class="entryKeys">
        {{ 'companyDialog.emailInputLabel' | translate }}
      </div>
      <div class="entryValues">{{company?.contact?.email }}</div>
    </div>
    <div class="item">
      <div class="entryKeys">
        {{ 'companyDialog.phoneInputLabel' | translate }}
      </div>
      <div class="entryValues">{{company?.contact?.phoneNumber }}</div>
    </div>
    <div class="item">
      <div class="entryKeys">
        {{ 'companyDialog.groupsInputLabel' | translate }}
      </div>
      <div class="entryValues">{{company?.groups?.length }}</div>
    </div>
    <div class="item">
      <div class="entryKeys">
        {{ 'companyDialog.vehiclesSelectLabel' | translate }}
      </div>
      <div class="entryValues">{{company?.vehicles?.length}}</div>
    </div>
    <div class="item">
      <div class="entryKeys">
        {{ 'companyDialog.driversSelectLabel' | translate }}
      </div>
      <div class="entryValues">{{company?.users?.length}}</div>
    </div>
    <div class="item">
      <div class="entryKeys">
        {{ 'companyDialog.chargepointsSelectLabel' | translate }}
      </div>
      <div class="entryValues">{{ company?.chargePoints?.length }}</div>
    </div>
  </div>
</div>
