import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SUPPORTED_LANGUAGES } from '../../../shared/predefined-values';
import { Language } from '../../../shared/language';
import { NGXLogger } from 'ngx-logger';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
    readonly supportedLanguages = SUPPORTED_LANGUAGES;
    settingsForm!: FormGroup;

    constructor(
        private readonly translate: TranslateService,
        private readonly logger: NGXLogger,
        private readonly fb: FormBuilder,
    ) {}

    ngOnInit(): void {
        this.settingsForm = this.fb.group({
            location: true,
            notifications: true,
            language: this.supportedLanguages[0],
        });

        const language = this.findLanguage(this.translate.currentLang);
        this.settingsForm.patchValue({
            language: language,
        });

        this.settingsForm.get('language')?.valueChanges.subscribe({
            next: (result: Language | null) => {
                this.changeLanguage(result);
            },
            error: (error: unknown) => {
                this.logger.error('Language could not be set', error);
            },
        });
    }

    private findLanguage(code: string): Language {
        return this.supportedLanguages
            .filter(l => l.code === code)
            .values()
            .next().value;
    }

    changeLanguage(language: Language | null) {
        if (language) {
            this.logger.info('setLanguage: ' + language.code);
            this.translate.use(language.code);
        }
    }
}
