import {Component} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ChargepointService} from '../chargepoint-list.service';
import {DatePipe} from '@angular/common';
import {ChargepointFilterInput} from '../../graphql/generated/cp-graphql_types';

@Component({
    selector: 'app-filter-chargepoint-dialog',
    templateUrl: './filter-chargepoint-dialog.component.html',
    styleUrl: './filter-chargepoint-dialog.component.scss',
})
export class FilterChargepointDialogComponent {
    filterForm: FormGroup;
    constructor(
        private fb: FormBuilder,
        private datePipe: DatePipe,
        public activeModal: NgbActiveModal,
        public chargepointService: ChargepointService,
    ) {
        this.filterForm = this.fb.group({
            search: this.fb.group({
                contains: [[]],
            }),
            lastActivity: this.fb.group({
                before: null,
                after: null,
            }),
            createdAt: this.fb.group({
                before: null,
                after: null,
            }),
            connectionStatus: null,
            company: null,
            user: null,
        });
        let setFilters = this.chargepointService.filter$.getValue();
        if (Object.keys(setFilters).length) {
            this.filterForm.patchValue({
                search: { contains: setFilters.search?.contains },
                lastActivity: {
                    before: this.dateToNgbDateStruct(setFilters.lastActivity?.before),
                    after: this.dateToNgbDateStruct(setFilters.lastActivity?.after),
                },
                createdAt: {
                    before: this.dateToNgbDateStruct(setFilters.createdAt?.before),
                    after: this.dateToNgbDateStruct(setFilters.createdAt?.after),
                },
                connectionStatus: setFilters.connectionStatus,
                company: setFilters.company,
                user: setFilters.user,
            });
        }
    }

    filterChargepoints() {
        this.constructDateField('createdAt');
        this.constructDateField('lastActivity');
        let nonNullForm = this.removeNull(this.filterForm.value);
        this.chargepointService.filter$.next(nonNullForm);
    }

    setFormFields(filter: ChargepointFilterInput) {
        this.filterForm.patchValue({
            search: filter.search,
            lastActivity: this.dateToNgbDateStruct(filter.lastActivity?.after),
        });
    }

    removeNull(obj: ChargepointFilterInput) {
        return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null && v));
    }

    getDateFromForm(formField: string) {
        let ngbDate = this.filterForm.get(formField)?.value;
        if (ngbDate) {
            let date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
            return this.datePipe.transform(date, 'yyyy-MM-dd');
        }
        return undefined;
    }

    dateToNgbDateStruct(dateString: Date | undefined) {
        if (dateString) {
            let date: Date = new Date(dateString);
            return {
                year: date.getFullYear(),
                month: date.getMonth() + 1,
                day: date.getDate(),
            };
        }
        return '';
    }

    constructDateField(formField: string) {
        let formDateAfter = this.getDateFromForm(formField + '.after');
        let formDateBefore = this.getDateFromForm(formField + '.before');
        this.filterForm.controls[formField].patchValue({ after: formDateAfter });
        this.filterForm.controls[formField].patchValue({ before: formDateBefore });
    }
}
