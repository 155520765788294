import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReportingComponent} from './reporting.component';
import {TranslateModule} from "@ngx-translate/core";
import {RouterModule} from "@angular/router";
import {ReportingService} from "./reporting.service";
import {LocalizedDatePipe} from "./pipes/localized-date.pipe";
import {TransactionDetailComponent} from './transaction-detail/transaction-detail.component';
import {TrackedTransactionsComponent} from './tracked-transactions/tracked-transactions.component';
import {ManualTransactionsComponent} from './manual-transactions/manual-transactions.component';
import {NgbModule, NgbNav, NgbNavItem} from "@ng-bootstrap/ng-bootstrap";
import {TransactionDetailBreadcrumb} from "./transaction-detail/transaction-detail.breadcrumb";
import {AllTransactionsComponent} from './all-transactions/all-transactions.component';
import {PipesModule} from "../pipes/pipes.module";

@NgModule({
    declarations: [
        ReportingComponent,
        LocalizedDatePipe,
        TransactionDetailComponent,
        TrackedTransactionsComponent,
        ManualTransactionsComponent,
        AllTransactionsComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        NgbNav,
        NgbNavItem,
        NgbModule,
        PipesModule,
    ],
    schemas: [NO_ERRORS_SCHEMA],
    providers: [ReportingService, TransactionDetailBreadcrumb],
})
export class ReportingModule {
}
