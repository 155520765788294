import { Component, Input, OnInit } from '@angular/core';
import { ChargePoint } from '../../graphql/generated/cp-graphql_types';
import { ChargepointService } from '../chargepoint-list.service';
import { NGXLogger } from 'ngx-logger';
import { DeleteDialogComponent, DeleteDialogData } from 'src/app/shared/delete-dialog/delete-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserDataService } from 'src/app/user-data.service';

/**
 * Component for the tag <code>app-chargepoint</code>.
 */
@Component({
    selector: 'app-chargepoint',
    templateUrl: './chargepoint.component.html',
    styleUrls: ['./chargepoint.component.scss'],
})
export class ChargepointComponent implements OnInit {
    @Input() chargepoint!: ChargePoint;
    errorMsg: string | undefined;
    checked: Array<ChargePoint> = [];
    deletePermission: boolean = false;

    constructor(
        readonly chargepointService: ChargepointService,
        private userDataService: UserDataService,
        private readonly logger: NGXLogger,
        private modalService: NgbModal,
    ) {}

    ngOnInit(): void {
        this.deletePermission = this.hasPermission();
    }

    delete(chargepoint: ChargePoint) {
        let data = {} as DeleteDialogData;
        this.logger.debug('Delete chargepoints:', chargepoint);
        data.title = 'chargepoints.titleDeleteSingle';
        data.question = 'chargepoints.questionDeleteSingle';
        data.items = [chargepoint.name];

        const dialogRef = this.modalService.open(DeleteDialogComponent);
        dialogRef.componentInstance.data = data;
        dialogRef.result
            .then(() => {
                this.chargepointService.deleteChargepoints([chargepoint.id]).subscribe({
                    next: delResponse => this.logger.debug('Result of delete: ', delResponse),
                    error: (err: unknown) => this.logger.debug('An Error occured: ', err),
                    complete: () => {
                        this.chargepointService.refresh();
                    },
                });
                return 0;
            })
            .catch(cancel => {
                this.logger.debug('Dialog', cancel);
            });
    }
    hasPermission() {
        if (this.userDataService.checkPermission('chargepoint:modify:client')) {
            return true;
        } else if (
            this.userDataService.checkPermission('chargepoint:modify:company') &&
            this.chargepoint.owner.__typename === 'Company' &&
            this.chargepoint.owner.company?.id === this.userDataService.companyId()
        ) {
            return true;
        } else if (
            this.userDataService.checkPermission('chargepoint:modify:own') &&
            this.chargepoint.owner.id == this.userDataService.userId()
        ) {
            return true;
        }
        return false;
    }
}
