<div class="container">
  <h4>{{ "filter.title" | translate }}</h4>
  <form [formGroup]="filterForm">
    <div class="row mb-3">
      <div class="col">
        <div class="mb-3" formGroupName="search">
          <label class="form-label es-form-lable ">{{ "filter.search" | translate }}</label>
          <input type="text" class="form-control es-form-input" formControlName="contains"/>
        </div>
      </div>
    </div>
    <fieldset class="mb-3">
      <legend class="w-auto">{{ "filter.lastActivity" | translate }}</legend>
      <div class="row" formGroupName="lastActivity">
        <div class="col">
          <label class="form-label es-form-lable ">{{ "filter.after" | translate }}</label>
          <div class="input-group">
            <input class="form-control" placeholder="dd.mm.yyyy" ngbDatepicker formControlName="after"
                   #lastActivityAfter="ngbDatepicker"/>
            <button class="btn btn-outline-secondary calendar" (click)="lastActivityAfter.toggle()"
                    type="button"></button>
          </div>
        </div>
        <div class="col">
          <label class="form-label es-form-lable ">{{ "filter.before" | translate }}</label>
          <div class="input-group">
            <input class="form-control" placeholder="dd.mm.yyyy" ngbDatepicker formControlName="before"
                   #lastActivityBefore="ngbDatepicker"/>
            <button class="btn btn-outline-secondary calendar" (click)="lastActivityBefore.toggle()"
                    type="button"></button>
          </div>
        </div>
      </div>
    </fieldset>
    <fieldset class="mb-3">
      <legend class="w-auto">{{ "filter.created" | translate }}</legend>
      <div class="row" formGroupName="createdAt">
        <div class="col">
          <label class="form-label es-form-lable">{{ "filter.after" | translate }}</label>
          <div class="input-group">
            <input class="form-control" placeholder="dd.mm.yyyy" ngbDatepicker formControlName="after"
                   #createdAtAfter="ngbDatepicker"/>
            <button class="btn btn-outline-secondary calendar" (click)="createdAtAfter.toggle()" type="button"></button>
          </div>
        </div>
        <div class="col">
          <label class="form-label es-form-lable ">{{ "filter.before" | translate }}</label>
          <div class="input-group">
            <input class="form-control" placeholder="dd.mm.yyyy" ngbDatepicker formControlName="before"
                   #createdAtBefore="ngbDatepicker"/>
            <button class="btn btn-outline-secondary calendar" (click)="createdAtBefore.toggle()"
                    type="button"></button>
          </div>
        </div>
      </div>
    </fieldset>
    <div class="col">
      <label class="form-label es-form-lable">{{ "filter.connectionStatus" | translate }}</label>
      <select class="form-select" formControlName="connectionStatus">
        <option value=""></option>
        <option value="DISCONNECTED">
          {{ "chargepoints.connectionStatus.disconnected" | translate }}
        </option>
        <option value="CONNECTED">
          {{ "chargepoints.connectionStatus.connected" | translate }}
        </option>
        <option value="PENDING">
          {{ "chargepoints.connectionStatus.pending" | translate }}
        </option>
      </select>
    </div>


    <div class="dialog-button-bar">
      <button class="bs-secondary-button text-nowrap btn" (click)="activeModal.dismiss('cancel')" type="button">
        {{ 'idTokenDialog.cancel' | translate }}
      </button>
      <button class="bs-primary-button btn-block ml-1 btn" (click)="filterChargepoints(); activeModal.close('save')"
              type="submit">
        Filtern
      </button>
    </div>
  </form>
</div>
