import { Component, EventEmitter, Output } from '@angular/core';
import { ChargepointService } from '../chargepoint-list.service';

@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrl: './filter.component.scss',
})
export class FilterComponent {
    @Output() clickedDelete = new EventEmitter();
    filterChips = this.chargepointService.flatFilterChips$;
    constructor(private chargepointService: ChargepointService) {}

    delete(filterKey: string) {
        this.chargepointService.deleteFilter(filterKey);
        this.clickedDelete.emit('delete');
    }
}
