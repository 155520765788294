import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ChargingOperationsComponent } from './charging-operations.component';
import { ChargingOperationDetailviewComponent } from './charging-operation-detailview/charging-operation-detailview.component';
import { RouterModule } from '@angular/router';
import { AddChargingOperationDialogComponent } from './add-charging-operation-dialog/add-charging-operation-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        ChargingOperationsComponent,
        ChargingOperationDetailviewComponent,
        AddChargingOperationDialogComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        ReactiveFormsModule,
    ],
})
export class ChargingOperationsModule {}
