export function fileDownload(fileName: string, fileContent: string, fileType: string) {
    const htmlAnchorElement = document.createElement('a');
    htmlAnchorElement.style.opacity = '0';
    htmlAnchorElement.style.display = 'none';
    htmlAnchorElement.style.visibility = 'hidden';
    const blob = new Blob([fileContent], {type: fileType});
    const url = window.URL.createObjectURL(blob);
    htmlAnchorElement.href = url;
    htmlAnchorElement.download = fileName;
    htmlAnchorElement.click();
    window.URL.revokeObjectURL(url);
    htmlAnchorElement.remove();
}
