import { Injectable } from '@angular/core';
import { User } from './profile/user';
import { NGXLogger } from 'ngx-logger';
import { KeycloakService } from 'keycloak-angular';
import { OneCompanyNameGQL } from './master-data/graphql/generated/mdm_graphql_types';
import { map } from 'rxjs';

interface Token {
    client_id: string;
    client_name: string;
    company_id: string;
    company_name: string;
    email: string;
    email_verified: string;
    family_name: string;
    given_name: string;
    name: string;
    scope: string;
    user_id: string;
}

@Injectable({ providedIn: 'root' })
export class UserDataService {
    token = this.keycloak.getKeycloakInstance().tokenParsed as Token;
    user: User = {
        id: this.token.user_id,
        firstName: this.token.given_name,
        lastName: this.token.family_name,
        street: 'Straßennummer',
        houseNumber: '1',
        postalCode: 76_131,
        town: 'Karlruhe',
        email: this.token.email,
        company_name: this.token.company_name,
    };

    constructor(
        private readonly logger: NGXLogger,
        protected readonly keycloak: KeycloakService,
        private oneCompanyNameGql: OneCompanyNameGQL,
    ) {}

    public getUser(): User {
        return this.user;
    }

    public getCompanyQuery(): any {
        return this.oneCompanyNameGql
            .watch({ companyId: this.token.company_id })
            .valueChanges.pipe(map(result => result.data.company?.name));
    }

    updateUser(updatedUser: User) {
        this.user = updatedUser;
    }

    checkPermission(requiredPermission: string): boolean {
        return this.token.scope.split(' ').includes(requiredPermission);
    }

    public userId() {
        return this.token.user_id;
    }
    public companyId() {
        return this.token.company_id;
    }
}
