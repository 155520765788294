import {TrackedTransaction} from "../graphql/generated/reporting_graphql_types";
import {DeepPartial} from "../../shared/typescript/deep-partial";

export type TrackedTransactionTableModel = {
    root: any;
    firstName: string;
    lastName: string;
    chargePoint: string;
    timeStart: Date;
    timeStop: Date;
    duration: number;
    transferredEnergy: number;
}

export function buildTrackedTransactionTableModels(
    transactions: DeepPartial<TrackedTransaction>[]
): TrackedTransactionTableModel[] {
    if (!transactions || transactions.length === 0) {
        return [];
    }

    function duration(transaction: any) {
        const timeStop = transaction.startDate ?? 0;
        const timeStart = transaction.endDate ?? 0;
        const duration = new Date(timeStop).getTime() - new Date(timeStart).getTime();
        if (duration <= 0) {
            return 0
        }
        return duration / 1000 / 3600
    }

    return transactions.map(transaction => {
        const firstName = transaction?.idToken?.user?.firstName;
        const lastName = transaction?.idToken?.user?.lastName;
        const chargePoint = transaction?.chargePoint?.name;
        const timeStart = new Date(transaction.startDate ?? 0);
        const timeStop = new Date(transaction.endDate ?? 0);
        const duration = 0;
        const transferredEnergy = 0;
        return {
            root: transaction,
            firstName: firstName,
            lastName: lastName,
            chargePoint: chargePoint,
            timeStart: timeStart,
            timeStop: timeStop,
            duration: duration,
            transferredEnergy: transferredEnergy,
        } as TrackedTransactionTableModel;
    });
}
