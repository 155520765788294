<article class="personal-data">
  <header>
    <h4>{{ 'profileData.personalData' | translate}}</h4>
    <button class="btn-edit"
            type="button"
            (click)="openEditDialog()">
      <img
        ngSrc="./assets/button-edit.svg"
        alt="Icon of the button to edit the user profile"
        height="20"
        width="20">
    </button>
  </header>
  <figure>
    <img
      ngSrc="./assets/icon-profile.svg"
      alt="Icon of the user profile"
      height="96"
      width="80">
  </figure>
  <main>
    <ul class="user-data">
      <li>
        <h6>
          {{ 'profileData.driver' | translate}}
        </h6>
        <p>
          {{user.lastName}}, {{user.firstName}}
        </p>
      </li>
      <li>
        <h6>
          {{ 'profileData.street' | translate}}, {{ 'profileData.houseNumber' | translate}}
        </h6>
        <p>
          {{user.street}} {{user.houseNumber}}
        </p>
      </li>
      <li>
        <h6>
          {{ 'profileData.postalCode' | translate}}, {{ 'profileData.town' | translate}}
        </h6>
        <p>
          {{user.postalCode}} {{user.town}}
        </p>
      </li>
      <li>
        <h6>
          {{ 'profileData.email' | translate}}
        </h6>
        <p>
          {{user.email}}
        </p>
      </li>
    </ul>
    <ul class="other-data">
      <li>
        <h6>
          {{ 'profileData.company' | translate}}
        </h6>
        <p>
          {{company.name}}
        </p>
      </li>
      <li>
        <h6>
          {{ 'profileData.vehicle' | translate}}
        </h6>
        <p>
          -
        </p>
      </li>
    </ul>
  </main>
</article>
