import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { SymbolBarService } from './symbol-bar.service';

@Component({
    selector: 'app-symbol-bar',
    templateUrl: './symbol-bar.component.html',
    styleUrls: ['./symbol-bar.component.scss'],
})
export class SymbolBarComponent implements OnInit {
    @Output() filter = new EventEmitter();
    layoutMode: string = 'card';
    constructor(private symbolBarService: SymbolBarService) { }

    ngOnInit() {
        this.symbolBarService.layoutMode$.subscribe((mode: string) => {
            this.layoutMode = mode;
        });
    }

    setCardLayout() {
        this.symbolBarService.setLayoutMode('card');
    }
    setTableLayout() {
        this.symbolBarService.setLayoutMode('table');
    }
    onFilter() {
        this.filter.emit();
    }
}
