export class PaginatedCollection<T> {
    static EMPTY = new PaginatedCollection<any>([], 0);

    pages: T[][];
    pageId: number;
    page: T[];
    pageAmount: number;

    constructor(elements: T[], pageSize: number) {
        this.pages = this.pagination(elements, pageSize);
        this.pageId = 0;
        this.page = this.pages.length > 0 ? this.pages[this.pageId] : [];
        this.pageAmount = this.pages.length;
    }

    pageChange(offset: number) {
        const pageId = this.pageId + offset;
        if (pageId < 0) {
            this.pageId = 0;
        } else if (pageId >= this.pages.length) {
            this.pageId = this.pages.length - 1;
        } else {
            this.pageId = pageId;
        }
        this.page = this.pages[this.pageId];
    }

    private pagination<T>(elements: T[], pageSize: number): T[][] {
        if (elements.length === 0 || pageSize === 0) {
            return [];
        }
        const tmp: T[] = [...elements];
        const length = elements.length;
        const amountPages = Math.ceil(length / pageSize);
        const pages: Array<T[]> = Array(amountPages);
        for (let i = 0; i < amountPages; i++) {
            pages[i] = tmp.splice(0, Math.min(pageSize, tmp.length));
        }
        return pages;
    }
}
