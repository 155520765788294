import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { UserDataService } from '../../user-data.service';
import { ErrorHandler } from '../shared/md-error-handler.service';
import { IdToken } from '../graphql/generated/mdm_graphql_types';
import { EditIdTokenDialogComponent } from './edit-id-token-dialog/edit-id-token-dialog.component';
import { IdTokenService } from './id-token-list.service';
import { BreadcrumbService } from 'src/app/layout/breadcrumb/breadcrumb.service';
import { DeleteDialogComponent, DeleteDialogData } from 'src/app/shared/delete-dialog/delete-dialog.component';

/**
 * Component for the tag <code>app-id-token-list</code> to display
 * the table of id tokens.
 */
@Component({
    selector: 'app-id-token-list',
    templateUrl: './id-token-list.component.html',
    styleUrls: ['./id-token-list.component.scss'],
})
export class IdTokenListComponent implements OnInit, OnDestroy {
    idTokens: IdToken[] = [];
    flatIdTokens: any[] = [];
    colNames: string[] = [
        'masterData.name',
        'masterData.owner',
        'masterData.companies',
        'masterData.typeOfToken',
        'masterData.tokenIdentifier',
        'masterData.tokenGroup',
        'general.state',
        'masterData.validFrom',
        'masterData.validUntil',
    ];
    addDialog = 'idTokens.add';
    loading = false;
    checked: Array<string> = [];

    constructor(
        private idTokenService: IdTokenService,
        private logger: NGXLogger,
        public errorHandler: ErrorHandler,
        private modalService: NgbModal,
        private breadcrumbService: BreadcrumbService,
    ) {
        logger.debug('IdTokenListComponent.constructor()');
    }

    /**
     * Starts the search for ID Tokens.
     */
    ngOnInit() {
        this.breadcrumbService.masterDataCrumbs(2, 'id-tokens');
        this.getIdTokens();
    }

    updateChecked(checked: string[]) {
        this.checked = checked;
    }

    /**
     * Searches for all idTokens.
     */
    getIdTokens() {
        this.logger.debug('IdTokenListComponent.getIdTokens()');
        this.loading = true;
        this.idTokenService.getIdTokens().subscribe((result: any) => {
            this.idTokens = result;
            this.flatIdTokens = this.idTokenService.flattenIdTokens(this.idTokens);
            this.logger.debug('Id-token loaded: ', this.idTokens.length);
            this.loading = false;
        });
    }

    delete(idTokenIds: string[]) {
        let data = {} as DeleteDialogData;
        let idTokens = this.idTokens.filter(idToken => idTokenIds.includes(idToken.id));
        this.logger.debug('Delete ID-Tokens:', idTokens);
        data.items = idTokens.map(idToken => idToken.name);

        if (idTokens.length === 1) {
            data.title = 'idTokenDialog.titleDeleteOneToken';
            data.question = 'idTokenDialog.questionDeleteSingle';
        } else {
            data.title = 'idTokenDialog.titleDeleteMultipleToken';
            data.question = 'idTokenDialog.questionDeleteMulti';
        }

        const dialogRef = this.modalService.open(DeleteDialogComponent);
        dialogRef.componentInstance.data = data;
        dialogRef.result
            .then(() => {
                this.idTokenService.deleteIdToken(idTokenIds).subscribe({
                    next: delResponse => this.logger.debug('Result of delete: ', delResponse),
                    error: (err: unknown) => this.logger.debug('An Error occured: ', err),
                    complete: () => {
                        this.checked = [];
                        this.idTokenService.refresh();
                    },
                });
                return 0;
            })
            .catch(cancel => {
                this.logger.debug('Dialog', cancel);
            });
    }

    ngOnDestroy(): void {
        this.breadcrumbService.masterDataCrumbs(0);
    }

    openDialogAdd() {
        this.logger.debug('ID-Token-ListComponent.openDialogAdd()');
        this.modalService.open(EditIdTokenDialogComponent);
    }

    openDialogEdit(tokenId: string) {
        this.logger.debug('ID-Token-ListComponent.openDialogEdit()');
        let editDialogRef = this.modalService.open(EditIdTokenDialogComponent);
        editDialogRef.componentInstance.idTokenId = tokenId;
    }
}
