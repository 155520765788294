import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-button-bar',
    templateUrl: './button-bar.component.html',
    styleUrls: ['./button-bar.component.scss'],
})
export class ButtonBarComponent {
    @Input() addDialog: string = '';
    @Input() checkedAmount: number = 0;
    @Input() showDelete: boolean = true;
    @Output() add = new EventEmitter();
    @Output() delete = new EventEmitter();

    clickedOnAdd() {
        this.add.emit();
    }

    clickedOnDelete() {
        this.delete.emit();
    }
}
