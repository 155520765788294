import {
    NgbDateParserFormatter,
    NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

@Injectable()
export class DateFormatter extends NgbDateParserFormatter {
    readonly DELIMITER = '.';

    parse(value: string): NgbDateStruct | null {
        if (value) {
            let date = value.split(this.DELIMITER);
            return {
                day: parseInt(date[0], 10),
                month: parseInt(date[1], 10),
                year: parseInt(date[2], 10),
            };
        }
        return null;
    }

    format(date: NgbDateStruct | null): string {
        let day = date ? (date?.day < 10 ? '0' + date?.day : date?.day) : '';
        let month = date
            ? date?.month < 10
                ? '0' + date?.month
                : date?.month
            : '';
        return date
            ? day + this.DELIMITER + month + this.DELIMITER + date.year
            : '';
    }
}
