import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { DriverService } from '../driver-list.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    AllCompaniesGQL,
    AllCompaniesQuery,
    AllVehiclesGQL,
    AllVehiclesQuery,
    CreateUserInput,
    User,
} from '../../graphql/generated/mdm_graphql_types';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

/**
 * Component for the tag <code>app-edit-driver-dialog</code>
 * to display a dialog to edit a driver.
 */
@Component({
    selector: 'app-edit-driver-dialog',
    templateUrl: './edit-driver-dialog.component.html',
    styleUrls: ['./edit-driver-dialog.component.scss'],
})
export class EditDriverDialogComponent implements OnInit {
    @Input() userId: string | undefined;
    initialUser = {} as User;
    userForm!: FormGroup;
    editing = new BehaviorSubject(false);
    vehicles: Observable<AllVehiclesQuery['vehicles']>;
    companies: Observable<AllCompaniesQuery['companies']>;

    constructor(
        private readonly driverService: DriverService,
        private readonly logger: NGXLogger,
        private fb: FormBuilder,
        private vehiclesQl: AllVehiclesGQL,
        private companiesQl: AllCompaniesGQL,
        public activeModal: NgbActiveModal,
    ) {
        logger.debug(
            'EditDriverDialogComponent.constructor() with data:',
            this.userId,
        );
        this.vehicles = vehiclesQl
            .watch()
            .valueChanges.pipe(map(result => result.data.vehicles));
        this.companies = companiesQl
            .watch()
            .valueChanges.pipe(map(result => result.data.companies));
    }

    ngOnInit() {
        if (
            this.userId !== undefined &&
            this.userId !== '' &&
            this.userId !== null
        ) {
            this.logger.debug('Load driver from server ID=', this.userId);
            this.driverService.getUser(this.userId).subscribe((result: any) => {
                this.initialUser = result;
                this.setFormData(this.initialUser);
            });
        }
        this.userForm = this.fb.group({
            lastName: ['', [Validators.required, Validators.maxLength(64)]],
            firstName: ['', [Validators.required, Validators.maxLength(64)]],
            email: [
                '',
                [
                    Validators.required,
                    Validators.email,
                    Validators.maxLength(64),
                ],
            ],
            address: this.fb.group({
                country: [''],
                houseNumber: ['', [Validators.maxLength(5)]],
                street: [''],
                town: [''],
                zipCode: ['', [Validators.pattern('^[0-9]*$')]],
            }),
            companyId: ['', [Validators.required]],
        });
    }

    get firstName() {
        return this.userForm.controls.firstName;
    }

    get lastName() {
        return this.userForm.controls.lastName;
    }
    get email() {
        return this.userForm.controls.email;
    }

    addUser() {
        this.driverService
            .createUser(this.userForm.value)
            .subscribe((data: {}) => {
                this.logger.debug('create new User RC=', data);
                this.driverService.refresh();
            });
    }

    editUser() {
        this.driverService
            .updateUser({
                firstName: this.userForm.value.firstName,
                lastName: this.userForm.value.lastName,
                email: this.userForm.value.email,
                address: this.userForm.value.address,
                companyId: this.userForm.value.companyId,
                id: this.initialUser.id,
                version: this.initialUser.version,
            })
            .subscribe((data: {}) => {
                this.logger.debug('create new User RC=', data);
                this.driverService.refresh();
            });
        this.isEditing(false);
    }

    isEditing(editStatus: boolean) {
        this.editing.next(editStatus);
    }

    private setFormData(user: User) {
        this.isEditing(true);
        this.userForm.patchValue({
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            address: user.address,
            companyId: user.company.id,
        });
    }
}
