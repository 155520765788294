import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { UserDataService } from '../../user-data.service';
import { ErrorHandler } from '../shared/md-error-handler.service';
import { CompanyService } from './company-list.service';
import { EditCompanyDialogComponent } from './edit-company-dialog/edit-company-dialog.component';
import { Company } from '../graphql/generated/mdm_graphql_types';
import { BreadcrumbService } from 'src/app/layout/breadcrumb/breadcrumb.service';
import { DeleteDialogComponent, DeleteDialogData } from 'src/app/shared/delete-dialog/delete-dialog.component';

/**
 * Component for the tag <code>app-company-list</code> to display
 * the table of companies.
 */
@Component({
    selector: 'app-company-list',
    templateUrl: './company-list.component.html',
    styleUrls: ['./company-list.component.scss'],
})
export class CompanyListComponent implements OnInit, OnDestroy {
    role = '';
    companies: Company[] = [];
    flatCompanies: any[] = [];
    colNames: string[] = [
        'masterData.companies',
        'general.location',
        'companies.vehicles',
        'companies.num_drivers',
        'companies.chargepoints',
        'companies.idToken',
    ];
    addDialog = 'companies.addCompany';
    loading = false;
    checked: Array<string> = [];
    modifyCompany: boolean = false;

    constructor(
        private readonly companyService: CompanyService,
        private readonly logger: NGXLogger,
        private readonly userDataService: UserDataService,
        public errorHandler: ErrorHandler,
        private modalService: NgbModal,
        private breadcrumbService: BreadcrumbService,
    ) {
        logger.debug('CompanyListComponent.constructor()');
        this.modifyCompany = this.hasPermission();
    }

    /**
     * Starts the search for companies.
     */
    ngOnInit() {
        this.breadcrumbService.masterDataCrumbs(2, 'companies');
        this.getCompanies();
    }

    updateChecked(checked: string[]) {
        this.checked = checked;
    }

    getCompanies() {
        this.logger.debug('CompanyListComponent.getCompanies()');
        this.loading = true;
        this.companyService.getCompanies().subscribe((result: any) => {
            this.companies = result;
            this.flatCompanies = this.companyService.flattenCompanies(this.companies);
            this.logger.debug('Companies loaded: ', this.companies.length);
            this.loading = false;
        });
    }

    openDialogAdd() {
        this.logger.debug('CompanyListComponent.openDialogAdd()');
        this.modalService.open(EditCompanyDialogComponent);
    }

    openDialogEdit(companyId: string) {
        this.logger.debug('CompanyListComponent.openDialogEdit()');
        let editDialogRef = this.modalService.open(EditCompanyDialogComponent);
        editDialogRef.componentInstance.companyId = companyId;
    }

    delete(companyIds: string[]) {
        let data = {} as DeleteDialogData;
        let companies = this.companies.filter(company => companyIds.includes(company.id));
        this.logger.debug('Delete Companies:', companies);
        data.items = companies.map(companies => companies.name);

        if (companies.length === 1) {
            data.title = 'companyDialog.titleDeleteOneCompany';
            data.question = 'companyDialog.questionDeleteSingle';
        } else {
            data.title = 'companyDialog.titleDeleteMultipleCompanies';
            data.question = 'companyDialog.questionDeleteMulti';
        }

        const dialogRef = this.modalService.open(DeleteDialogComponent);
        dialogRef.componentInstance.data = data;
        dialogRef.result
            .then(() => {
                this.companyService.deleteCompany(companyIds).subscribe({
                    next: delResponse => this.logger.debug('Result of delete: ', delResponse),
                    error: (err: unknown) => this.logger.debug('An Error occured: ', err),
                    complete: () => {
                        this.checked = [];
                        this.companyService.refresh();
                    },
                });
                return 0;
            })
            .catch(cancel => {
                this.logger.debug('Dialog', cancel);
            });
    }

    ngOnDestroy(): void {
        this.breadcrumbService.masterDataCrumbs(0);
    }

    hasPermission() {
        return this.userDataService.checkPermission('company:modify:client');
    }
}
