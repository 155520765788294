import { Injectable } from '@angular/core';
import {
    GetAllUsersGQL,
    GetInvoiceByIdGQL,
    GetInvoiceByIdQuery,
    GetInvoicesByCompanyIdGQL,
    GetInvoicesByUserIdGQL, Invoice,
    User
} from "../graphql/generated/accounting_graphql_types";
import { map, Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AccountingService {
    constructor(private readonly getAllUsersGQL: GetAllUsersGQL,
                private readonly getInvoicesByUserIdGQL: GetInvoicesByUserIdGQL,
                private readonly getInvoicesByCompanyIdGQL: GetInvoicesByCompanyIdGQL,
                private readonly getInvoiceByIdGQL: GetInvoiceByIdGQL
    ) {
    }

    public getAllUsers(): Observable<Array<Pick<User, 'id' | 'firstName' | 'lastName'>>> {
        return this.getAllUsersGQL
            .watch()
            .valueChanges
            .pipe(map(value => value.data.getUsers.content as User[]));
    }

    public getInvoicesByUserId(userId: string) {
        return this.getInvoicesByUserIdGQL
            .watch({ userId: userId })
            .valueChanges
            .pipe(map(value => value.data.findUser ?? []));
    }

    public getInvoicesByCompanyId(companyId: string): Observable<Array<Pick<Invoice, 'id' | 'year' | 'month'>>> {
        return this.getInvoicesByCompanyIdGQL
            .watch({ companyId: companyId })
            .valueChanges
            .pipe(map(value => value.data.findCompany?.invoices?.content as Invoice[] ?? []));
    }

    public getInvoiceById(invoiceId: string, pageId: number, pageSize: number, info: boolean, pageSizeChange: boolean): Observable<GetInvoiceByIdQuery['findInvoice']> {
        return this.getInvoiceByIdGQL
            .watch({
                invoiceId: invoiceId,
                pageId: pageId,
                pageSize: pageSize,
            })
            .valueChanges
            .pipe(map(value => value.data.findInvoice));
    }

    // public getInvoiceTransactions(invoiceId: string, pageId: number, pageSize: number) {
    //     return this.getInvoiceTransactionsGQL.watch({
    //         invoiceId: invoiceId,
    //         pageId: pageId,
    //         pageSize: pageSize
    //     }).valueChanges.pipe(map(value => value.data.findInvoice?.transactions), filter(isDefined));
    // }
}
