<div class="symbol-bar">
  <button *ngIf="layoutMode === 'card'" class="btn symbol">
    <img src="assets/button-tiles-selected.svg"/>
  </button>
  <button *ngIf="layoutMode === 'table'"class="btn symbol" (click)="setCardLayout()">
    <img src="assets/button-tiles-deselected.svg"/>
  </button>
  <button *ngIf="layoutMode === 'table'"class="btn symbol">
    <img src="assets/button-list-selected.svg"/>
  </button>
  <button *ngIf="layoutMode === 'card'"class="btn symbol" (click)="setTableLayout()">
    <img src="assets/button-list-deselected.svg"/>
  </button>
  <button class="btn symbol filter" (click)="onFilter()">
    <img src="assets/button-filter.svg"/>
  </button>
</div>
