<nav ngbNav #nav="ngbNav" class="nav-tabs justify-content-center mb-3">
  <ng-container ngbNavItem>
    <li class="nav-item">
      <a class="nav-link btn-link" routerLink="all" [routerLinkActive]="['active']">
        {{ 'reporting.tab.all' | translate }}
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link btn-link" routerLink="manual" [routerLinkActive]="['active']">
        {{ 'reporting.tab.manual' | translate }}
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link btn-link" routerLink="tracked" [routerLinkActive]="['active']">
        {{ 'reporting.tab.tracked' | translate }}
      </a>
    </li>
  </ng-container>
</nav>

<router-outlet></router-outlet>
