<nav ngbNav #nav="ngbNav" class="nav-tabs justify-content-center">
  <ng-container ngbNavItem>
    <li class="nav-item">
      <a class="nav-link btn-link" routerLink="chargepoints" [routerLinkActive]="['active']">
        {{ 'chargepoints.chargingStation' | translate }}
      </a>
    </li>

    <li class="nav-item">
      <a class="nav-link btn-link" routerLink="wallboxes" [routerLinkActive]="['active']">
        {{ 'chargepoints.wallboxes' | translate }}
      </a>
    </li>

    <li class="nav-item">
      <a class="nav-link btn-link" routerLink="compatible-wallboxes" [routerLinkActive]="['active']">
        {{ 'chargepoints.compatibleWallboxes' | translate }}
      </a>
    </li>
  </ng-container>
</nav>

<router-outlet></router-outlet>
