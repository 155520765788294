import { Component, OnInit, OnDestroy } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BreadcrumbService } from 'src/app/layout/breadcrumb/breadcrumb.service';

@Component({
    selector: 'app-wallboxes',
    templateUrl: './wallboxes.component.html',
    styleUrls: ['./wallboxes.component.scss'],
})
export class WallboxesComponent implements OnInit, OnDestroy {
    /** TODO Add logic to retrieve wallboxes from backend  */
    wallboxesExist: boolean;

    constructor(private readonly logger: NGXLogger, private readonly breadcrumbService: BreadcrumbService) {
        logger.debug('WallboxesComponent.constructor()');
        this.wallboxesExist = false;
    }

    ngOnInit() {
        this.breadcrumbService.wallboxesCrumbs(1);
    }
    ngOnDestroy() {
        this.breadcrumbService.wallboxesCrumbs(0);
    }
}
