<div class="chargepoint">
  <app-overview-card [chargepoints]="chargepoints"></app-overview-card>
  <app-symbol-bar (filter)="openDialogFilter()"></app-symbol-bar>
  <app-filter (clickedDelete)="deleteFilter()"></app-filter>
  <app-waiting *ngIf=" loading; else showSearchResults"></app-waiting>
  <ng-template #showSearchResults>
    <div class="chargepoint-card" *ngIf="chargepoints.length > 0 && layoutMode === 'card'; else showErrorMsg">
      <div *ngFor="let chargePoint of chargepoints">
        <app-chargepoint [chargepoint]="chargePoint"></app-chargepoint>
      </div>
    </div>

    <div *ngIf="chargepoints.length > 0 && layoutMode === 'table'; else showErrorMsg">
      <app-table [colNames]="colNames" [data]="flatChargepoints" (checkedChange)="updateChecked($event)"
        (edit)="openDialogEdit($event)" (delete)="delete($event)" (sortInput)="sort($event)"></app-table>
    </div>
    <div class="page-button-bar">
      <button class="btn page-button" [disabled]="!hasPreviousPage"
        (click)="getPrevChargepoints(pageSize, startCursor)">{{ 'chargepoints.previous' | translate}}</button>
      <button class="btn page-button next" [disabled]="!hasNextPage" (click)="getChargepoints(pageSize, endCursor)">
        {{ 'chargepoints.next' | translate}}
      </button>
    </div>
    <app-button-bar [addDialog]="addDialog" [checkedAmount]="checked.length" [showDelete]="layoutMode === 'table'"
      (add)="openDialogAdd()" (delete)="delete(checked)"></app-button-bar>

    <ng-template #showErrorMsg>
    </ng-template>
  </ng-template>
</div>
