import { Component } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

/**
 * Component for the tag <code>app-waiting</code> to display the waiting of data.
 */
@Component({
    selector: 'app-waiting',
    templateUrl: './waiting.component.html',
    styleUrls: ['./waiting.component.scss'],
})
export class WaitingComponent {
    /**
     * Creates an instance of WaitingComponent.
     * @param logger Service for logging.
     */
    constructor(private readonly logger: NGXLogger) {
        logger.debug('WaitingComponent.constructor()');
    }
}
