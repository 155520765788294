import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { VehicleService } from '../vehicle-list.service';
import { BreadcrumbService } from 'src/app/layout/breadcrumb/breadcrumb.service';
import { Vehicle } from '../../graphql/generated/mdm_graphql_types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditVehicleDialogComponent } from '../edit-vehicle-dialog/edit-vehicle-dialog.component';

@Component({
    selector: 'app-details-vehicle',
    templateUrl: './details-vehicle.component.html',
    styleUrls: ['./details-vehicle.component.scss'],
})
export class DetailsVehicleComponent implements OnInit {
    vehicle: Vehicle | undefined;

    constructor(
        private readonly vehicleService: VehicleService,
        private readonly route: ActivatedRoute,
        private readonly logger: NGXLogger,
        private breadcrumbService: BreadcrumbService,
        private modalService: NgbModal,
    ) {
        logger.debug('DetailsVehicleComponent.constructor()');
        logger.debug('DetailsVehicleComponent.constructor(): this.vehicle=', this.vehicle);
    }

    ngOnInit() {
        const id = this.route.snapshot.paramMap.get('id') ?? '';
        this.breadcrumbService.masterDataCrumbs(3, 'vehicles', id);
        this.logger.debug('DetailsVehicleComponent.ngOnInit(): id=', id);
        this.vehicleService.getVehicle(id).subscribe((result: any) => {
            this.vehicle = result;
        });
    }

    openDialogEdit() {
        this.logger.debug('DetailsVehicleComponent.openDialogEdit()');
        let editDialogRef = this.modalService.open(EditVehicleDialogComponent, {
            centered: true,
            ariaLabelledBy: 'modal-basic-title',
            backdrop: 'static',
            keyboard: false,
            windowClass: 'modal',
        });
        editDialogRef.componentInstance.vehicleId = this.vehicle?.id;
    }
}
