import {Component, OnDestroy, OnInit} from '@angular/core';
import {EditChargepointDialogComponent} from './edit-chargepoint-dialog/edit-chargepoint-dialog.component';
import {ChargepointService, CPQueryVariables} from './chargepoint-list.service';
import {NGXLogger} from 'ngx-logger';
import {interval, Subscription} from 'rxjs';
import {ChargePoint, ChargePointEdge, ChargePointSort, SortOrder} from '../graphql/generated/cp-graphql_types';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SymbolBarService} from 'src/app/shared/symbol-bar/symbol-bar.service';
import {BreadcrumbService} from 'src/app/layout/breadcrumb/breadcrumb.service';
import {DeleteDialogComponent, DeleteDialogData} from 'src/app/shared/delete-dialog/delete-dialog.component';
import {FilterChargepointDialogComponent} from './filter-chargepoint-dialog/filter-chargepoint-dialog.component';

/**
 * Component for the tag <code>app-chargepoint-list</code> to first display
 * the waiting for the charging points and then the loaded charging points,
 * i.e., the charging points, or an error message.
 */
@Component({
    selector: 'app-chargepoint-list',
    templateUrl: './chargepoint-list.component.html',
    styleUrls: ['./chargepoint-list.component.scss'],
})
export class ChargepointListComponent implements OnInit, OnDestroy {
    layoutMode: string = 'card';
    chargepoints: ChargePoint[] = [];
    flatChargepoints: any[] = [];
    colNames: string[] = [
        'chargepoints.chargingStation',
        'chargepoints.owner',
        'chargepoints.lastTransaction',
        'chargepoints.createdAt',
        'chargepoints.status',
    ];
    addDialog = 'chargepointList.addChargepoint';
    loading = false;
    checked: Array<string> = [];
    crumbs$ = this.breadcrumbService.crumbs$;
    filter$ = this.chargepointService.filter$;
    filterChips$ = this.chargepointService.flatFilterChips$;
    hasNextPage: boolean = false;
    hasPreviousPage: boolean = false;
    startCursor: string | undefined = undefined;
    endCursor: string | undefined = undefined;
    sortInput = {} as ChargePointSort;
    pollQueryVariables$ = this.chargepointService.pollQueryVariables$;
    timerSubscribtion!: Subscription;
    pollInterval = 5000;
    pageSize = 20;

    constructor(
        private readonly chargepointService: ChargepointService,
        private readonly symbolBarService: SymbolBarService,
        private readonly logger: NGXLogger,
        private breadcrumbService: BreadcrumbService,
        private modalService: NgbModal,
    ) {
        logger.debug('ChargepointListComponent.constructor()');
        this.timerSubscribtion = interval(this.pollInterval).subscribe(() => {
            this.pollChargepoints(this.pollQueryVariables$.value);
        });
    }

    /**
     * Starts the search for charging points.
     */
    ngOnInit() {
        this.getChargepoints(this.pageSize);
        this.symbolBarService.layoutMode$.subscribe((mode: string) => {
            this.layoutMode = mode;
        });
        this.breadcrumbService.chargepointCrumbs(1);
    }

    updateChecked(checked: string[]) {
        this.checked = checked;
    }

    /**
     * Searches for all charging points.
     */
    getChargepoints(first: number, cursor?: any) {
        this.pollQueryVariables$.next({
            first: first,
            after: cursor,
            last: undefined,
            before: undefined,
            sort: this.sortInput,
            filter: this.filter$.value,
        });
        this.logger.debug('ChargepointListComponent.getChargepoints()');
        this.chargepointService.getChargepoints(this.pollQueryVariables$.value).subscribe((result: any) => {
            let chargepoints = result.edges.map((item: ChargePointEdge) => item.node);
            this.chargepoints = chargepoints;
            this.hasNextPage = result.pageInfo.hasNextPage;
            this.hasPreviousPage = !!cursor;
            this.startCursor = result.pageInfo.startCursor;
            this.endCursor = result.pageInfo.endCursor;
            this.flatChargepoints = this.chargepointService.flattenChargepoints(chargepoints);
            this.logger.debug('Chargepoints loaded: ', this.chargepoints.length);
        });
    }

    getPrevChargepoints(last: number, cursor?: any) {
        this.pollQueryVariables$.next({
            first: undefined,
            after: undefined,
            last: last,
            before: cursor,
            sort: this.sortInput,
            filter: this.filter$.value,
        });
        this.chargepointService.getChargepoints(this.pollQueryVariables$.value).subscribe((result: any) => {
            let chargepoints = result.edges.map((item: ChargePointEdge) => item.node);
            this.chargepoints = chargepoints;
            this.startCursor = result.pageInfo.startCursor;
            this.endCursor = result.pageInfo.endCursor;
            this.hasPreviousPage = result.pageInfo.hasPreviousPage;
            this.hasNextPage = !!cursor;
            this.flatChargepoints = this.chargepointService.flattenChargepoints(chargepoints);
            this.logger.debug('Chargepoints loaded: ', this.chargepoints.length);
        });
    }

    pollChargepoints(queryVars: CPQueryVariables) {
        this.chargepointService.getChargepoints(queryVars).subscribe((result: any) => {
            let chargepoints = result.edges.map((item: ChargePointEdge) => item.node);
            this.chargepoints = chargepoints;
            this.flatChargepoints = this.chargepointService.flattenChargepoints(chargepoints);
        });
    }

    /**
     * Opens the dialog to add a charging point.
     */
    openDialogAdd() {
        this.logger.debug('CharginPoint-ListComponent.openDialogAdd()');
        this.modalService.open(EditChargepointDialogComponent);
    }

    openDialogEdit(chargepointId: string) {
        this.logger.debug('CharginPoint-ListComponent.openDialogEdit()');
        let editDialogRef = this.modalService.open(EditChargepointDialogComponent);
        editDialogRef.componentInstance.chargepointId = chargepointId;
    }

    openDialogFilter() {
        const dialogRef = this.modalService.open(FilterChargepointDialogComponent);
        dialogRef.result
            .then(() => {
                let filter = this.filter$.value;
                this.pollQueryVariables$.next({
                    sort: this.sortInput,
                    filter: filter,
                });
                // this.filterChips = this.chargepointService.createChips(filter);
                this.chargepointService.createChips(filter);
                this.getChargepoints(this.pageSize);
                return 0;
            })
            .catch(cancel => {
                this.logger.debug('Filter', cancel);
            });
    }
    deleteFilter() {
        this.getChargepoints(this.pageSize);
    }

    delete(chargepointIds: string[]) {
        let data = {} as DeleteDialogData;
        let chargepoints = this.chargepoints.filter(chargepoint => chargepointIds.includes(chargepoint.id));
        this.logger.debug('Delete Chargepoints:', chargepoints);
        data.items = chargepoints.map(chargepoint => chargepoint.name);

        if (chargepoints.length === 1) {
            data.title = 'chargepoints.titleDeleteSingle';
            data.question = 'chargepoints.questionDeleteSingle';
        } else {
            data.title = 'chargepoints.titleDeleteMulti';
            data.question = 'chargepoints.questionDeleteMulti';
        }

        const dialogRef = this.modalService.open(DeleteDialogComponent);
        dialogRef.componentInstance.data = data;
        dialogRef.result
            .then(() => {
                this.chargepointService.deleteChargepoints(chargepointIds).subscribe({
                    next: delResponse => this.logger.debug('Result of delete: ', delResponse),
                    error: (err: unknown) => this.logger.debug('An Error occured: ', err),
                    complete: () => {
                        this.checked = [];
                        this.chargepointService.refresh();
                    },
                });
                return 0;
            })
            .catch(cancel => {
                this.logger.debug('Dialog', cancel);
            });
    }

    indexToField(index: string) {
        const fields = ['NAME', '', 'LAST_ACTIVITY', 'CREATED_AT', 'CONNECTION_STATUS'];
        return fields[+index - 1];
    }

    sort(sort: ChargePointSort) {
        let sortInput: ChargePointSort;
        if (sort.direction === SortOrder.Init) {
            sortInput = { field: 'NAME' };
        } else {
            sortInput = { field: this.indexToField(sort.field), direction: sort.direction } as ChargePointSort;
        }
        this.sortInput = sortInput;
        this.getChargepoints(this.pageSize, undefined);
    }

    ngOnDestroy(): void {
        this.timerSubscribtion.unsubscribe();
        this.breadcrumbService.chargepointCrumbs(0);
    }
}
