import { RouterModule, Routes } from '@angular/router';
import { CompanyListComponent } from './company-list/company-list.component';
import { DetailsCompanyComponent } from './company-list/details-company/details-company.component';
import { DetailsDriverComponent } from './driver-list/details-driver/details-driver.component';
import { DriverListComponent } from './driver-list/driver-list.component';
import { IdTokenListComponent } from './id-token-list/id-token-list.component';
import { MasterDataComponent } from './master-data.component';
import { NgModule } from '@angular/core';
import { VehicleListComponent } from './vehicle-list/vehicle-list.component';
import { DetailsVehicleComponent } from './vehicle-list/details-vehicle/details-vehicle.component';
import { DetailsIdTokenComponent } from './id-token-list/details-id-token/details-id-token.component';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'drivers',
    },
    {
        path: 'companies/:id',
        component: DetailsCompanyComponent,
    },
    {
        path: 'drivers/:id',
        component: DetailsDriverComponent,
    },
    {
        path: 'vehicles/:id',
        component: DetailsVehicleComponent,
    },
    {
        path: 'id-tokens/:id',
        component: DetailsIdTokenComponent,
    },
    {
        path: '',
        component: MasterDataComponent,
        children: [
            {
                path: 'drivers',
                component: DriverListComponent,
            },
            {
                path: 'vehicles',
                component: VehicleListComponent,
            },
            {
                path: 'companies',
                component: CompanyListComponent,
            },
            {
                path: 'id-tokens',
                component: IdTokenListComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class MasterDataRoutingModule { }
