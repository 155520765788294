import {KeycloakService} from 'keycloak-angular';
import {ConfigInitService} from "./config-init.service";
import {switchMap} from "rxjs";
import {fromPromise} from "rxjs/internal/observable/innerFrom";


export function initializeKeycloak(
    keycloak: KeycloakService,
    configService: ConfigInitService
) {
    return () =>
        configService.getConfig()
            .pipe(
                switchMap<any, any>((config) => {
                    return fromPromise(keycloak.init({
                        config: {
                            url: config['KEYCLOAK_URL'],
                            realm: config['KEYCLOAK_REALM'],
                            clientId: config['KEYCLOAK_CLIENT_ID'],
                        },
                        initOptions: {
                            onLoad: 'login-required',
                            checkLoginIframe: false,
                        },
                        authorizationHeaderName: 'Authorization',
                        bearerPrefix: 'Bearer ',
                        enableBearerInterceptor: true,
                    }))

                })
            )
}
