import { Component } from '@angular/core';
import { BreadcrumbService } from './breadcrumb.service';

@Component({
    selector: 'breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
    crumbs$ = this.breadcrumbService.crumbs$;

    constructor(public breadcrumbService: BreadcrumbService) {}
}
