<nav class="navbar">
  <img class="logo" src="assets/logo.svg" alt="" />
  <div class="nav-entries">
    <a class="nav-item" class="nav-entry" routerLink="/dashboard" routerLinkActive="link-active">
      <h5>{{ 'navigation.dashboard' | translate }}</h5>
    </a>
    <a class="nav-entry" routerLink="/chargepoints" routerLinkActive="link-active">
      <h5>{{ 'navigation.chargepoints' | translate }}</h5>
    </a>
    <a class="nav-entry" routerLink="/reporting" routerLinkActive="link-active">
      <h5>{{ 'navigation.reporting' | translate }}</h5>
    </a>
    <a class="nav-entry" routerLink="/master-data" routerLinkActive="link-active">
      <h5>{{ 'navigation.masterData' | translate }}</h5>
    </a>
    <a class="nav-entry" routerLink="/reservation" routerLinkActive="link-active">
      <h5>{{ 'navigation.reservation' | translate }}</h5>
    </a>
    <a class="nav-entry" routerLink="/accounting" routerLinkActive="link-active">
      <h5>{{ 'navigation.accounting' | translate }}</h5>
    </a>
  </div>

  <a ngbDropdown class="nav-entry">
    <button type="button" class="btn nav-entry" id="actionDropdown" ngbDropdownToggle>
      <h5>{{ 'navigation.profile' | translate }}</h5>
    </button>
    <div ngbDropdownMenu class="dropdown-menu profile-menu" aria-labelledby="actionDropdown">
      <button ngbDropdownItem class="profile submenu-link" routerLink="/profile" routerLinkActive="link-active">
        <h5>{{ 'navigation.profile' | translate }}</h5>
      </button>
      <button ngbDropdownItem class="submenu-link" (click)="logout()">
        <h5>{{ 'navigation.logout' | translate }}</h5>
      </button>
    </div>
  </a>
</nav>
