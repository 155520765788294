import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An RFC-3339 compliant Full Date Scalar */
  Date: any;
  /** A date-time with an offset from UTC/Greenwich in the ISO-8601 calendar system. */
  Instant: any;
  /** A 64-bit signed integer */
  Long: any;
  /** A universally unique identifier compliant UUID Scalar */
  UUID: any;
  _FieldSet: any;
}

export interface BaseEntity {
  id: Scalars['UUID'];
}

export interface ChargePoint extends VersionedEntity {
  __typename?: 'ChargePoint';
  client: Client;
  company?: Maybe<Company>;
  createdAt: Scalars['Instant'];
  id: Scalars['UUID'];
  name: Scalars['String'];
  owner?: Maybe<User>;
  version: Scalars['Long'];
}

export interface Client extends VersionedEntity {
  __typename?: 'Client';
  companies: Array<Company>;
  createdAt: Scalars['Instant'];
  id: Scalars['UUID'];
  idTokens: Array<IdToken>;
  name: Scalars['String'];
  users: Array<User>;
  version: Scalars['Long'];
}

export interface Company extends VersionedEntity {
  __typename?: 'Company';
  address: CompanyAddress;
  client: Client;
  createdAt: Scalars['Instant'];
  groups: Array<IdTokenGroup>;
  id: Scalars['UUID'];
  invoices: Page;
  name: Scalars['String'];
  users: Array<User>;
  version: Scalars['Long'];
}


export interface CompanyInvoicesArgs {
  month?: InputMaybe<Scalars['Int']>;
  orders?: InputMaybe<Array<SortInput>>;
  pageId?: Scalars['Int'];
  pageSize?: Scalars['Int'];
  year?: InputMaybe<Scalars['Int']>;
}

export interface CompanyAddress extends BaseEntity {
  __typename?: 'CompanyAddress';
  company?: Maybe<Company>;
  country: Scalars['String'];
  houseNumber: Scalars['String'];
  id: Scalars['UUID'];
  street: Scalars['String'];
  town: Scalars['String'];
  zipCode: Scalars['String'];
}

export interface CreateEnteredPositionInput {
  clientId: Scalars['UUID'];
  cost: Scalars['Float'];
  invoiceId?: InputMaybe<Scalars['UUID']>;
  location: Scalars['String'];
  timeInvoice: Scalars['Date'];
  userId: Scalars['UUID'];
}

export interface CreateInvoiceCommentInput {
  comment: Scalars['String'];
  invoiceId: Scalars['UUID'];
  userId: Scalars['UUID'];
}

export interface EnteredPosition extends VersionedEntity {
  __typename?: 'EnteredPosition';
  client: Client;
  cost: Scalars['Float'];
  createdAt: Scalars['Instant'];
  id: Scalars['UUID'];
  invoice: Invoice;
  location: Scalars['String'];
  released: Scalars['Boolean'];
  timeInvoice: Scalars['Date'];
  user?: Maybe<User>;
  version: Scalars['Long'];
}

export enum ErrorDetail {
  /**
   * The deadline expired before the operation could complete.
   *
   * For operations that change the state of the system, this error
   * may be returned even if the operation has completed successfully.
   * For example, a successful response from a server could have been
   * delayed long enough for the deadline to expire.
   *
   * HTTP Mapping: 504 Gateway Timeout
   * Error Type: UNAVAILABLE
   */
  DeadlineExceeded = 'DEADLINE_EXCEEDED',
  /**
   * The server detected that the client is exhibiting a behavior that
   * might be generating excessive load.
   *
   * HTTP Mapping: 429 Too Many Requests or 420 Enhance Your Calm
   * Error Type: UNAVAILABLE
   */
  EnhanceYourCalm = 'ENHANCE_YOUR_CALM',
  /**
   * The requested field is not found in the schema.
   *
   * This differs from `NOT_FOUND` in that `NOT_FOUND` should be used when a
   * query is valid, but is unable to return a result (if, for example, a
   * specific video id doesn't exist). `FIELD_NOT_FOUND` is intended to be
   * returned by the server to signify that the requested field is not known to exist.
   * This may be returned in lieu of failing the entire query.
   * See also `PERMISSION_DENIED` for cases where the
   * requested field is invalid only for the given user or class of users.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: BAD_REQUEST
   */
  FieldNotFound = 'FIELD_NOT_FOUND',
  /**
   * The client specified an invalid argument.
   *
   * Note that this differs from `FAILED_PRECONDITION`.
   * `INVALID_ARGUMENT` indicates arguments that are problematic
   * regardless of the state of the system (e.g., a malformed file name).
   *
   * HTTP Mapping: 400 Bad Request
   * Error Type: BAD_REQUEST
   */
  InvalidArgument = 'INVALID_ARGUMENT',
  /**
   * The provided cursor is not valid.
   *
   * The most common usage for this error is when a client is paginating
   * through a list that uses stateful cursors. In that case, the provided
   * cursor may be expired.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: NOT_FOUND
   */
  InvalidCursor = 'INVALID_CURSOR',
  /**
   * Unable to perform operation because a required resource is missing.
   *
   * Example: Client is attempting to refresh a list, but the specified
   * list is expired. This requires an action by the client to get a new list.
   *
   * If the user is simply trying GET a resource that is not found,
   * use the NOT_FOUND error type. FAILED_PRECONDITION.MISSING_RESOURCE
   * is to be used particularly when the user is performing an operation
   * that requires a particular resource to exist.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   * Error Type: FAILED_PRECONDITION
   */
  MissingResource = 'MISSING_RESOURCE',
  /**
   * Service Error.
   *
   * There is a problem with an upstream service.
   *
   * This may be returned if a gateway receives an unknown error from a service
   * or if a service is unreachable.
   * If a request times out which waiting on a response from a service,
   * `DEADLINE_EXCEEDED` may be returned instead.
   * If a service returns a more specific error Type, the specific error Type may
   * be returned instead.
   *
   * HTTP Mapping: 502 Bad Gateway
   * Error Type: UNAVAILABLE
   */
  ServiceError = 'SERVICE_ERROR',
  /**
   * Request failed due to network errors.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  TcpFailure = 'TCP_FAILURE',
  /**
   * Request throttled based on server concurrency limits.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  ThrottledConcurrency = 'THROTTLED_CONCURRENCY',
  /**
   * Request throttled based on server CPU limits
   *
   * HTTP Mapping: 503 Unavailable.
   * Error Type: UNAVAILABLE
   */
  ThrottledCpu = 'THROTTLED_CPU',
  /**
   * The operation is not implemented or is not currently supported/enabled.
   *
   * HTTP Mapping: 501 Not Implemented
   * Error Type: BAD_REQUEST
   */
  Unimplemented = 'UNIMPLEMENTED',
  /**
   * Unknown error.
   *
   * This error should only be returned when no other error detail applies.
   * If a client sees an unknown errorDetail, it will be interpreted as UNKNOWN.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  Unknown = 'UNKNOWN'
}

export enum ErrorType {
  /**
   * Bad Request.
   *
   * There is a problem with the request.
   * Retrying the same request is not likely to succeed.
   * An example would be a query or argument that cannot be deserialized.
   *
   * HTTP Mapping: 400 Bad Request
   */
  BadRequest = 'BAD_REQUEST',
  /**
   * The operation was rejected because the system is not in a state
   * required for the operation's execution.  For example, the directory
   * to be deleted is non-empty, an rmdir operation is applied to
   * a non-directory, etc.
   *
   * Service implementers can use the following guidelines to decide
   * between `FAILED_PRECONDITION` and `UNAVAILABLE`:
   *
   * - Use `UNAVAILABLE` if the client can retry just the failing call.
   * - Use `FAILED_PRECONDITION` if the client should not retry until
   * the system state has been explicitly fixed.  E.g., if an "rmdir"
   * fails because the directory is non-empty, `FAILED_PRECONDITION`
   * should be returned since the client should not retry unless
   * the files are deleted from the directory.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   */
  FailedPrecondition = 'FAILED_PRECONDITION',
  /**
   * Internal error.
   *
   * An unexpected internal error was encountered. This means that some
   * invariants expected by the underlying system have been broken.
   * This error code is reserved for serious errors.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  Internal = 'INTERNAL',
  /**
   * The requested entity was not found.
   *
   * This could apply to a resource that has never existed (e.g. bad resource id),
   * or a resource that no longer exists (e.g. cache expired.)
   *
   * Note to server developers: if a request is denied for an entire class
   * of users, such as gradual feature rollout or undocumented allowlist,
   * `NOT_FOUND` may be used. If a request is denied for some users within
   * a class of users, such as user-based access control, `PERMISSION_DENIED`
   * must be used.
   *
   * HTTP Mapping: 404 Not Found
   */
  NotFound = 'NOT_FOUND',
  /**
   * The caller does not have permission to execute the specified
   * operation.
   *
   * `PERMISSION_DENIED` must not be used for rejections
   * caused by exhausting some resource or quota.
   * `PERMISSION_DENIED` must not be used if the caller
   * cannot be identified (use `UNAUTHENTICATED`
   * instead for those errors).
   *
   * This error Type does not imply the
   * request is valid or the requested entity exists or satisfies
   * other pre-conditions.
   *
   * HTTP Mapping: 403 Forbidden
   */
  PermissionDenied = 'PERMISSION_DENIED',
  /**
   * The request does not have valid authentication credentials.
   *
   * This is intended to be returned only for routes that require
   * authentication.
   *
   * HTTP Mapping: 401 Unauthorized
   */
  Unauthenticated = 'UNAUTHENTICATED',
  /**
   * Currently Unavailable.
   *
   * The service is currently unavailable.  This is most likely a
   * transient condition, which can be corrected by retrying with
   * a backoff.
   *
   * HTTP Mapping: 503 Unavailable
   */
  Unavailable = 'UNAVAILABLE',
  /**
   * Unknown error.
   *
   * For example, this error may be returned when
   * an error code received from another address space belongs to
   * an error space that is not known in this address space.  Also
   * errors raised by APIs that do not return enough error information
   * may be converted to this error.
   *
   * If a client sees an unknown errorType, it will be interpreted as UNKNOWN.
   * Unknown errors MUST NOT trigger any special behavior. These MAY be treated
   * by an implementation as being equivalent to INTERNAL.
   *
   * When possible, a more specific error should be provided.
   *
   * HTTP Mapping: 520 Unknown Error
   */
  Unknown = 'UNKNOWN'
}

export interface IdToken extends VersionedEntity {
  __typename?: 'IdToken';
  client: Client;
  createdAt: Scalars['Instant'];
  group: IdTokenGroup;
  id: Scalars['UUID'];
  idTag: Scalars['String'];
  name: Scalars['String'];
  type: IdTokenType;
  version: Scalars['Long'];
}

export interface IdTokenGroup {
  __typename?: 'IdTokenGroup';
  client: Client;
  company?: Maybe<Company>;
  id: Scalars['UUID'];
  idTokens: Array<IdToken>;
  name: Scalars['String'];
  user?: Maybe<User>;
  version: Scalars['Long'];
}

export enum IdTokenStatus {
  Activated = 'ACTIVATED',
  Deactivated = 'DEACTIVATED'
}

export enum IdTokenType {
  Central = 'CENTRAL',
  EMaid = 'E_MAID',
  Iso14443 = 'ISO14443',
  Iso15693 = 'ISO15693',
  KeyCode = 'KEY_CODE',
  Local = 'LOCAL',
  MacAddress = 'MAC_ADDRESS',
  NoAuthorization = 'NO_AUTHORIZATION'
}

export interface Invoice extends VersionedEntity {
  __typename?: 'Invoice';
  comments: Page;
  company?: Maybe<Company>;
  createdAt: Scalars['Instant'];
  enteredPositions: Page;
  id: Scalars['UUID'];
  month: Scalars['Int'];
  recordedPositions: Page;
  user?: Maybe<User>;
  version: Scalars['Long'];
  year: Scalars['Int'];
}


export interface InvoiceCommentsArgs {
  orders?: Array<SortInput>;
  pageId?: Scalars['Int'];
  pageSize?: Scalars['Int'];
}


export interface InvoiceEnteredPositionsArgs {
  orders?: InputMaybe<Array<SortInput>>;
  pageId?: Scalars['Int'];
  pageSize?: Scalars['Int'];
}


export interface InvoiceRecordedPositionsArgs {
  orders?: InputMaybe<Array<SortInput>>;
  pageId?: Scalars['Int'];
  pageSize?: Scalars['Int'];
}

export interface InvoiceComment extends VersionedEntity {
  __typename?: 'InvoiceComment';
  comment: Scalars['String'];
  createdAt: Scalars['Instant'];
  id: Scalars['UUID'];
  invoice: Invoice;
  user: User;
  version: Scalars['Long'];
}

export interface Mutation {
  __typename?: 'Mutation';
  createEnteredPosition: EnteredPosition;
  createInvoiceComment: InvoiceComment;
  deleteEnteredPosition?: Maybe<Scalars['UUID']>;
  deleteInvoiceComment?: Maybe<Scalars['UUID']>;
  updateEnteredPosition: EnteredPosition;
  updateInvoiceComment: InvoiceComment;
}


export interface MutationCreateEnteredPositionArgs {
  data: CreateEnteredPositionInput;
}


export interface MutationCreateInvoiceCommentArgs {
  data: CreateInvoiceCommentInput;
}


export interface MutationDeleteEnteredPositionArgs {
  id: Scalars['UUID'];
}


export interface MutationDeleteInvoiceCommentArgs {
  commentId: Scalars['UUID'];
}


export interface MutationUpdateEnteredPositionArgs {
  data: UpdateEnteredPositionInput;
}


export interface MutationUpdateInvoiceCommentArgs {
  data: UpdateInvoiceCommentInput;
}

export interface Page {
  __typename?: 'Page';
  content: Array<Pageable>;
  totalElements: Scalars['Long'];
  totalPages: Scalars['Int'];
}

export type Pageable = Company | EnteredPosition | Invoice | InvoiceComment | RecordedPosition | User;

export interface Query {
  __typename?: 'Query';
  _service: Service;
  findCompany?: Maybe<Company>;
  findInvoice?: Maybe<Invoice>;
  findUser?: Maybe<User>;
  getCompanies: Page;
  getEnteredPosition?: Maybe<EnteredPosition>;
  getUsers: Page;
}


export interface QueryFindCompanyArgs {
  companyId: Scalars['UUID'];
}


export interface QueryFindInvoiceArgs {
  invoiceId: Scalars['UUID'];
}


export interface QueryFindUserArgs {
  userId: Scalars['UUID'];
}


export interface QueryGetCompaniesArgs {
  pageId?: Scalars['Int'];
  pageSize?: Scalars['Int'];
  sort?: Array<SortInput>;
}


export interface QueryGetEnteredPositionArgs {
  id: Scalars['UUID'];
}


export interface QueryGetUsersArgs {
  pageId?: Scalars['Int'];
  pageSize?: Scalars['Int'];
  sort?: Array<SortInput>;
}

export interface RecordedPosition extends BaseEntity {
  __typename?: 'RecordedPosition';
  chargePoint: ChargePoint;
  id: Scalars['UUID'];
  invoice: Invoice;
  timeStart: Scalars['Instant'];
  timeStop: Scalars['Instant'];
  transferredEnergy: Scalars['Float'];
}

export interface SortInput {
  field: Scalars['String'];
  order?: SortOrder;
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export interface UpdateEnteredPositionInput {
  clientId: Scalars['UUID'];
  cost: Scalars['Float'];
  id: Scalars['UUID'];
  invoiceId?: InputMaybe<Scalars['UUID']>;
  location: Scalars['String'];
  released: Scalars['Boolean'];
  timeInvoice: Scalars['Date'];
  userId: Scalars['UUID'];
}

export interface UpdateInvoiceCommentInput {
  comment: Scalars['String'];
  commentId: Scalars['UUID'];
}

export interface User extends VersionedEntity {
  __typename?: 'User';
  address?: Maybe<UserAddress>;
  client: Client;
  comments: Page;
  company: Company;
  createdAt: Scalars['Instant'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  groups: Array<IdTokenGroup>;
  id: Scalars['UUID'];
  invoices: Page;
  lastName: Scalars['String'];
  version: Scalars['Long'];
}


export interface UserCommentsArgs {
  orders?: Array<SortInput>;
  pageId?: Scalars['Int'];
  pageSize?: Scalars['Int'];
}


export interface UserInvoicesArgs {
  month?: InputMaybe<Scalars['Int']>;
  pageId?: Scalars['Int'];
  pageSize?: Scalars['Int'];
  sort?: Array<SortInput>;
  year?: InputMaybe<Scalars['Int']>;
}

export interface UserAddress extends BaseEntity {
  __typename?: 'UserAddress';
  country: Scalars['String'];
  houseNumber: Scalars['String'];
  id: Scalars['UUID'];
  street: Scalars['String'];
  town: Scalars['String'];
  user?: Maybe<User>;
  zipCode: Scalars['String'];
}

export interface VersionedEntity {
  createdAt: Scalars['Instant'];
  version: Scalars['Long'];
}

export interface Service {
  __typename?: '_Service';
  sdl: Scalars['String'];
}

export type GetAllUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllUsersQuery = (
  { __typename?: 'Query' }
  & { getUsers: (
    { __typename?: 'Page' }
    & { content: Array<{ __typename?: 'Company' } | { __typename?: 'EnteredPosition' } | { __typename?: 'Invoice' } | { __typename?: 'InvoiceComment' } | { __typename?: 'RecordedPosition' } | (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
      & { address?: Maybe<(
        { __typename?: 'UserAddress' }
        & Pick<UserAddress, 'town' | 'houseNumber' | 'street' | 'zipCode' | 'country'>
      )> }
    )> }
  ) }
);

export type GetInvoicesByUserIdQueryVariables = Exact<{
  userId: Scalars['UUID'];
}>;


export type GetInvoicesByUserIdQuery = (
  { __typename?: 'Query' }
  & { findUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName'>
    & { invoices: (
      { __typename?: 'Page' }
      & { content: Array<{ __typename?: 'Company' } | { __typename?: 'EnteredPosition' } | (
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'id' | 'year' | 'month'>
      ) | { __typename?: 'InvoiceComment' } | { __typename?: 'RecordedPosition' } | { __typename?: 'User' }> }
    ) }
  )> }
);

export type GetInvoicesByCompanyIdQueryVariables = Exact<{
  companyId: Scalars['UUID'];
}>;


export type GetInvoicesByCompanyIdQuery = (
  { __typename?: 'Query' }
  & { findCompany?: Maybe<(
    { __typename?: 'Company' }
    & { invoices: (
      { __typename?: 'Page' }
      & { content: Array<{ __typename?: 'Company' } | { __typename?: 'EnteredPosition' } | (
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'id' | 'year' | 'month'>
      ) | { __typename?: 'InvoiceComment' } | { __typename?: 'RecordedPosition' } | { __typename?: 'User' }> }
    ) }
  )> }
);

export type GetInvoiceByIdQueryVariables = Exact<{
  invoiceId: Scalars['UUID'];
  pageId: Scalars['Int'];
  pageSize: Scalars['Int'];
}>;


export type GetInvoiceByIdQuery = (
  { __typename?: 'Query' }
  & { findInvoice?: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'year' | 'month'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
      & { address?: Maybe<(
        { __typename?: 'UserAddress' }
        & Pick<UserAddress, 'town' | 'street' | 'houseNumber' | 'zipCode' | 'country'>
      )>, company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { address: (
          { __typename?: 'CompanyAddress' }
          & Pick<CompanyAddress, 'town' | 'street' | 'houseNumber' | 'zipCode' | 'country'>
        ) }
      ) }
    )>, company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
      & { address: (
        { __typename?: 'CompanyAddress' }
        & Pick<CompanyAddress, 'town' | 'street' | 'houseNumber' | 'zipCode' | 'country'>
      ) }
    )>, recordedPositions: (
      { __typename?: 'Page' }
      & { content: Array<{ __typename?: 'Company' } | { __typename?: 'EnteredPosition' } | { __typename?: 'Invoice' } | { __typename?: 'InvoiceComment' } | (
        { __typename?: 'RecordedPosition' }
        & Pick<RecordedPosition, 'id' | 'timeStop' | 'timeStart' | 'transferredEnergy'>
        & { chargePoint: (
          { __typename?: 'ChargePoint' }
          & Pick<ChargePoint, 'id' | 'name'>
        ) }
      ) | { __typename?: 'User' }> }
    ) }
  )> }
);

export type GetInvoiceTransactionsQueryVariables = Exact<{
  invoiceId: Scalars['UUID'];
  pageSize: Scalars['Int'];
  pageId: Scalars['Int'];
}>;


export type GetInvoiceTransactionsQuery = (
  { __typename?: 'Query' }
  & { findInvoice?: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id'>
    & { recordedPositions: (
      { __typename?: 'Page' }
      & Pick<Page, 'totalPages' | 'totalElements'>
      & { content: Array<{ __typename?: 'Company' } | { __typename?: 'EnteredPosition' } | { __typename?: 'Invoice' } | { __typename?: 'InvoiceComment' } | (
        { __typename?: 'RecordedPosition' }
        & Pick<RecordedPosition, 'id' | 'timeStop' | 'timeStart' | 'transferredEnergy'>
        & { chargePoint: (
          { __typename?: 'ChargePoint' }
          & Pick<ChargePoint, 'name'>
        ) }
      ) | { __typename?: 'User' }> }
    ) }
  )> }
);

export const GetAllUsersDocument = gql`
    query GetAllUsers {
  getUsers {
    content {
      ... on User {
        id
        firstName
        lastName
        address {
          town
          houseNumber
          street
          zipCode
          country
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllUsersGQL extends Apollo.Query<GetAllUsersQuery, GetAllUsersQueryVariables> {
    document = GetAllUsersDocument;
    client = 'accountingQlClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetInvoicesByUserIdDocument = gql`
    query GetInvoicesByUserId($userId: UUID!) {
  findUser(userId: $userId) {
    firstName
    lastName
    invoices {
      content {
        ... on Invoice {
          id
          year
          month
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetInvoicesByUserIdGQL extends Apollo.Query<GetInvoicesByUserIdQuery, GetInvoicesByUserIdQueryVariables> {
    document = GetInvoicesByUserIdDocument;
    client = 'accountingQlClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetInvoicesByCompanyIdDocument = gql`
    query GetInvoicesByCompanyId($companyId: UUID!) {
  findCompany(companyId: $companyId) {
    invoices {
      content {
        ... on Invoice {
          id
          year
          month
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetInvoicesByCompanyIdGQL extends Apollo.Query<GetInvoicesByCompanyIdQuery, GetInvoicesByCompanyIdQueryVariables> {
    document = GetInvoicesByCompanyIdDocument;
    client = 'accountingQlClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetInvoiceByIdDocument = gql`
    query GetInvoiceById($invoiceId: UUID!, $pageId: Int!, $pageSize: Int!) {
  findInvoice(invoiceId: $invoiceId) {
    year
    month
    user {
      id
      firstName
      lastName
      address {
        town
        street
        houseNumber
        zipCode
        town
        country
      }
      company {
        id
        address {
          town
          street
          houseNumber
          zipCode
          town
          country
        }
      }
    }
    company {
      id
      name
      address {
        town
        street
        houseNumber
        zipCode
        town
        country
      }
    }
    recordedPositions(pageId: $pageId, pageSize: $pageSize) {
      content {
        ... on RecordedPosition {
          id
          timeStop
          timeStart
          transferredEnergy
          chargePoint {
            id
            name
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetInvoiceByIdGQL extends Apollo.Query<GetInvoiceByIdQuery, GetInvoiceByIdQueryVariables> {
    document = GetInvoiceByIdDocument;
    client = 'accountingQlClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetInvoiceTransactionsDocument = gql`
    query GetInvoiceTransactions($invoiceId: UUID!, $pageSize: Int!, $pageId: Int!) {
  findInvoice(invoiceId: $invoiceId) {
    id
    recordedPositions(pageId: $pageId, pageSize: $pageSize) {
      totalPages
      totalElements
      content {
        ... on RecordedPosition {
          id
          timeStop
          timeStart
          transferredEnergy
          chargePoint {
            name
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetInvoiceTransactionsGQL extends Apollo.Query<GetInvoiceTransactionsQuery, GetInvoiceTransactionsQueryVariables> {
    document = GetInvoiceTransactionsDocument;
    client = 'accountingQlClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }