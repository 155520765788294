<app-overview-card></app-overview-card>

<div class="dashboard">

  <!-- Most Wanted Charge Point -->
  <div class="card dashboard-element most-wanted">
    <div class="card-header">
      <h4>{{ 'dashboard.backoffice.mostWantedChargepoint' | translate }}</h4>
    </div>
    <div class="card-body">
      <h6>{{ 'general.chargePoint' | translate }}</h6>
      <p class="text">LS-KA-00</p>

      <h6>{{ 'general.location' | translate }}</h6>
      <p class="text">Karlsruhe</p>

      <h6>{{ 'general.plugType' | translate }}</h6>
      <p class="text">Typ 2 Steckdose</p>

      <h6>{{ 'general.chargingPower' | translate }}</h6>
      <p class="text">150 kW (1000 Volt, 500 Ampere)</p>

      <h6>{{ 'dashboard.backoffice.chargingProcessesThisMonth' | translate }}</h6>
      <p class="text">25</p>

      <h6>{{ 'general.state' | translate }}</h6>
      <p class="text">Frei</p>
    </div>
  </div>

  <!-- New Registrations -->
  <div class="card dashboard-element new-registrations">
    <div class="card-header">
      <h4>{{ 'dashboard.backoffice.newRegistrations' | translate }}</h4>
    </div>
    <div class="card-body">
      <div class="input-elements">
        <div class="form-group">
          <label for="location">{{ 'general.location' | translate }}</label>
          <select class="form-control" id="location">
            <option value="Karlsruhe">Karlsruhe</option>
            <option value="Stuttgart">Stuttgart</option>
            <option value="Mannheim">Mannheim</option>
          </select>
        </div>
        <div class="form-group">
          <label for="year">{{ 'general.year' | translate }}</label>
          <select class="form-control" id="year">
            <option value="2022">2022</option>
            <option value="2021">2021</option>
            <option value="2020">2020</option>
          </select>
        </div>
      </div>
      <img class="img-fluid" src="assets/example-graph1.png" alt=""/>
    </div>
  </div>

  <!-- Charging Frequency -->
  <div class="card dashboard-element charging-frequency">
    <div class="card-header">
      <h4>{{ 'dashboard.backoffice.chargingFrequencyPerLocation' | translate }}</h4>
    </div>
    <div class="card-body">
      <div class="input-elements">
        <div class="form-group">
          <label for="location">{{ 'general.location' | translate }}</label>
          <select class="form-control" id="location">
            <option value="Karlsruhe">Karlsruhe</option>
            <option value="Stuttgart">Stuttgart</option>
            <option value="Mannheim">Mannheim</option>
          </select>
        </div>
        <div class="form-group">
          <label for="year">{{ 'general.year' | translate }}</label>
          <select class="form-control" id="year">
            <option value="2022">2022</option>
            <option value="2021">2021</option>
            <option value="2020">2020</option>
          </select>
        </div>
      </div>
      <img class="img-fluid" src="assets/example-graph2.png" alt=""/>
    </div>
  </div>

  <!-- Most Reserved -->
  <div class="card dashboard-element most-reserved">
    <div class="card-header">
      <h4>{{ 'dashboard.backoffice.mostReserved' | translate }}</h4>
    </div>
    <div class="card-body">
      <div class="input-elements">
        <div class="form-group">
          <label for="location">{{ 'general.location' | translate }}</label>
          <select class="form-control" id="location">
            <option value="Karlsruhe">Karlsruhe</option>
            <option value="Stuttgart">Stuttgart</option>
            <option value="Mannheim">Mannheim</option>
          </select>
        </div>
        <div class="form-group">
          <label for="month">{{ 'general.month' | translate }}</label>
          <select class="form-control" id="month">
            <option>January</option>
            <option>February</option>
            <!-- Add more months as needed -->
          </select>
        </div>
      </div>
      <img class="img-fluid illu-wallbox" src="assets/illu-wallboxes.svg" alt=""/>
      <ol class="ranking">
        <li><span class="text">LS-KA-09</span></li>
        <li><span class="text">LS-S-01</span></li>
        <li><span class="text">LS-KA-01</span></li>
        <li><span class="text">LS-F-02</span></li>
        <li><span class="text">LS-S-03</span></li>
      </ol>
    </div>
  </div>

  <!-- Technical Malfunctions -->
  <div class="card dashboard-element tech-malfunctions">
    <div class="card-header">
      <h4>{{ 'dashboard.backoffice.technicalMalfunctions' | translate }}</h4>
    </div>
    <div class="card-body">
      <h1>1</h1>
      <p class="date">{{ dateTime | localizedDate:'MMMM YYYY' }}</p>
    </div>
  </div>
</div>

