<div class="table-responsive">
  <table class="table">
    <thead>
    <tr>
      <th scope="col"><a>{{ 'reporting.driver' | translate }}</a></th>
      <th scope="col"><a>{{ 'reporting.location' | translate }}</a></th>
      <th scope="col"><a>{{ 'reporting.time' | translate }}</a></th>
      <th scope="col"><a>{{ 'reporting.cost' | translate }}</a></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let transaction of transactionsDisplayed(); let index = index;" class="cursor-pointer">
      <td>{{ transaction.lastName }}, {{ transaction.firstName }}</td>
      <td>{{ transaction.location }}</td>
      <td>{{ transaction.time | localizedDate: 'date-time-short' }}</td>
      <td>{{ transaction.cost | currency:'EUR':'symbol':'1.2-2' }}</td>
    </tr>
    </tbody>
  </table>
</div>

<div *ngIf="pages.pageAmount > 1" class="d-flex flex-row justify-content-end align-items-center">
  <div>
    <div class="d-flex flex-row align-items-center">
      <span class="chevron rotate--n90 cursor-pointer" (click)="previous()"></span>
      <span class="mx-5 user-select-none">{{ pages.pageId + 1 }}/{{ pages.pageAmount }}</span>
      <span class="chevron rotate--90 cursor-pointer" (click)="next()"></span>
    </div>
  </div>
</div>
