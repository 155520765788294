import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'duration'
})
export class DurationPipe implements PipeTransform {
    transform(decimalHours: number | null | undefined): string {
        if (decimalHours === undefined || decimalHours === null) {
            return '0:00';
        }
        const hours = Math.floor(decimalHours);
        const minutes = Math.round((decimalHours - hours) * 60);
        return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
    }
}
