import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { KeycloakService } from 'keycloak-angular';

/**
 * Component for the header navigation bar with the tag <code>app-headernav</code>.
 */
@Component({
    selector: 'app-headernav',
    templateUrl: './headernav.component.html',
    styleUrls: ['./headernav.component.scss'],
})
export class HeadernavComponent {
    /**
     * Access to {@link AppComponent.isHandset$}.
     */
    @Input() isHandset$!: Observable<boolean>;

    /**
     * Creates an instance of HeadernavComponent and sets the language that is displayed in the header navigation bar.
     * If the language is not supported, English is displayed by default.
     */
    constructor(
        private readonly logger: NGXLogger,
        private readonly keycloakService: KeycloakService,
    ) {
        logger.debug('HeadernavComponent.constructor()');
    }

    logout(): void {
        this.logger.debug('logout()');
        this.keycloakService.logout();
    }
}
