import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreadcrumbService } from 'src/app/layout/breadcrumb/breadcrumb.service';

@Component({
    selector: 'app-compatible-wallboxes',
    templateUrl: './compatible-wallboxes.component.html',
    styleUrls: ['./compatible-wallboxes.component.scss'],
})
export class CompatibleWallboxesComponent implements OnInit, OnDestroy {
    constructor(private readonly breadcrumbService: BreadcrumbService) {}

    ngOnInit() {
        this.breadcrumbService.compatibleWallboxesCrumbs(1);
    }
    ngOnDestroy() {
        this.breadcrumbService.compatibleWallboxesCrumbs(0);
    }
}
