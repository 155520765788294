import {ManualTransaction, TrackedTransaction} from "../graphql/generated/reporting_graphql_types";
import {DeepPartial} from "../../shared/typescript/deep-partial";

export type CombinedTransaction = Partial<{
    cost: number;
    timeEnd: Date;
    duration: number;
    transferredEnergy: number;
}> & {
    type: 'manual' | 'tracked';
    transaction: DeepPartial<TrackedTransaction | ManualTransaction>;
    timeStart: Date;
    firstName: string;
    lastName: string;
    chargePoint: string;
}

export const buildCombinedTransaction = (transaction: DeepPartial<TrackedTransaction | ManualTransaction>): CombinedTransaction => {
    if ("idToken" in transaction) {
        return {
            type: 'tracked',
            transaction: transaction,
            firstName: transaction?.idToken?.user?.firstName ?? '-',
            lastName: transaction?.idToken?.user?.lastName ?? '-',
            chargePoint: transaction?.chargePoint?.name ?? '-',
            timeStart: new Date(transaction.startDate ?? 0),
            timeStop: new Date(transaction.endDate ?? 0),
            duration: 0,
            transferredEnergy: 0,
        } as CombinedTransaction;
    }
    if ("user" in transaction) {
        return {
            type: 'manual',
            transaction: transaction,
            firstName: transaction?.user?.firstName,
            lastName: transaction?.user?.lastName,
            chargePoint: transaction?.location,
            timeStart: new Date(transaction?.date ?? 0),
            cost: transaction?.cost ?? 0,
        } as CombinedTransaction;
    }
    throw new Error("Could not build CombinedTransaction Model");
}
