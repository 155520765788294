import { Component } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

/**
 * Component for the tab bar of the master data with the tag <code>app-master-data</code>.
 */
@Component({
    selector: 'app-master-data',
    templateUrl: './master-data.component.html',
    styleUrls: ['./master-data.component.scss'],
})
export class MasterDataComponent {
    /**
     * Creates an instance of MasterDataComponent.
     * @param logger Service for logging.
     */
    constructor(private readonly logger: NGXLogger) {
        logger.debug('MasterDataComponent.constructor()');
    }
}
