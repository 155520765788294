import { Injectable } from '@angular/core';
import { QueryRef } from 'apollo-angular';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    AllIdTokensGQL,
    AllIdTokensQuery,
    CreateIdTokenGQL,
    CreateIdTokenInput,
    DeleteIdTokensGQL,
    IdToken,
    OneIdTokensGQL,
    OneIdTokensQuery,
    UpdateIdTokenGQL,
    UpdateIdTokenInput,
} from '../graphql/generated/mdm_graphql_types';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class IdTokenService {
    idTokenQueryRef: QueryRef<AllIdTokensQuery, {}> | undefined;

    constructor(
        private logger: NGXLogger,
        private allIdTokensGql: AllIdTokensGQL,
        private oneIdTokenQgl: OneIdTokensGQL,
        private delIdTokenGql: DeleteIdTokensGQL,
        private createIdTokenGQL: CreateIdTokenGQL,
        private updateIdTokenGQL: UpdateIdTokenGQL,
        private readonly translateService: TranslateService,
    ) { }

    flattenIdTokens(idTokens: IdToken[]) {
        const { currentLang } = this.translateService;
        const ngPipe = new DatePipe(currentLang);

        let flatIdTokenArray = [];
        let permissionArray = [];
        let ownerName;
        let status;
        for (let i = 0; i < idTokens.length; i++) {
            if (idTokens[i].group.user) {
                ownerName = idTokens[i].group.user?.lastName + ', ' + idTokens[i].group.user?.firstName;
            }
            if (idTokens[i].active) {
                status = this.translateService.instant('masterData.active');
            } else {
                status = this.translateService.instant('masterData.notActive');
            }
            flatIdTokenArray.push([
                idTokens[i].id,
                idTokens[i].name,
                ownerName,
                idTokens[i].group.company?.name || '',
                idTokens[i].type,
                idTokens[i].idTag,
                idTokens[i].group.name,
                status,
                ngPipe.transform(idTokens[i].validAt, 'mediumDate'),
                ngPipe.transform(idTokens[i].validUntil, 'mediumDate'),
            ]);
            permissionArray.push(1);
        }
        return [flatIdTokenArray, permissionArray];
    }

    getIdTokens(): Observable<AllIdTokensQuery['idTokens']> {
        this.idTokenQueryRef = this.allIdTokensGql.watch();
        return this.idTokenQueryRef.valueChanges.pipe(map(result => result.data.idTokens));
    }

    getIdToken(idTokenId: string): Observable<OneIdTokensQuery['idToken']> {
        return this.oneIdTokenQgl.watch({ idTokenId: idTokenId }).valueChanges.pipe(map(result => result.data.idToken));
    }

    refresh() {
        this.logger.debug('call refresh for reloading data');
        this.idTokenQueryRef?.refetch();
    }

    deleteIdToken(idTokens: string[]): Observable<any> {
        this.logger.debug('call service delete idTokens with ids:', idTokens);
        return this.delIdTokenGql.mutate({ idTokenId: idTokens });
    }

    createIdToken(idToken: CreateIdTokenInput): Observable<any> {
        this.logger.debug('call service create idToken with tokenName', idToken);
        return this.createIdTokenGQL.mutate({ createIdToken: idToken });
    }

    updateIdToken(idToken: UpdateIdTokenInput): Observable<any> {
        this.logger.debug('call service update idToken with token-ID', idToken);
        return this.updateIdTokenGQL.mutate({ updateIdToken: idToken });
    }
}
