import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserDataService } from '../../user-data.service';
import { NGXLogger } from 'ngx-logger';
import { Vehicle } from '../graphql/generated/mdm_graphql_types';
import { VehicleService } from './vehicle-list.service';
import { ErrorHandler } from '../shared/md-error-handler.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditVehicleDialogComponent } from './edit-vehicle-dialog/edit-vehicle-dialog.component';
import { BreadcrumbService } from 'src/app/layout/breadcrumb/breadcrumb.service';
import { DeleteDialogComponent, DeleteDialogData } from 'src/app/shared/delete-dialog/delete-dialog.component';

/**
 * Component for the tag <code>app-vehicle-list</code> to display
 * the table of vehicles.
 */
@Component({
    selector: 'app-vehicle-list',
    templateUrl: './vehicle-list.component.html',
    styleUrls: ['./vehicle-list.component.scss'],
})
export class VehicleListComponent implements OnInit, OnDestroy {
    vehicles: Vehicle[] = [];
    flatVehicles: any = [];
    colNames: string[] = [
        'vehicleDialog.owner',
        'vehicleDialog.licensePlate',
        'vehicleDialog.vendor',
        'vehicleDialog.model',
        'vehicleDialog.registered',
    ];
    addDialog = 'vehicles.add';
    loading = false;
    checked: Array<string> = [];

    constructor(
        public readonly vehicleService: VehicleService,
        private readonly logger: NGXLogger,
        private readonly userDataService: UserDataService,
        public readonly errorHandler: ErrorHandler,
        private modalService: NgbModal,
        private breadcrumbService: BreadcrumbService,
    ) {
        logger.debug('VehicleListComponent.constructor()');
    }
    ngOnInit() {
        this.breadcrumbService.masterDataCrumbs(2, 'vehicles');
        this.getVehicles();
    }

    updateChecked(checked: string[]) {
        this.checked = checked;
    }

    getVehicles() {
        this.logger.debug('VehicleListComponent.getVehicles()');
        this.loading = true;
        this.vehicleService.getVehicles().subscribe((result: any) => {
            this.vehicles = result;
            this.flatVehicles = this.vehicleService.flattenVehicles(this.vehicles);
            this.logger.debug('Vehicles loaded: ', this.vehicles.length);
            this.loading = false;
        });
    }

    openDialogAdd() {
        this.logger.debug('VehicleListComponent.openDialogAdd()');
        this.modalService.open(EditVehicleDialogComponent);
    }

    openDialogEdit(vehicleId: string) {
        this.logger.debug('VehicleListComponent.openDialogEdit()');
        let editDialogRef = this.modalService.open(EditVehicleDialogComponent);
        editDialogRef.componentInstance.vehicleId = vehicleId;
    }

    delete(vehicleIds: string[]) {
        let vehicles = this.vehicles.filter(vehicle => vehicleIds.includes(vehicle.id));
        let data = {} as DeleteDialogData;
        this.logger.debug('Delete Vehicles:', vehicles);
        data.items = vehicles.map(vehicles => vehicles.licensePlate);

        if (vehicles.length === 1) {
            data.title = 'vehicleDialog.titleDeleteOneVehicle';
            data.question = 'vehicleDialog.questionDeleteSingle';
        } else {
            data.title = 'vehicleDialog.titleDeleteMultipleVehicle';
            data.question = 'vehicleDialog.questionDeleteMulti';
        }

        const dialogRef = this.modalService.open(DeleteDialogComponent);
        dialogRef.componentInstance.data = data;
        dialogRef.result
            .then(() => {
                this.vehicleService.deleteVehicle(vehicleIds).subscribe({
                    next: delResponse => this.logger.debug('Result of delete: ', delResponse),
                    error: (err: unknown) => this.logger.debug('An Error occured: ', err),
                    complete: () => {
                        this.checked = [];
                        this.vehicleService.refresh();
                    },
                });
                return 0;
            })
            .catch(cancel => {
                this.logger.debug('Dialog', cancel);
            });
    }

    ngOnDestroy(): void {
        this.breadcrumbService.masterDataCrumbs(0);
    }
}
