<div class="row flex-wrap justify-content-start align-items-center g-4">
  <app-accounting-loading-spinner *ngIf="loadingUsers else showAfterLoading"
                                  [loadingMessage]="'accounting.loading.users' | translate"></app-accounting-loading-spinner>
</div>

<ng-template #showAfterLoading>
  <h5 class="text-danger" *ngIf="users.length === 0 else showUsers">{{ 'accounting.noUsers' | translate }}</h5>
</ng-template>

<ng-template #showUsers>
  <div *ngFor="let user of users" class="col-12 col-sm-6 col-lg-4 col-xl-3">
    <app-accounting-user [user]="user"></app-accounting-user>
  </div>
</ng-template>
