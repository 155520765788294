import { Component } from '@angular/core';


/**
 * Component for the history of charging operations with the tag <code>app-charging-operation-list</code>.
 */
@Component({
    selector: 'charging-operation-detailview',
    templateUrl: './charging-operation-detailview.component.html',
    styleUrls: ['./charging-operation-detailview.component.scss'],
})
export class ChargingOperationDetailviewComponent {}
