import {APP_INITIALIZER, Injector, NgModule} from '@angular/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {ApolloModule} from 'apollo-angular';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {ChargingOperationsModule} from './charging-operations/charging-operations.module';
import {ChargepointsModule} from './chargepoints/chargepoints.module';
import {DashboardModule} from './dashboard/dashboard.module';
import {HeadernavComponent} from './layout/headernav/headernav.component';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {LayoutModule} from '@angular/cdk/layout';
import {MasterDataModule} from './master-data/master-data.module';
import {ProfileModule} from './profile/profile.module';
import {ReservationModule} from './reservation/reservation.module';
import {RouterModule} from '@angular/router';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {environment} from '../environments/environment';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {DatePipe, NgOptimizedImage, registerLocaleData} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {DateFormatter} from './shared/date-formatter';
import {FooterComponent} from './layout/footer/footer.component';
import {GraphQLModule} from './graphql.module';
import {AccountingModule} from './accounting/accounting.module';
import {BreadcrumbModule} from "./layout/breadcrumb/breadcrumb.module";
import {setInjector} from "./shared/composable/injector.composable";
import {initializeKeycloak} from "./init/keycloak-init.factory";
import {ConfigInitService} from "./init/config-init.service";
import {ReportingModule} from "./reporting/reporting.module";

// Registration for languages other than English to be supported
registerLocaleData(localeDe, 'de', localeDeExtra);

/**
 * Factory function for the TranslateHttpLoader to find the translation files.
 * @param httpClient Client with which the data will be found.
 */
// AoT requires an exported function for factories
export const createTranslateLoader = (httpClient: HttpClient) =>
    new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');

@NgModule({
    exports: [ApolloModule],
    declarations: [
        AppComponent,
        HeadernavComponent,
        FooterComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        ChargingOperationsModule,
        ChargepointsModule,
        DashboardModule,
        HttpClientModule,
        KeycloakAngularModule,
        LayoutModule,
        TranslateModule.forRoot({
            defaultLanguage: 'de',
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        ProfileModule,
        ReactiveFormsModule,
        LoggerModule.forRoot({
            level: environment.production
                ? NgxLoggerLevel.OFF
                : NgxLoggerLevel.DEBUG,
            timestampFormat: 'HH:mm:ss.SSS',
            enableSourceMaps: true,
        }),
        ReservationModule,
        RouterModule,
        NgbModule,
        GraphQLModule,
        MasterDataModule,
        AccountingModule,
        ReportingModule,
        NgOptimizedImage,
        BreadcrumbModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService, ConfigInitService],
        },
        {
            provide: NgbDateParserFormatter,
            useClass: DateFormatter,
        },
        DatePipe,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(private readonly injector: Injector) {
        setInjector(injector);
    }
}
