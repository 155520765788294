import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { ChargePoint } from '../../../graphql/generated/cp-graphql_types';
import { EditChargepointDialogComponent } from '../../edit-chargepoint-dialog/edit-chargepoint-dialog.component';
import { ChargepointService } from '../../chargepoint-list.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbService } from 'src/app/layout/breadcrumb/breadcrumb.service';

export interface TransactionHistory {
    driver: string;
    start: string;
    duration: string;
    end: string;
    chargedEnergy: string;
}

@Component({
    selector: 'app-chargepoint-details',
    templateUrl: './chargepoint-details.component.html',
    styleUrls: ['./chargepoint-details.component.scss'],
})
export class ChargepointDetailsComponent implements OnInit, OnDestroy {
    chargepointDetails = {} as ChargePoint;
    transactionHistories: TransactionHistory[] = [];
    initialTransactionHistories: TransactionHistory[] = [];
    lastTransaction: string = '';

    constructor(
        readonly chargepointService: ChargepointService,
        private readonly route: ActivatedRoute,
        private readonly logger: NGXLogger,
        private modalService: NgbModal,
        private breadcrumbService: BreadcrumbService,
        private activatedRoute: ActivatedRoute,
    ) {
        logger.debug('ChargepointDetailsComponent.constructor()');
    }

    ngOnInit() {
        const id: string = this.route.snapshot.paramMap.get('id') ?? '';
        this.activatedRoute.data.subscribe(({ chargepoint }) => {
            this.chargepointDetails = chargepoint;
            this.breadcrumbService.chargepointCrumbs(2, chargepoint.name, id);
        });
    }


    openDialogEdit(chargepointId: string) {
        this.logger.debug('Chargepoint-ListComponent.openDialogEdit()');
        let editDialogRef = this.modalService.open(EditChargepointDialogComponent);
        editDialogRef.componentInstance.chargepointId = chargepointId;
        this.chargepointService.refresh();
    }

    ngOnDestroy(): void {
        this.breadcrumbService.chargepointCrumbs(0);
    }
}
