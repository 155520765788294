<nav ngbNav #nav="ngbNav" class="nav-tabs justify-content-center">
  <ng-container ngbNavItem>
    <li class="nav-item">
      <a
        class="nav-link btn-link"
        routerLink="drivers"
        [routerLinkActive]="['active']"
      >
        {{ 'masterData.drivers' | translate }}
      </a>
    </li>
    <li class="nav-item">
      <a
        class="nav-link btn-link"
        routerLink="vehicles"
        [routerLinkActive]="['active']"
      >
        {{ 'masterData.vehicles' | translate }}
      </a>
    </li>
    <li class="nav-item">
      <a
        class="nav-link btn-link"
        routerLink="companies"
        [routerLinkActive]="['active']"
      >
        {{ 'masterData.companies' | translate }}
      </a>
    </li>
    <li class="nav-item">
      <a
        class="nav-link btn-link"
        routerLink="id-tokens"
        [routerLinkActive]="['active']"
      >
        {{ 'masterData.idTokens' | translate }}
      </a>
    </li>
  </ng-container>
</nav>

<router-outlet></router-outlet>
