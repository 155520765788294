<div class="container">
  <h4 class="dialog-title">
    {{ data.title | translate }}
  </h4>
  <div class="dialog-content">
    <div *ngIf="data.items.length > 1; then multiContent; else singleContent"></div>
    <ng-template #multiContent>
      <p>{{ data.question | translate: {value: data.items.length } }}</p>
      <ng-container *ngIf="data.items.length <= 5">
        <ul>
          <div *ngFor="let entry of data.items">
            <li class="text">{{ entry }}</li>
          </div>
        </ul>
      </ng-container>
    </ng-template>
    <ng-template #singleContent>{{ data.question | translate: {value: data.items} }}</ng-template>
  </div>
  <div class="dialog-button-bar">
    <button class="bs-secondary-button text-nowrap btn" (click)="activeModal.dismiss('cancel')">
      {{ 'deleteDialog.cancel' | translate }}
    </button>
    <button class="bs-primary-button btn-block ml-1 btn" (click)="activeModal.close('delete')">
      {{ 'deleteDialog.delete' | translate }}
    </button>
  </div>
</div>
