import {Component, Input} from '@angular/core';
import {User} from "../graphql/generated/accounting_graphql_types";
import {Router} from "@angular/router";

@Component({
    selector: 'app-accounting-user',
    template: `
        <div class="card accounting-card user-select-none">
            <div
                    class="card-body accounting-card-body d-flex flex-column justify-content-center align-items-center text-center">
                <h1>{{ 'accounting.userCardTitle' | translate }}</h1>
                <a class="stretched-link cursor-pointer" (click)="toUserPeriodList()">
                    {{ 'accounting.userCardSubtitle' | translate: {lastName: user.lastName, firstName: user.firstName} }}
                </a>
            </div>
        </div>
    `,
    styleUrls: ['../accounting.scss']
})
export class UserComponent {
    @Input() user!: Pick<User, 'id' | 'firstName' | 'lastName'>;

    constructor(private readonly router: Router) {
    }

    toUserPeriodList() {
        // AccountingStore.set('user', this.user);
        this.router.navigate(['accounting', 'user', this.user.id, 'invoices']);
    }
}
