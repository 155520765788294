import {Injector} from "@angular/core";

let injector: Injector | undefined;

export function useInjector(): Injector {
    if (!injector) {
        throw new Error("There is no Injector available");
    }
    return injector;
}

export function setInjector(value: Injector) {
    injector = value;
}
