import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { ChargePoint, OneCompanyGQL, User } from '../../graphql/generated/cp-graphql_types';
import { BehaviorSubject } from 'rxjs';
import { ChargepointService } from '../chargepoint-list.service';
import { UserDataService } from 'src/app/user-data.service';
import { CompaniesGQL } from '../../graphql/generated/cp-graphql_types';
import { map } from 'rxjs';

/**
 * Component for the tag <code>app-edit-chargepoint-dialog</code>
 * to display a dialog to edit a charging point.
 */
@Component({
    selector: 'app-edit-chargepoint-dialog',
    templateUrl: './edit-chargepoint-dialog.component.html',
    styleUrls: ['./edit-chargepoint-dialog.component.scss'],
})
export class EditChargepointDialogComponent implements OnInit {
    @Input() chargepointId: string | undefined;
    initialChargepoint: ChargePoint;
    editing = new BehaviorSubject(false);
    companies;
    selectedCompanyId = null;
    users!: User[];
    userId!: string;

    chargepointForm!: FormGroup;
    /**
     * Creates an instance of EditChargepointDialogComponent.
     * @param dialogRef Reference to the open dialog.
     * @param logger Service for logging.
     * @param data The given data of the charging point.
     */
    constructor(
        private chargepointService: ChargepointService,
        private readonly logger: NGXLogger,
        private fb: FormBuilder,
        public activeModal: NgbActiveModal,
        private userDataService: UserDataService,
        private companiesGQL: CompaniesGQL,
        public oneCompanyGQL: OneCompanyGQL,
    ) {
        logger.debug('EditChargepointDialogComponent.constructor()');
        this.initialChargepoint = {} as ChargePoint;
        this.companies = companiesGQL
            .watch()
            .valueChanges.pipe(map(result => result.data.companies.edges.map(companyEdge => companyEdge.node)));

        this.hasClientPermission() ? (this.userId = '') : (this.userId = this.userDataService.userId());

        this.chargepointForm = this.fb.group({
            name: [null, [Validators.required, Validators.minLength(2)]],
            owner: this.fb.group({
                id: this.userId,
                type: 'USER',
            }),
        });
    }

    ngOnInit() {
        if (this.chargepointId) {
            this.logger.debug('Load Chargepoint from server ID=', this.chargepointId);
            this.chargepointService.getChargepoint(this.chargepointId).subscribe((result: any) => {
                this.initialChargepoint = result;
                this.setFormData(this.initialChargepoint);
            });
        }
    }

    changeOwnerId(owner: string) {
        if (owner === 'USER') {
            this.chargepointForm.patchValue({ owner: { id: this.userId } });
        } else {
            this.chargepointForm.patchValue({ owner: { id: this.userDataService.companyId() } });
        }
    }

    queryUsers(companyId: string) {
        if (companyId) {
            this.oneCompanyGQL
                .watch({ companyId: companyId })
                .valueChanges.pipe(map(result => result.data.company.users.edges.map(userEdge => userEdge.node)))
                .subscribe(users => {
                    this.users = users;
                });
        }
    }

    addChargepoint() {
        this.chargepointService.createChargepoint(this.chargepointForm.value).subscribe((data: {}) => {
            this.logger.debug('create new Chargepoint RC=', data);
            this.chargepointService.refresh();
        });
    }

    editChargepoint() {
        this.chargepointService
            .updateChargepoint({
                name: this.chargepointForm.value.name,
                id: this.initialChargepoint.id,
                version: this.initialChargepoint.version,
            })
            .subscribe((data: {}) => {
                this.logger.debug('update Chargepoint RC=', data);
                this.chargepointService.refresh();
            });
        this.isEditing(false);
    }

    isEditing(editStatus: boolean) {
        this.editing.next(editStatus);
    }
    hasCompanyPermission() {
        return this.userDataService.checkPermission('chargepoint:modify:company');
    }

    hasClientPermission() {
        return this.userDataService.checkPermission('chargepoint:modify:client');
    }

    private setFormData(chargepoint: ChargePoint) {
        this.isEditing(true);
        this.chargepointForm.patchValue(chargepoint);
    }
}
