import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from "@angular/common";
import { NgbModalModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { LoadingSpinnerComponent } from './components/loading-spinner.component';
import { RouterModule } from "@angular/router";
import { UserNamePipe } from "./pipes/user-name.pipe";
import { FormsModule } from "@angular/forms";
import { AccountingComponent } from './accounting.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserComponent } from "./user-list/user.component";
import { UserPeriodListComponent } from './user-period-list/user-period-list.component';
import { UserPeriodComponent } from './user-period-list/user-period.component';
import { UserPeriodInvoiceListComponent } from './user-period-invoice-list/user-period-invoice-list.component';
import { ExportDialogComponent } from './user-period-invoice-list/export-dialog/export-dialog.component';
import { UserInvoiceExportComponent } from './user-invoice-export/user-invoice-export.component';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { AccountingService } from "./service/accounting.service";
import { PipesModule } from "../pipes/pipes.module";

@NgModule({
    declarations: [
        LoadingSpinnerComponent,
        UserNamePipe,
        AccountingComponent,
        UserListComponent,
        UserComponent,
        UserPeriodListComponent,
        UserPeriodComponent,
        UserPeriodInvoiceListComponent,
        ExportDialogComponent,
        UserInvoiceExportComponent,
        LocalizedDatePipe
    ],
    imports: [
        CommonModule,
        NgbModule,
        TranslateModule,
        RouterModule,
        FormsModule,
        NgbModalModule,
        PipesModule
    ],
    schemas: [NO_ERRORS_SCHEMA],
    providers: [
        AccountingService,
    ]
})
export class AccountingModule {
}
