import {Component, Input} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Modal} from "bootstrap";
import {TableInvoiceTransaction} from "../table-invoice";

@Component({
  selector: 'app-accounting-export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.scss']
})
export class ExportDialogComponent {
    @Input()
    transactionsSelected: Array<TableInvoiceTransaction> = [];

    @Input()
    user!: any;

    constructor(private readonly router: Router, private readonly route: ActivatedRoute) {
    }

    public toInvoiceExport() {
        const userId = this.route.snapshot.params['id'];
        const year = this.route.snapshot.params['year'];
        const month = this.route.snapshot.params['month'];

        Modal.getOrCreateInstance('#accountingModal').hide();
        // AccountingStore.set('selected', this.transactionsSelected);
        this.router.navigate(['accounting', 'user', userId, 'periods', year, month, 'export']);
    }
}
