<article class="settings">
  <header>
    <h4>{{ 'profileData.settings' | translate}}</h4>
  </header>
  <main>
    <form [formGroup]="settingsForm">
      <section class="location form-check form-switch">
        <label class="form-check-label" for="location-toggle">
          {{ 'profileData.location' | translate}}
        </label>
        <input
          type="checkbox"
          class="form-check-input"
          role="switch"
          id="location-toggle"
          formControlName="location"
        >
      </section>
      <section class="notifications form-check form-switch">
        <label class="form-check-label" for="location-toggle">
          {{ 'profileData.notifications' | translate}}
        </label>
        <input
          type="checkbox"
          class="form-check-input"
          role="switch"
          id="notifications-toggle"
          formControlName="notifications"
        >
      </section>
      <section class="language">
        <label class="form-label" for="language-select">
          {{ 'general.language' | translate }}
        </label>
        <select
          id="language-select"
          class="form-select"
          formControlName="language"
        >
          <option
            *ngFor="let lang of supportedLanguages"
            [ngValue]="lang"
          >
            {{ lang.label | translate }}
          </option>
        </select>
      </section>
    </form>
  </main>
</article>
