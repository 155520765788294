import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {map} from "rxjs";
import {ReportingService} from "../reporting.service";
import {DatePipe} from "@angular/common";

@Injectable()
export class TransactionDetailBreadcrumb {
    constructor(protected readonly translateService: TranslateService,
                private readonly reportingService: ReportingService,
                private readonly datePipe: DatePipe) {
    }

    // resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): ResolvedCrumbs {
    //     const transactionId = route.params['transactionId'];
    //     const type = route.params['type'];
    //     if (!transactionId || !type) {
    //         return [];
    //     }
    //     return this.reportingService.getTrackedTransactionById(transactionId)
    //         .pipe(
    //             map(transaction => {
    //                 const format = this.translateService.instant('date-format.date-time-short');
    //                 const lang = this.translateService.currentLang;
    //                 return [
    //                     {
    //                         text: this.translateService.instant(`reporting.breadcrumbs.transactions`),
    //                         path: `reporting`
    //                     },
    //                     {
    //                         text: this.translateService.instant(`reporting.breadcrumbs.${type}`),
    //                         path: `reporting/${type}`
    //                     },
    //                     {
    //                         text: this.datePipe.transform(transaction?.startDate, format, undefined, lang),
    //                     }
    //                 ] as Breadcrumb[]
    //             })
    //         )
    // }
}
