import { RouterModule, Routes } from '@angular/router';
import { ChargepointListComponent } from './chargepoint-list/chargepoint-list.component';
import { ChargepointsMainComponent } from './chargepoints-main.component';
import { CompatibleWallboxesComponent } from './compatible-wallboxes/compatible-wallboxes.component';
import { NgModule } from '@angular/core';
import { WallboxesComponent } from './wallboxes/wallboxes.component';
import { ChargepointDetailsComponent } from './chargepoint-list/chargepoint/chargepoint-details/chargepoint-details.component';
import { chargepointResolver } from './details.resolver';


const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'chargepoints',
    },
    {
        path: '',
        component: ChargepointsMainComponent,
        children: [
            {
                path: 'chargepoints',
                component: ChargepointListComponent,
            },
            {
                path: 'chargepoints/:id',
                component: ChargepointDetailsComponent,
                resolve: { chargepoint: chargepointResolver },
            },
            {
                path: 'wallboxes',
                component: WallboxesComponent,
            },
            {
                path: 'compatible-wallboxes',
                component: CompatibleWallboxesComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ChargepointsRoutingModule {}
