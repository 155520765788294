import {Component, signal} from '@angular/core';
import { BreadcrumbService } from 'src/app/layout/breadcrumb/breadcrumb.service';
import {PaginatedCollection} from "../../accounting/util/pagination.composable";
import {ReportingService} from "../reporting.service";
import {CombinedTransaction} from "./combined-transaction.model";

@Component({
  selector: 'app-all-transactions',
  templateUrl: './all-transactions.component.html',
  styleUrls: ['../reporting.scss', './all-transactions.component.scss']
})
export class AllTransactionsComponent {
    transactionsDisplayed = signal<CombinedTransaction[]>([]);
    pages: PaginatedCollection<CombinedTransaction> = PaginatedCollection.EMPTY;

    constructor(private readonly service: ReportingService, private breadcrumbService: BreadcrumbService) {
    }

    ngOnInit() {
        this.breadcrumbService.reportingCrumbs(2, "all")
        this.service.getAllTransactions().subscribe(value => {
            this.pages = new PaginatedCollection(value, 5);
            this.transactionsDisplayed.set(this.pages.page);
        });
    }

    ngOnDestroy(): void {
        this.breadcrumbService.reportingCrumbs(0);
    }

    previous() {
        this.pages.pageChange(-1);
        this.transactionsDisplayed.set(this.pages.page);
    }

    next() {
        this.pages.pageChange(1);
        this.transactionsDisplayed.set(this.pages.page);
    }
}
