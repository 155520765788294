import {ChargePoint, RecordedPosition} from "../graphql/generated/accounting_graphql_types";

type Transaction = Pick<RecordedPosition, 'id' | 'timeStop' | 'timeStart' | 'transferredEnergy'>
    & { chargePoint: ({ __typename?: 'ChargePoint' } & Pick<ChargePoint, 'id' | 'name'>) };

export type TableInvoiceTransaction = {
    root: Transaction;
    checked: boolean;
    chargePoint: string;
    timeStart: Date;
    timeStop: Date;
    duration: number;
    location: string;
    plate: string;
    cost: number;
}

function duration(transaction: Pick<RecordedPosition, 'id' | 'timeStop' | 'timeStart' | 'transferredEnergy'>) {
    const timeStop = transaction.timeStop ?? 0;
    const timeStart = transaction.timeStart ?? 0;
    const duration = new Date(timeStop).getTime() - new Date(timeStart).getTime();
    if (duration <= 0) {
        return 0
    }
    return duration / 1000 / 3600
}

export function buildTableInvoiceTransaction(transaction: any) {
    const location = 'Unknown';
    return {
        root: transaction,
        checked: false,
        chargePoint: transaction.chargePoint.name,
        type: 'Nutzung',
        timeStart: new Date(transaction.timeStart ?? 0),
        timeStop: new Date(transaction.timeStop ?? 0),
        duration: duration(transaction),
        location: location,
        plate: 'KA-EX-00',
        cost: 0.0
    }
}
