import { NgModule } from '@angular/core';
import {
    APOLLO_NAMED_OPTIONS,
    ApolloModule,
    NamedOptions,
} from 'apollo-angular';
import { ApolloClientOptions, InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import {
    BASE_PATH_GQL_INVOICE_SYSTEM,
    BASE_PATH_GQL_CHARGE_ENGINE,
    BASE_PATH_GQL_MASTER_DATA_MANAGEMENT,
    BASE_PATH_GQL_REPORTING_SYSTEM,
} from './shared/paths';

const uri = BASE_PATH_GQL_CHARGE_ENGINE; // <-- add the URL of the GraphQL server here
export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
    return {
        link: httpLink.create({ uri }),
        cache: new InMemoryCache(),
    };
}

@NgModule({
    exports: [ApolloModule],
    providers: [
        {
            provide: APOLLO_NAMED_OPTIONS, // <-- Different from standard initialization
            useFactory(httpLink: HttpLink): NamedOptions {
                return {
                    mdmQlClient: {
                        cache: new InMemoryCache(),
                        link: httpLink.create({
                            uri: BASE_PATH_GQL_MASTER_DATA_MANAGEMENT,
                        }),
                    },
                    accountingQlClient: {
                        cache: new InMemoryCache(),
                        link: httpLink.create({
                            uri: BASE_PATH_GQL_INVOICE_SYSTEM,
                        }),
                    },
                    chargingQlClient: {
                        cache: new InMemoryCache(),
                        link: httpLink.create({
                            uri: BASE_PATH_GQL_CHARGE_ENGINE,
                        }),
                        defaultOptions: {
                            watchQuery: {
                                fetchPolicy: 'network-only',
                            },
                        },
                    },
                    reportingQlClient: {
                        cache: new InMemoryCache(),
                        link: httpLink.create({
                            uri: BASE_PATH_GQL_REPORTING_SYSTEM,
                        }),
                    },
                };
            },
            deps: [HttpLink],
        },
    ],
})
export class GraphQLModule {}
