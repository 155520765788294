export class KeyedCSV<T> {
    column: string;
    keyElement?: string;
    predicate?: (data: T) => string;

    constructor(column: string, keyElement?: string, predicate?: (data: T) => string) {
        this.column = column;
        this.keyElement = keyElement;
        this.predicate = predicate;
    }
}

export function toCSV(elements: Array<any>, keys: Array<KeyedCSV<any>>): string {
    if (elements.length === 0) {
        throw new Error('Elements can not be empty');
    }
    if (keys.length === 0) {
        throw new Error('Keys can not be empty');
    }
    const columns = keys.map(key => key.column).join(',');
    const rows = elements.map(element => {
        return keys.map(key => {
            if (key.keyElement) {
                return element[key.keyElement];
            }
            if (key.predicate) {
                return key.predicate(element);
            }
            throw new Error(`There is no possibility for retrieving a value for column='${key.column}'`)
        }).join(',');
    });
    rows.unshift(columns);
    return rows.join('\r\n');
}
