import { Component, EventEmitter, Input, Output, QueryList, ViewChildren, OnChanges } from '@angular/core';
import { NgbdSortableHeaderDirective, SortDirection } from './data-sort-directive';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnChanges {
    @ViewChildren(NgbdSortableHeaderDirective) headers: QueryList<NgbdSortableHeaderDirective> | undefined;

    @Input() colNames: string[] = [];
    @Input() data: any[][] = [];
    @Output() checkedChange = new EventEmitter<string[]>();
    @Output() edit = new EventEmitter<string>();
    @Output() delete = new EventEmitter<string[]>();
    @Output() sortInput = new EventEmitter<any>();

    initialSortData = this.data;
    checked: string[] = [];
    allChecked: boolean = false;

    ngOnInit() {
        document.documentElement.style.setProperty('--num-cols', `${this.data[0][0].length - 1}`);
    }

    clickedOnCheckAll() {
        if (this.allChecked) {
            this.checked = [];
        } else {
            this.checked = this.data.map(item => item[0]);
        }
        this.allChecked = !this.allChecked;
        this.checkedChange.emit(this.checked);
    }

    clickedOnEdit(id: string) {
        this.edit.emit(id);
    }
    clickedOnDelete(id: string) {
        this.delete.emit([id]);
    }

    ngOnChanges() {
        this.initialSortData = this.data;
    }

    check(userId: string) {
        if (this.checked.includes(userId)) {
            this.checked = this.checked.filter(d => d !== userId);
        } else {
            this.checked.push(userId);
        }
        if (this.checked.length < this.data.length) {
            this.allChecked = false;
        } else {
            this.allChecked = true;
        }
        this.checkedChange.emit(this.checked);
    }

    onSort(direction: SortDirection, index: number) {
        // resetting other headers
        this.headers?.map((header, idx) => {
            if (idx + 1 != index) {
                header.direction = 'init';
            }
            return header;
        });
        this.sortInput.emit({ field: index, direction: direction.toUpperCase() });
    }
}
