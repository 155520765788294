import {Component, OnInit, signal} from '@angular/core';
import {ReportingService} from "./reporting.service";
import {PaginatedCollection} from "../accounting/util/pagination.composable";
import {buildTrackedTransactionTableModels, TrackedTransactionTableModel} from "./tracked-transactions/tracked-transaction.table.model";

@Component({
    selector: 'app-reporting',
    templateUrl: './reporting.component.html',
    styleUrls: ['./reporting.component.scss', './reporting.scss']
})
export class ReportingComponent implements OnInit {
    transactions: Array<TrackedTransactionTableModel> = [];
    transactionsDisplayed = signal<TrackedTransactionTableModel[]>([]);
    pages: PaginatedCollection<TrackedTransactionTableModel> = PaginatedCollection.EMPTY;

    constructor(private readonly service: ReportingService) {
    }

    ngOnInit() {
        this.service.getTrackedTransactions().subscribe(value => {
            this.transactions = buildTrackedTransactionTableModels(value);
            this.pages = new PaginatedCollection<TrackedTransactionTableModel>(this.transactions, 5);
            this.transactionsDisplayed.set(this.pages.page);
        });
    }

    previous() {
        this.pages.pageChange(-1);
        this.transactionsDisplayed.set(this.pages.page);
    }

    next() {
        this.pages.pageChange(1);
        this.transactionsDisplayed.set(this.pages.page);
    }
}
