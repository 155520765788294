import { CommonModule } from '@angular/common';
import { ErrorMessageComponent } from './error-message.component';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [ErrorMessageComponent],
    imports: [CommonModule, TranslateModule],
    exports: [ErrorMessageComponent],
})
export class ErrorMessageModule {}
