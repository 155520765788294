<div class="container">
  <h4>
    {{ 'vehicleDialog.titleAdd' | translate }}
  </h4>
  <form [formGroup]="vehicleForm" class="vehicle-edit-dialog">
    <div class="row text-left mb-3">
      <div class="col">
        <div class="form-group" id="assignGroup">
          <div class="">
            <label class="form-label es-form-lable" for="assignGroup">{{
              'vehicleDialog.assignTo' | translate
            }}</label>
          </div>
          <div class="form-check form-check-inline mb-3">
            <input
              #user
              class="form-check-input"
              type="radio"
              id="rb_user"
              value="user"
              formControlName="owner"
              (change)="setPrevIdToNull(user.value)"
            />
            <label class="form-check-label" for="rb_user">
              {{ 'vehicleDialog.rb_user' | translate }}
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              #company
              class="form-check-input"
              type="radio"
              id="rb_company"
              value="company"
              formControlName="owner"
              (change)="setPrevIdToNull(company.value)"
            />
            <label class="form-check-label" for="rb_company">
              {{ 'vehicleDialog.rb_company' | translate }}
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 mb-3">
        <div *ngIf="owner.value === 'user'">
          <label class="form-label es-form-lable required">{{
            'vehicleDialog.owner' | translate
          }}</label>
          <select
            #user
            class="form-select"
            id="vehicleUser"
            formControlName="ownerId"
          >
            <option value="null">
              {{ 'vehicleDialog.sel_placeholder' | translate }}
            </option>
            <option *ngFor="let user of this.users | async" [value]="user.id">
              {{ user.firstName }}&nbsp;{{ user.lastName }}
            </option>
          </select>
        </div>

        <div *ngIf="owner.value === 'company'">
          <label
            class="form-label es-form-lable required"
            for="vehicleCompany"
            >{{ 'vehicleDialog.owner' | translate }}</label
          >
          <select
            #selectedCompany
            class="form-select"
            formControlName="companyId"
          >
            <option value="null">
              {{ 'vehicleDialog.sel_placeholder' | translate }}
            </option>
            <option
              *ngFor="let company of this.companies | async"
              [value]="company.id"
            >
              {{ company.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <fieldset class="mb-3">
      <legend class="w-auto">{{
        'vehicleDialog.vehicle' | translate
      }}</legend>
      <div class="row">
        <div class="col-md-6 mb-3">
          <label class="form-label es-form-lable required">{{
            'vehicleDialog.licensePlate' | translate
          }}</label>
          <input
            type="email"
            class="form-control es-form-input"
            formControlName="licensePlate"
            [ngClass]="{
              'is-invalid': licensePlate.invalid && licensePlate.touched
            }"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="mb-3">
            <label class="form-label es-form-lable">{{
              'vehicleDialog.vendor' | translate
            }}</label>
            <input
              type="text"
              class="form-control es-form-input"
              formControlName="vendor"
            />
          </div>
        </div>

        <div class="col">
          <div class="mb-3">
            <label class="form-label es-form-lable">{{
              'vehicleDialog.model' | translate
            }}</label>
            <input
              type="text"
              class="form-control es-form-input"
              formControlName="model"
            />
          </div>
        </div>
      </div>
    </fieldset>
    <div class="dialog-button-bar">
      <button
        class="bs-secondary-button text-nowrap btn"
        (click)="activeModal.dismiss('cancel')"
        type="button"
      >
        {{ 'vehicleDialog.cancel' | translate }}
      </button>
      <button
        class="bs-primary-button btn-block ml-1 btn"
        *ngIf="editing | async"
        (click)="editVehicle(); activeModal.close('save')"
        type="submit"
        [disabled]="!vehicleForm.valid"
      >
        {{ 'vehicleDialog.save' | translate }}
      </button>
      <button
        class="bs-primary-button btn-block ml-1 btn"
        *ngIf="!(editing | async)"
        (click)="addVehicle(); activeModal.close('save')"
        type="submit"
        [disabled]="!vehicleForm.valid"
      >
        {{ 'vehicleDialog.save' | translate }}
      </button>
    </div>
  </form>
</div>
