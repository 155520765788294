<h3>{{ "reporting.detail.header" | translate: { date: transaction?.startDate  | localizedDate: 'date-time-short' } }}</h3>
<div class="d-flex justify-content-between align-items-start gap-3 mt-3">
  <div class="card rounded-3">
    <div class="card-body d-flex flex-column gap-5 px-5 py-5">
      <div class="sidebar-entry">
        <h4>{{ "reporting.detail.transferredEnergy" | translate }}</h4>
        <h5>{{ transferredEnergy | async }}kWh</h5>
      </div>
      <div class="sidebar-entry">
        <h4>{{ "reporting.detail.chargePoint" | translate }}</h4>
        <h5>{{ transaction?.chargePoint?.name ?? '-' }}</h5>
      </div>
      <div class="sidebar-entry">
        <h4>{{ "reporting.detail.duration" | translate }}</h4>
        <h5>{{ (durationValue | async) | duration }}</h5>
      </div>
      <div class="sidebar-entry">
        <h4>{{ "reporting.detail.batteryStateBefore" | translate }}</h4>
        <h5>{{ batteryBefore | async }}</h5>
      </div>
      <div class="sidebar-entry">
        <h4>{{ "reporting.detail.batteryStateAfter" | translate }}</h4>
        <h5>{{ batteryAfter | async }}</h5>
      </div>
      <div class="sidebar-entry">
        <h4>{{ "reporting.detail.rfid" | translate }}</h4>
        <h5>{{ transaction?.idToken?.idTag ?? '-' }}</h5>
      </div>
    </div>
  </div>

  <ng-template #noEvents>
    <div class="flex-grow-1 d-flex justify-content-center items-center">
      <h3 class="text-danger">Diese Transaktion hat keine Sequenzen</h3>
    </div>
  </ng-template>

  <div *ngIf="transaction?.events && 0 !== transaction?.events?.length else noEvents" class="flex-grow-1">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th scope="col"><a>{{ 'reporting.driver' | translate }}</a></th>
          <th scope="col"><a>{{ 'reporting.chargePoint' | translate }}</a></th>
          <th scope="col"><a>{{ 'reporting.start' | translate }}</a></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let event of (transaction?.events ?? []); let index = index;">
          <td>{{ event?.sequenceNumber }}</td>
          <td>{{ event?.chargingStatus }}</td>
          <td>{{ event.timestamp | localizedDate: 'date-time-short' }}</td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="d-flex flex-row justify-content-end align-items-center">
      <div>
        <div class="d-flex flex-row align-items-center">
          <i class="chevron rotate--n90 cursor-pointer" (click)="previous()"></i>
          <span class="mx-5 user-select-none">{{ pages.pageId + 1 }}/{{ pages.pageAmount }}</span>
          <i class="chevron rotate--90 cursor-pointer" (click)="next()"></i>
        </div>
      </div>
    </div>
  </div>
</div>
