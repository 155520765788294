<div class="container">
  <h4>
    {{ 'driverDialog.titleAdd' | translate }}
  </h4>
  <form [formGroup]="userForm" class="user-edit-dialog">
    <fieldset class="mb-3">
      <legend class="w-auto"> {{ 'driverDialog.person' | translate }}</legend>
    <div class="row mb-3">
      <div class="col">
        <label class="form-label es-form-lable required">{{
          'driverDialog.firstNameInputLabel' | translate
        }}</label>
        <input type="text" class="form-control es-form-input"           formControlName="firstName"
        [ngClass]="{
          'is-invalid': firstName.invalid && firstName.touched
        }"
        />
      </div>

      <div class="col">
        <label class="form-label es-form-lable required">{{
          'driverDialog.lastNameInputLabel' | translate
        }}</label>
        <input type="text" class="form-control es-form-input"           formControlName="lastName"
        [ngClass]="{
          'is-invalid': lastName.invalid && lastName.touched
        }"
        />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        <label class="form-label es-form-lable required" for="">{{
          'driverDialog.emailInputLabel' | translate
        }}</label>
        <input type="text" class="form-control es-form-input"           formControlName="email"
        [ngClass]="{
          'is-invalid': email.invalid && email.touched
        }"
        />
      </div>
    </div>
  </fieldset>

  <fieldset class="mb-3">
    <legend class="w-auto">{{ 'driverDialog.address' | translate}}</legend>
    <div class="row mb-3" formGroupName="address">
      <div class="col-md-9">
        <label class="form-label es-form-lable">{{
          'driverDialog.streetInputLabel' | translate
        }}</label>
        <input type="text" class="form-control es-form-input" formControlName="street"/>
      </div>
      <div class="col-md-3">
        <label class="form-label es-form-lable">{{
          'driverDialog.houseNumberInputLabel' | translate
        }}</label>
        <input type="text" class="form-control es-form-input" formControlName="houseNumber"/>
      </div>
    </div>

    <div class="row mb-3" formGroupName="address">
      <div class="col">
        <label class="form-label es-form-lable">{{
          'driverDialog.cityInputLabel' | translate
        }}</label>
        <input type="text" class="form-control es-form-input" formControlName="town"/>
      </div>
      <div class="col">
        <label class="form-label es-form-lable">{{
          'driverDialog.zipCodeInputLabel' | translate
        }}</label>
        <input type="text" class="form-control es-form-input" formControlName="zipCode"/>
      </div>
    </div>
    <div class="row mb-3" formGroupName="address">
      <div class="col-md-6">
        <label class="form-label es-form-lable">{{
          'driverDialog.countryInputLabel' | translate
        }}</label>
        <input type="text" class="form-control es-form-input" formControlName="country"/>
      </div>
    </div>
  </fieldset>
    <div class="row mb-3">
      <div class="col">
        <label
          class="form-label es-form-lable required"
          >{{ 'driverDialog.companySelectLabel' | translate }}</label
        >
        <select
          #selectedCompany
          class="form-select"
          formControlName="companyId"
        >
          <option value="">
            {{ 'driverDialog.companySelectPlaceholder' | translate }}
          </option>
          <option
            *ngFor="let company of this.companies | async"
            [value]="company.id"
          >
            {{ company.name }}
          </option>
        </select>
      </div>
      <div class="col">
        <label class="form-label es-form-lable">{{
          'driverDialog.vehiclesSelectLabel' | translate
        }}</label>
        <select
          #user
          class="form-select"
        >
        <option value="">
          {{ 'driverDialog.vehiclesSelectPlaceholder' | translate }}
        </option>
          <option
            *ngFor="let vehicle of this.vehicles | async"
            [value]="vehicle.id"
          >
            {{ vehicle.licensePlate }}
          </option>
        </select>
      </div>
    </div>

    <div class="dialog-button-bar">
      <button
        class="bs-secondary-button text-nowrap btn"
        (click)="activeModal.dismiss('cancel')"
        type="button"
      >
        {{ 'driverDialog.cancel' | translate }}
      </button>
      <button
        class="bs-primary-button btn-block ml-1 btn"
        *ngIf="editing | async"
        (click)="editUser(); activeModal.close('save')"
        type="submit"
      >
        {{ 'driverDialog.save' | translate }}
      </button>
      <button
        class="bs-primary-button btn-block ml-1 btn"
        *ngIf="!(editing | async)"
        (click)="addUser(); activeModal.close('save')"
        type="submit"
        [disabled]="!userForm.valid"
      >
        {{ 'driverDialog.save' | translate }}
      </button>
    </div>
  </form>
</div>