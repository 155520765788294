import { Component, OnInit } from '@angular/core';
import { User } from '../user';
import { NGXLogger } from 'ngx-logger';
import { UserDataService } from '../../user-data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-profile-edit',
    templateUrl: './profile-edit-dialog.component.html',
    styleUrls: ['./profile-edit-dialog.component.scss'],
})
export class ProfileEditDialogComponent implements OnInit {
    private role = '';
    private readonly user: User;
    editForm!: FormGroup;

    constructor(
        private readonly userService: UserDataService,
        private readonly logger: NGXLogger,
        private readonly fb: FormBuilder,
        public activeModal: NgbActiveModal,
    ) {
        logger.debug('ProfileEditDialogComponent.constructor()');
        this.user = this.userService.getUser();
    }

    ngOnInit(): void {
        this.editForm = this.fb.group({
            firstName: [this.user.firstName, [Validators.required, Validators.maxLength(64)]],
            lastName: [this.user.lastName, [Validators.required, Validators.maxLength(64)]],
            street: [this.user.street],
            houseNumber: [this.user.houseNumber, [Validators.maxLength(5)]],
            zipCode: [this.user.postalCode, [Validators.pattern('^[0-9]*$')]],
            town: [this.user.town],
        });
    }

    get firstName() {
        return this.editForm.controls.firstName;
    }

    get lastName() {
        return this.editForm.controls.lastName;
    }

    get street() {
        return this.editForm.controls.street;
    }

    get houseNumber() {
        return this.editForm.controls.houseNumber;
    }

    get zipCode() {
        return this.editForm.controls.zipCode;
    }

    get town() {
        return this.editForm.controls.town;
    }

    updateUser() {
        this.user.firstName = this.editForm.value.firstName;
        this.user.lastName = this.editForm.value.lastName;
        this.user.street = this.editForm.value.street;
        this.user.houseNumber = this.editForm.value.houseNumber;
        this.user.town = this.editForm.value.town;
        this.user.postalCode = this.editForm.value.zipCode;
        this.userService.updateUser(this.user);
        this.activeModal.close('save');
    }
}
