import { Injectable } from '@angular/core';
import { QueryRef } from 'apollo-angular';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Company } from '../graphql/generated/mdm_graphql_types';
import {
    AllCompaniesGQL,
    AllCompaniesQuery,
    OneCompanyGQL,
    OneCompanyQuery,
    CreateCompanyGQL,
    CreateCompanyInput,
    DeleteCompanyGQL,
    UpdateCompanyGQL,
    UpdateCompanyInput,
} from '../graphql/generated/mdm_graphql_types';

@Injectable()
export class CompanyService {
    companyQueryRef: QueryRef<AllCompaniesQuery, {}> | undefined;

    constructor(
        private logger: NGXLogger,
        private allCompaniesGql: AllCompaniesGQL,
        private oneCompanyGql: OneCompanyGQL,
        private delCompanyGql: DeleteCompanyGQL,
        private createCompanyGQL: CreateCompanyGQL,
        private updateCompanyGQL: UpdateCompanyGQL,
    ) {}

    flattenCompanies(companies: Company[]) {
        let flatCompanyArray = [];
        let permissionArray = [];
        let amountVehicles;
        for (let i = 0; i < companies.length; i++) {
            amountVehicles = companies[i].vehicles?.length || 0;
            flatCompanyArray.push([
                companies[i].id,
                companies[i].name,
                companies[i].address.town + ', ' + companies[i].address.country,
                amountVehicles,
                companies[i].users.length,
                companies[i].chargePoints.length,
                companies[i].groups.length,
            ]);
            permissionArray.push(1);
        }
        return [flatCompanyArray, permissionArray];
    }

    getCompanies(): Observable<AllCompaniesQuery['companies']> {
        this.companyQueryRef = this.allCompaniesGql.watch();
        return this.companyQueryRef.valueChanges.pipe(map(result => result.data.companies));
    }

    getCompany(companyId: string): Observable<OneCompanyQuery['company']> {
        return this.oneCompanyGql.watch({ companyId: companyId }).valueChanges.pipe(map(result => result.data.company));
    }

    refresh() {
        this.logger.debug('call refresh for reloading data');
        this.companyQueryRef?.refetch();
    }

    deleteCompany(companies: string[]): Observable<any> {
        this.logger.debug('call service delete companies with ids:', companies);
        return this.delCompanyGql.mutate({ companyId: companies });
    }

    createCompany(company: CreateCompanyInput): Observable<any> {
        this.logger.debug('call service create company with companyName', company);
        return this.createCompanyGQL.mutate({ createCompany: company });
    }

    updateCompany(company: UpdateCompanyInput): Observable<any> {
        this.logger.debug('call service update company with company-ID', company);
        return this.updateCompanyGQL.mutate({ updateCompany: company });
    }
}
