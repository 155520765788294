<div class="container">
  <h4 *ngIf="!(editing | async)">
    {{ 'addChargepointDialog.title' | translate }}
  </h4>
  <h4 *ngIf="editing | async">
    {{ 'addChargepointDialog.titleEdit' | translate }}
  </h4>
  <form [formGroup]="chargepointForm">
    <div class="row">
      <div class="col-md-6 mb-3">
        <label class="form-label es-form-lable required">{{
          'addChargepointDialog.name' | translate
          }}</label>
        <input type="text" class="form-control es-form-input" formControlName="name" />
      </div>
    </div>
    <ng-container *ngIf="hasCompanyPermission() || hasClientPermission()">
      <div class="row text-left">
        <div class="col">
          <div class="">
            <label class="form-label es-form-lable" for="assignGroup">{{
              'addChargepointDialog.assignTo' | translate
              }}</label>
          </div>
          <div formGroupName="owner" class="form-check form-check-inline
          mb-3">
            <input class="form-check-input" type="radio" value="USER" formControlName="type"
              [checked]="chargepointForm.value.owner.type === 'USER'" (click)="changeOwnerId('USER')"/>
            <label class="form-check-label">
              {{ 'addChargepointDialog.sel_user' | translate }}
            </label>
          </div>
          <div formGroupName="owner" class="form-check form-check-inline">
            <input class="form-check-input" type="radio" value="COMPANY" formControlName="type"
              [checked]="chargepointForm.value.owner.type === 'COMPANY'" (click)="changeOwnerId('COMPANY')"/>
            <label class="form-check-label">
              {{ 'addChargepointDialog.sel_company' | translate }}
            </label>
          </div>
        </div>
      </div>
      <div formGroupName="owner" *ngIf="hasClientPermission()">
        <div class="row mb-3">
          <div class="col">
            <label class="form-label es-form-lable required" for="idTokenCompany">{{ 'addChargepointDialog.sel_company'
              |
              translate }}</label>
            <select #selectedCompany formControlName="id" class="form-select"
              (change)="queryUsers(this.chargepointForm.value.owner.id)">
              <option value="">
                {{ 'addChargepointDialog.sel_placeholder' | translate }}
              </option>
              <ng-container *ngIf="hasCompanyPermission() || hasClientPermission()">
                <option *ngFor="let company of this.companies | async" [value]="company.id">
                  {{ company.name }}
                </option>
              </ng-container>
            </select>
          </div>
        </div>
        <div *ngIf="chargepointForm.value.owner.type === 'USER'" class="row mb-3">
          <div class="col">
            <label class="form-label es-form-lable required" for="idTokenCompany">{{ 'addChargepointDialog.sel_user' |
              translate }}</label>
            <select #selectedUser formControlName="id" class="form-select">
              <option value="">
                {{ 'addChargepointDialog.sel_placeholder' | translate }}
              </option>
              <ng-container *ngIf="(hasCompanyPermission() || hasClientPermission())">
                <option *ngFor="let user of this.users" [value]="user.id">
                  {{ user.firstName }} {{ user.lastName }}
                </option>
              </ng-container>
            </select>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="dialog-button-bar">
      <button class="bs-secondary-button text-nowrap btn" (click)="activeModal.dismiss('cancel')" type="button">
        {{ 'addChargepointDialog.cancel' | translate }}
      </button>
      <button class="bs-primary-button btn-block ml-1 btn" *ngIf="editing | async"
        (click)="editChargepoint(); activeModal.close('save')" type="submit">
        {{ 'addChargepointDialog.update' | translate }}
      </button>
      <button class="bs-primary-button btn-block ml-1 btn" *ngIf="!(editing | async)"
        (click)="addChargepoint(); activeModal.close('save')" type="submit">
        {{ 'addChargepointDialog.add' | translate }}
      </button>
    </div>
  </form>
</div>
