import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** Instant data type support */
    Instant: any;
    /** A 64-bit signed integer */
    Long: any;
    /** A universally unique identifier compliant UUID Scalar */
    UUID: any;
}

export interface Address {
    country: Scalars['String'];
    createdAt: Scalars['Instant'];
    houseNumber: Scalars['String'];
    id: Scalars['UUID'];
    modifiedAt?: Maybe<Scalars['Instant']>;
    street: Scalars['String'];
    town: Scalars['String'];
    version: Scalars['Long'];
    zipCode: Scalars['String'];
}

export interface BaseEntity {
    createdAt: Scalars['Instant'];
    id: Scalars['UUID'];
    modifiedAt?: Maybe<Scalars['Instant']>;
    version: Scalars['Long'];
}

export interface ChargePoint extends BaseEntity {
    __typename?: 'ChargePoint';
    client: Client;
    company?: Maybe<Company>;
    createdAt: Scalars['Instant'];
    id: Scalars['UUID'];
    modifiedAt?: Maybe<Scalars['Instant']>;
    name: Scalars['String'];
    owner?: Maybe<User>;
    version: Scalars['Long'];
}

export interface Client extends BaseEntity {
    __typename?: 'Client';
    companies: Array<Company>;
    contact: ClientContact;
    createdAt: Scalars['Instant'];
    groups: Array<IdTokenGroup>;
    id: Scalars['UUID'];
    idTokens: Array<IdToken>;
    modifiedAt?: Maybe<Scalars['Instant']>;
    name: Scalars['String'];
    users: Array<User>;
    vehicles: Array<Vehicle>;
    version: Scalars['Long'];
}

export interface ClientContact extends BaseEntity, Contact {
    __typename?: 'ClientContact';
    createdAt: Scalars['Instant'];
    email: Scalars['String'];
    id: Scalars['UUID'];
    modifiedAt?: Maybe<Scalars['Instant']>;
    name: Scalars['String'];
    phoneNumber: Scalars['String'];
    version: Scalars['Long'];
}

export interface Company extends BaseEntity {
    __typename?: 'Company';
    address: CompanyAddress;
    chargePoints: Array<ChargePoint>;
    client: Client;
    contact: CompanyContact;
    createdAt: Scalars['Instant'];
    groups: Array<IdTokenGroup>;
    id: Scalars['UUID'];
    idTokens: Array<IdToken>;
    modifiedAt?: Maybe<Scalars['Instant']>;
    name: Scalars['String'];
    users: Array<User>;
    vehicles: Maybe<Vehicle[]>;
    version: Scalars['Long'];
}

export interface CompanyAddress extends Address, BaseEntity {
    __typename?: 'CompanyAddress';
    country: Scalars['String'];
    createdAt: Scalars['Instant'];
    houseNumber: Scalars['String'];
    id: Scalars['UUID'];
    modifiedAt?: Maybe<Scalars['Instant']>;
    street: Scalars['String'];
    town: Scalars['String'];
    version: Scalars['Long'];
    zipCode: Scalars['String'];
}

export interface CompanyContact extends BaseEntity, Contact {
    __typename?: 'CompanyContact';
    createdAt: Scalars['Instant'];
    email: Scalars['String'];
    id: Scalars['UUID'];
    modifiedAt?: Maybe<Scalars['Instant']>;
    name: Scalars['String'];
    phoneNumber: Scalars['String'];
    version: Scalars['Long'];
}

export interface Contact {
    createdAt: Scalars['Instant'];
    email: Scalars['String'];
    id: Scalars['UUID'];
    modifiedAt?: Maybe<Scalars['Instant']>;
    name: Scalars['String'];
    phoneNumber: Scalars['String'];
    version: Scalars['Long'];
}

export interface CreateAddressInput {
    country: Scalars['String'];
    houseNumber: Scalars['String'];
    street: Scalars['String'];
    town: Scalars['String'];
    zipCode: Scalars['String'];
}

export interface CreateClientInput {
    contact: CreateContactInput;
    name: Scalars['String'];
}

export interface CreateCompanyInput {
    address: CreateAddressInput;
    contact: CreateContactInput;
    name: Scalars['String'];
}

export interface CreateContactInput {
    email: Scalars['String'];
    name: Scalars['String'];
    phoneNumber: Scalars['String'];
}

export interface CreateIdTokenGroupInput {
    active: Scalars['Boolean'];
    companyId?: InputMaybe<Scalars['UUID']>;
    name: Scalars['String'];
    ownerId?: InputMaybe<Scalars['UUID']>;
    validAt: Scalars['Instant'];
    validUntil?: InputMaybe<Scalars['Instant']>;
}

export interface CreateIdTokenInput {
    active: Scalars['Boolean'];
    groupId: Scalars['UUID'];
    idTag: Scalars['String'];
    name: Scalars['String'];
    type: IdTokenType;
    validAt: Scalars['Instant'];
    validUntil?: InputMaybe<Scalars['Instant']>;
}

export interface CreateUserInput {
    address?: CreateAddressInput;
    companyId: Scalars['UUID'];
    email: Scalars['String'];
    firstName: Scalars['String'];
    lastName: Scalars['String'];
}

export interface CreateVehicleInput {
    companyId?: InputMaybe<Scalars['UUID']>;
    licensePlate: Scalars['String'];
    model?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Scalars['UUID']>;
    vendor?: InputMaybe<Scalars['String']>;
}

export interface IdToken extends BaseEntity {
    __typename?: 'IdToken';
    active: Scalars['Boolean'];
    client: Client;
    createdAt: Scalars['Instant'];
    group: IdTokenGroup;
    id: Scalars['UUID'];
    idTag: Scalars['String'];
    modifiedAt?: Maybe<Scalars['Instant']>;
    name: Scalars['String'];
    type: IdTokenType;
    validAt: Scalars['Instant'];
    validUntil?: Maybe<Scalars['Instant']>;
    version: Scalars['Long'];
}

export interface IdTokenGroup extends BaseEntity {
    __typename?: 'IdTokenGroup';
    active: Scalars['Boolean'];
    client: Client;
    company?: Maybe<Company>;
    createdAt: Scalars['Instant'];
    id: Scalars['UUID'];
    idTag: Scalars['String'];
    idTokens: Array<IdToken>;
    modifiedAt?: Maybe<Scalars['Instant']>;
    name: Scalars['String'];
    user?: Maybe<User>;
    validAt: Scalars['Instant'];
    validUntil?: Maybe<Scalars['Instant']>;
    version: Scalars['Long'];
}

export enum IdTokenType {
    Central = 'CENTRAL',
    EMaid = 'E_MAID',
    Iso14443 = 'ISO14443',
    Iso15693 = 'ISO15693',
    KeyCode = 'KEY_CODE',
    Local = 'LOCAL',
    MacAddress = 'MAC_ADDRESS',
    NoAuthorization = 'NO_AUTHORIZATION',
}

export interface Mutation {
    __typename?: 'Mutation';
    createClient: Client;
    createCompany: Company;
    createIdToken: IdToken;
    createIdTokenGroup: IdTokenGroup;
    createUser: User;
    createVehicle: Vehicle;
    deleteClient: Array<Scalars['UUID']>;
    deleteCompany: Array<Scalars['UUID']>;
    deleteIdToken: Array<Scalars['UUID']>;
    deleteIdTokenGroup: Array<Scalars['UUID']>;
    deleteUser: Array<Scalars['UUID']>;
    deleteVehicle: Array<Scalars['UUID']>;
    updateClient: Client;
    updateCompany: Company;
    updateIdToken: IdToken;
    updateIdTokenGroup: IdTokenGroup;
    updateUser: User;
    updateVehicle: Vehicle;
}

export interface MutationCreateClientArgs {
    client: CreateClientInput;
}

export interface MutationCreateCompanyArgs {
    company: CreateCompanyInput;
}

export interface MutationCreateIdTokenArgs {
    idToken: CreateIdTokenInput;
}

export interface MutationCreateIdTokenGroupArgs {
    idTokenGroup: CreateIdTokenGroupInput;
}

export interface MutationCreateUserArgs {
    user: CreateUserInput;
}

export interface MutationCreateVehicleArgs {
    vehicle: CreateVehicleInput;
}

export interface MutationDeleteClientArgs {
    ids: Array<Scalars['UUID']>;
}

export interface MutationDeleteCompanyArgs {
    ids: Array<Scalars['UUID']>;
}

export interface MutationDeleteIdTokenArgs {
    ids: Array<Scalars['UUID']>;
}

export interface MutationDeleteIdTokenGroupArgs {
    ids: Array<Scalars['UUID']>;
}

export interface MutationDeleteUserArgs {
    ids: Array<Scalars['UUID']>;
}

export interface MutationDeleteVehicleArgs {
    ids: Array<Scalars['UUID']>;
}

export interface MutationUpdateClientArgs {
    client: UpdateClientInput;
}

export interface MutationUpdateCompanyArgs {
    company: UpdateCompanyInput;
}

export interface MutationUpdateIdTokenArgs {
    idToken: UpdateIdTokenInput;
}

export interface MutationUpdateIdTokenGroupArgs {
    idTokenGroup: UpdateIdTokenGroupInput;
}

export interface MutationUpdateUserArgs {
    user: UpdateUserInput;
}

export interface MutationUpdateVehicleArgs {
    vehicle: UpdateVehicleInput;
}

export interface Query {
    __typename?: 'Query';
    client?: Maybe<Client>;
    clients: Array<Client>;
    companies: Array<Company>;
    company?: Maybe<Company>;
    idToken?: Maybe<IdToken>;
    idTokenGroup?: Maybe<IdTokenGroup>;
    idTokenGroups: Array<IdTokenGroup>;
    idTokens: Array<IdToken>;
    user?: Maybe<User>;
    users: Array<User>;
    vehicle?: Maybe<Vehicle>;
    vehicles: Array<Vehicle>;
}

export interface QueryClientArgs {
    id: Scalars['UUID'];
}

export interface QueryCompanyArgs {
    id: Scalars['UUID'];
}

export interface QueryIdTokenArgs {
    id: Scalars['UUID'];
}

export interface QueryIdTokenGroupArgs {
    id: Scalars['UUID'];
}

export interface QueryUserArgs {
    id: Scalars['UUID'];
}

export interface QueryVehicleArgs {
    id: Scalars['UUID'];
}

export interface UpdateAddressInput {
    country?: InputMaybe<Scalars['String']>;
    houseNumber?: InputMaybe<Scalars['String']>;
    street?: InputMaybe<Scalars['String']>;
    town?: InputMaybe<Scalars['String']>;
    zipCode?: InputMaybe<Scalars['String']>;
}

export interface UpdateClientInput {
    contact?: InputMaybe<UpdateContactInput>;
    id: Scalars['UUID'];
    name?: InputMaybe<Scalars['String']>;
    version: Scalars['Long'];
}

export interface UpdateCompanyInput {
    address?: InputMaybe<UpdateAddressInput>;
    contact?: InputMaybe<UpdateContactInput>;
    id: Scalars['UUID'];
    name?: InputMaybe<Scalars['String']>;
    version: Scalars['Long'];
}

export interface UpdateContactInput {
    email?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    phoneNumber?: InputMaybe<Scalars['String']>;
}

export interface UpdateIdTokenGroupInput {
    active?: InputMaybe<Scalars['Boolean']>;
    companyId?: InputMaybe<Scalars['UUID']>;
    id: Scalars['UUID'];
    name?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Scalars['UUID']>;
    validAt?: InputMaybe<Scalars['Instant']>;
    validUntil?: InputMaybe<Scalars['Instant']>;
    version: Scalars['Long'];
}

export interface UpdateIdTokenInput {
    active?: InputMaybe<Scalars['Boolean']>;
    groupId?: InputMaybe<Scalars['UUID']>;
    id: Scalars['UUID'];
    idTag?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<IdTokenType>;
    validAt?: InputMaybe<Scalars['Instant']>;
    validUntil?: InputMaybe<Scalars['Instant']>;
    version: Scalars['Long'];
}

export interface UpdateUserInput {
    address?: InputMaybe<UpdateAddressInput>;
    companyId?: InputMaybe<Scalars['UUID']>;
    email?: InputMaybe<Scalars['String']>;
    firstName?: InputMaybe<Scalars['String']>;
    id: Scalars['UUID'];
    lastName?: InputMaybe<Scalars['String']>;
    version: Scalars['Long'];
}

export interface UpdateVehicleInput {
    companyId?: InputMaybe<Scalars['UUID']>;
    id: Scalars['UUID'];
    licensePlate?: InputMaybe<Scalars['String']>;
    model?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Scalars['UUID']>;
    vendor?: InputMaybe<Scalars['String']>;
    version: Scalars['Long'];
}

export interface User extends BaseEntity {
    __typename?: 'User';
    address: UserAddress;
    chargePoints: Array<ChargePoint>;
    client: Client;
    company: Company;
    createdAt: Scalars['Instant'];
    email: Scalars['String'];
    firstName: Scalars['String'];
    groups: Array<IdTokenGroup>;
    id: Scalars['UUID'];
    lastName: Scalars['String'];
    modifiedAt?: Maybe<Scalars['Instant']>;
    vehicles: Array<Vehicle>;
    version: Scalars['Long'];
}

export interface UserAddress extends Address, BaseEntity {
    __typename?: 'UserAddress';
    country: Scalars['String'];
    createdAt: Scalars['Instant'];
    houseNumber: Scalars['String'];
    id: Scalars['UUID'];
    modifiedAt?: Maybe<Scalars['Instant']>;
    street: Scalars['String'];
    town: Scalars['String'];
    user?: Maybe<User>;
    version: Scalars['Long'];
    zipCode: Scalars['String'];
}

export interface Vehicle extends BaseEntity {
    __typename?: 'Vehicle';
    client: Client;
    company?: Maybe<Company>;
    createdAt: Scalars['Instant'];
    id: Scalars['UUID'];
    licensePlate: Scalars['String'];
    model?: Maybe<Scalars['String']>;
    modifiedAt?: Maybe<Scalars['Instant']>;
    owner?: Maybe<User>;
    vendor?: Maybe<Scalars['String']>;
    version: Scalars['Long'];
}

export type AllCompaniesQueryVariables = Exact<{ [key: string]: never }>;

export type AllCompaniesQuery = {
    __typename?: 'Query';
    companies: Array<{
        __typename?: 'Company';
        address: CompanyAddress;
        chargePoints: Maybe<ChargePoint>;
        client: Client;
        contact: CompanyContact;
        createdAt: Scalars['Instant'];
        groups: Array<IdTokenGroup>;
        id: Scalars['UUID'];
        idTokens: Array<IdToken>;
        modifiedAt?: Maybe<Scalars['Instant']>;
        name: Scalars['String'];
        users: Array<User>;
        vehicles: Array<Vehicle>;
        version: Scalars['Long'];
    }>;
};

export const AllCompaniesDocument = gql`
    query AllCompanies {
        companies {
            name
            id
            address {
                town
                country
            }
            vehicles {
                licensePlate
            }
            chargePoints {
                id
                name
            }
            users {
                firstName
            }
            groups {
                name
                idTokens {
                    name
                    id
                }
            }
        }
    }
`;

export type AllVehiclesQueryVariables = Exact<{ [key: string]: never }>;

export type AllVehiclesQuery = {
    __typename?: 'Query';
    vehicles: Array<{
        __typename?: 'Vehicle';
        client: string;
        company?: { __typename?: 'Company'; id: any } | null;
        createdAt: any;
        id: any;
        licensePlate: string;
        model: string;
        modifiedAt?: string;
        owner?: Maybe<User>;
        vendor?: string;
        version: any;
    }>;
};

@Injectable({
    providedIn: 'root',
})
export class AllVehiclesGQL extends Apollo.Query<
    AllVehiclesQuery,
    AllVehiclesQueryVariables
> {
    document = AllVehiclesDocument;
    client = 'mdmQlClient';
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}

export const AllVehiclesDocument = gql`
    query AllVehicles {
        vehicles {
            owner {
                firstName
                lastName
                id
            }
            company {
                id
                name
            }
            createdAt
            id
            licensePlate
            model
            vendor
        }
    }
`;

export type AllIdTokenGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type AllIdTokenGroupsQuery = {
    __typename?: 'Query';
    idTokenGroups: Array<{
        __typename?: 'IdTokenGroup';
        id: any;
        version: any;
        name: string;
        idTag: string;
        active: boolean;
        validAt: any;
        validUntil?: any | null;
        company?: { __typename?: 'Company'; id: any } | null;
        user?: { __typename?: 'User'; id: any } | null;
    }>;
};

export type AllIdTokensQueryVariables = Exact<{ [key: string]: never }>;

export type AllIdTokensQuery = {
    __typename?: 'Query';
    idTokens: Array<{
        __typename?: 'IdToken';
        id: any;
        version: any;
        name: string;
        idTag: string;
        type: IdTokenType;
        active: boolean;
        validAt: any;
        validUntil?: any | null;
        group: {
            __typename?: 'IdTokenGroup';
            id: any;
            name: string;
            company?: {
                __typename?: 'Company';
                id: any;
                version: any;
                name: string;
            } | null;
            user?: {
                __typename?: 'User';
                id: any;
                version: any;
                firstName: string;
                lastName: string;
            } | null;
        };
    }>;
};

export type OneIdTokensQueryVariables = Exact<{
    idTokenId: Scalars['UUID'];
}>;

export type OneIdTokensQuery = {
    __typename?: 'Query';
    idToken?: {
        __typename?: 'IdToken';
        id: any;
        version: any;
        name: string;
        idTag: string;
        type: IdTokenType;
        active: boolean;
        validAt: any;
        validUntil?: any | null;
        group: {
            __typename?: 'IdTokenGroup';
            id: any;
            company?: {
                __typename?: 'Company';
                id: any;
                version: any;
                name: string;
            } | null;
            user?: {
                __typename?: 'User';
                id: any;
                version: any;
                firstName: string;
                lastName: string;
            } | null;
        };
    } | null;
};

export type DeleteIdTokensMutationVariables = Exact<{
    idTokenId: Array<Scalars['UUID']> | Scalars['UUID'];
}>;

export type DeleteIdTokensMutation = {
    __typename?: 'Mutation';
    deleteIdToken: Array<any>;
};

export type CreateIdTokenMutationVariables = Exact<{
    createIdToken: CreateIdTokenInput;
}>;

export type CreateIdTokenMutation = {
    __typename?: 'Mutation';
    createIdToken: {
        __typename?: 'IdToken';
        id: any;
        version: any;
        name: string;
        idTag: string;
    };
};

export type UpdateIdTokenMutationVariables = Exact<{
    updateIdToken: UpdateIdTokenInput;
}>;

export type UpdateIdTokenMutation = {
    __typename?: 'Mutation';
    updateIdToken: {
        __typename?: 'IdToken';
        id: any;
        version: any;
        name: string;
        idTag: string;
    };
};

export type AllUsersQueryVariables = Exact<{ [key: string]: never }>;

export type AllUsersQuery = {
    __typename?: 'Query';
    users: Array<{
        __typename?: 'User';
        id: any;
        version: any;
        firstName: string;
        lastName: string;
        groups: Array<{ __typename?: 'IdTokenGroup'; id: any; name: string }>;
        vehicles: Array<Vehicle>;
        address: any;
    }>;
};
export type OneUserQueryVariables = Exact<{
    userId: Scalars['UUID'];
}>;

export type OneUserQuery = {
    __typename?: 'Query';
    user?: {
        __typename?: 'User';
        id: any;
        version: any;
        firstName: string;
        lastName: string;
        company: any;
        address: any;
        email: string;
    };
};

@Injectable({
    providedIn: 'root',
})
export class AllCompaniesGQL extends Apollo.Query<
    AllCompaniesQuery,
    AllCompaniesQueryVariables
> {
    document = AllCompaniesDocument;
    client = 'mdmQlClient';
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const AllIdTokenGroupsDocument = gql`
    query AllIdTokenGroups {
        idTokenGroups {
            id
            version
            name
            idTag
            active
            validAt
            validUntil
            company {
                id
            }
            user {
                id
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class AllIdTokenGroupsGQL extends Apollo.Query<
    AllIdTokenGroupsQuery,
    AllIdTokenGroupsQueryVariables
> {
    document = AllIdTokenGroupsDocument;
    client = 'mdmQlClient';
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const AllIdTokensDocument = gql`
    query AllIdTokens {
        idTokens {
            id
            version
            name
            idTag
            type
            active
            group {
                id
                name
                company {
                    id
                    version
                    name
                }
                user {
                    id
                    version
                    firstName
                    lastName
                }
            }
            validAt
            validUntil
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class AllIdTokensGQL extends Apollo.Query<
    AllIdTokensQuery,
    AllIdTokensQueryVariables
> {
    document = AllIdTokensDocument;
    client = 'mdmQlClient';
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const OneIdTokensDocument = gql`
    query OneIdTokens($idTokenId: UUID!) {
        idToken(id: $idTokenId) {
            id
            version
            name
            idTag
            type
            active
            group {
                id
                company {
                    id
                    version
                    name
                }
                user {
                    id
                    version
                    firstName
                    lastName
                }
            }
            validAt
            validUntil
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class OneIdTokensGQL extends Apollo.Query<
    OneIdTokensQuery,
    OneIdTokensQueryVariables
> {
    document = OneIdTokensDocument;
    client = 'mdmQlClient';
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteIdTokensDocument = gql`
    mutation DeleteIdTokens($idTokenId: [UUID!]!) {
        deleteIdToken(ids: $idTokenId)
    }
`;

@Injectable({
    providedIn: 'root',
})
export class DeleteIdTokensGQL extends Apollo.Mutation<
    DeleteIdTokensMutation,
    DeleteIdTokensMutationVariables
> {
    document = DeleteIdTokensDocument;
    client = 'mdmQlClient';
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateIdTokenDocument = gql`
    mutation CreateIdToken($createIdToken: CreateIdTokenInput!) {
        createIdToken(idToken: $createIdToken) {
            id
            version
            name
            idTag
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class CreateIdTokenGQL extends Apollo.Mutation<
    CreateIdTokenMutation,
    CreateIdTokenMutationVariables
> {
    document = CreateIdTokenDocument;
    client = 'mdmQlClient';
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateIdTokenDocument = gql`
    mutation UpdateIdToken($updateIdToken: UpdateIdTokenInput!) {
        updateIdToken(idToken: $updateIdToken) {
            id
            version
            name
            idTag
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class UpdateIdTokenGQL extends Apollo.Mutation<
    UpdateIdTokenMutation,
    UpdateIdTokenMutationVariables
> {
    document = UpdateIdTokenDocument;
    client = 'mdmQlClient';
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const AllUsersDocument = gql`
    query AllUsers {
        users {
            id
            version
            firstName
            lastName
            address {
                country
                town
                zipCode
                street
                houseNumber
            }
            vehicles {
                licensePlate
            }
            groups {
                id
                name
                idTokens {
                    name
                }
            }
            chargePoints {
                name
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class AllUsersGQL extends Apollo.Query<
    AllUsersQuery,
    AllUsersQueryVariables
> {
    document = AllUsersDocument;
    client = 'mdmQlClient';
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}

export const OneUserDocument = gql`
    query OneUser($userId: UUID!) {
        user(id: $userId) {
            id
            version
            firstName
            lastName
            email
            company {
                id
                name
            }
            address {
                country
                town
                zipCode
                street
                houseNumber
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class OneUserGQL extends Apollo.Query<
    OneUserQuery,
    OneUserQueryVariables
> {
    document = OneUserDocument;
    client = 'mdmQlClient';
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}

export type CreateCompanyMutationVariables = Exact<{
    createCompany: CreateCompanyInput;
}>;

export type CreateCompanyMutation = {
    __typename?: 'Mutation';
    createCompany: {
        id: any;
        version: any;
        name: string;
    };
};

export const CreateCompanyDocument = gql`
    mutation CreateCompany($createCompany: CreateCompanyInput!) {
        createCompany(company: $createCompany) {
            id
            version
            name
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class CreateCompanyGQL extends Apollo.Mutation<
    CreateCompanyMutation,
    CreateCompanyMutationVariables
> {
    document = CreateCompanyDocument;
    client = 'mdmQlClient';
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}

@Injectable({
    providedIn: 'root',
})
export class UpdateCompanyGQL extends Apollo.Mutation<
    UpdateCompanyMutation,
    UpdateCompanyMutationVariables
> {
    document = UpdateCompanyDocument;
    client = 'mdmQlClient';
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}

export type UpdateCompanyMutationVariables = Exact<{
    updateCompany: UpdateCompanyInput;
}>;

export type UpdateCompanyMutation = {
    __typename?: 'Mutation';
    updateCompany: {
        __typename?: 'Company';
        id: any;
        version: any;
        name: string;
        contact: Contact;
        address: Address;
    };
};

export const UpdateCompanyDocument = gql`
    mutation UpdateCompany($updateCompany: UpdateCompanyInput!) {
        updateCompany(company: $updateCompany) {
            id
            version
            name
            contact {
                email
                name
                phoneNumber
            }
            address {
                country
                town
                zipCode
                street
                houseNumber
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class DeleteCompanyGQL extends Apollo.Mutation<
    DeleteCompanyMutation,
    DeleteCompanyMutationVariables
> {
    document = DeleteCompanyDocument;
    client = 'mdmQlClient';
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}

export const DeleteCompanyDocument = gql`
    mutation DeleteCompany($companyId: [UUID!]!) {
        deleteCompany(ids: $companyId)
    }
`;

export type DeleteCompanyMutationVariables = Exact<{
    companyId: Array<Scalars['UUID']> | Scalars['UUID'];
}>;

export type DeleteCompanyMutation = {
    __typename?: 'Mutation';
    deleteCompany: Array<any>;
};

@Injectable({
    providedIn: 'root',
})
export class OneCompanyGQL extends Apollo.Query<
    OneCompanyQuery,
    OneCompanyQueryVariables
> {
    document = OneCompanyDocument;
    client = 'mdmQlClient';
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}

export type OneCompanyQueryVariables = Exact<{
    companyId: Scalars['UUID'];
}>;

export type OneCompanyQuery = {
    __typename?: 'Query';
    company?: {
        address: CompanyAddress;
        chargePoints: Array<ChargePoint>;
        client: Client;
        contact: CompanyContact;
        createdAt: Scalars['Instant'];
        groups: Array<IdTokenGroup>;
        id: Scalars['UUID'];
        idTokens: Array<IdToken>;
        modifiedAt?: Maybe<Scalars['Instant']>;
        name: Scalars['String'];
        users: Array<User>;
        vehicles: Maybe<Vehicle>;
        version: Scalars['Long'];
    };
};
export const OneCompanyDocument = gql`
    query OneCompany($companyId: UUID!) {
        company(id: $companyId) {
            id
            version
            name
            address {
                country
                town
                zipCode
                street
                houseNumber
                id
            }
            contact {
                email
                name
                phoneNumber
            }
            chargePoints {
                id
                name
            }
            groups {
                id
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class OneCompanyNameGQL extends Apollo.Query<
    OneCompanyNameQuery,
    OneCompanyNameQueryVariables
> {
    document = OneCompanyNameDocument;
    client = 'mdmQlClient';
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}

export type OneCompanyNameQueryVariables = Exact<{
    companyId: Scalars['UUID'];
}>;

export type OneCompanyNameQuery = {
    __typename?: 'Query';
    company?: {
        id: Scalars['UUID'];
        name: Scalars['String'];
    };
};
export const OneCompanyNameDocument = gql`
    query OneCompany($companyId: UUID!) {
        company(id: $companyId) {
            id
            name
        }
    }
`;



export type CreateVehicleMutationVariables = Exact<{
    createVehicle: CreateVehicleInput;
}>;

export type CreateVehicleMutation = {
    __typename?: 'Mutation';
    createVehicle: {
        __typename?: 'Vehicle';
        companyId?: any;
        licensePlate: string;
        model?: string;
        ownerId?: any;
        vendor?: string;
    };
};

export const CreateVehicleDocument = gql`
    mutation CreateVehicle($createVehicle: CreateVehicleInput!) {
        createVehicle(vehicle: $createVehicle) {
            licensePlate
            id
            version
            vendor
            model
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class CreateVehicleGQL extends Apollo.Mutation<
    CreateVehicleMutation,
    CreateVehicleMutationVariables
> {
    document = CreateVehicleDocument;
    client = 'mdmQlClient';
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}

@Injectable({
    providedIn: 'root',
})
export class DeleteVehicleGQL extends Apollo.Mutation<
    DeleteVehicleMutation,
    DeleteVehicleMutationVariables
> {
    document = DeleteVehicleDocument;
    client = 'mdmQlClient';
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}

export const DeleteVehicleDocument = gql`
    mutation DeleteVehicle($vehicleId: [UUID!]!) {
        deleteVehicle(ids: $vehicleId)
    }
`;

export type DeleteVehicleMutationVariables = Exact<{
    vehicleId: Array<Scalars['UUID']> | Scalars['UUID'];
}>;

export type DeleteVehicleMutation = {
    __typename?: 'Mutation';
    deleteVehicle: Array<any>;
};

@Injectable({
    providedIn: 'root',
})
export class OneVehicleGQL extends Apollo.Query<
    OneVehicleQuery,
    OneVehicleQueryVariables
> {
    document = OneVehicleDocument;
    client = 'mdmQlClient';
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}

export type OneVehicleQueryVariables = Exact<{
    vehicleId: Scalars['UUID'];
}>;

export type OneVehicleQuery = {
    __typename?: 'Query';
    vehicle?: {
        companyId?: Maybe<Scalars['UUID']>;
        id: Scalars['UUID'];
        licensePlate?: Maybe<Scalars['String']>;
        model?: Maybe<Scalars['String']>;
        ownerId?: Maybe<Scalars['UUID']>;
        vendor?: Maybe<Scalars['String']>;
        version: Scalars['Long'];
    };
};
export const OneVehicleDocument = gql`
    query OneVehicle($vehicleId: UUID!) {
        vehicle(id: $vehicleId) {
            __typename
            company {
                id
                name
            }
            id
            licensePlate
            owner {
                id
                firstName
                lastName
            }
            vendor
            model
            version
        }
    }
`;
@Injectable({
    providedIn: 'root',
})
export class UpdateVehicleGQL extends Apollo.Mutation<
    UpdateVehicleMutation,
    UpdateVehicleMutationVariables
> {
    document = UpdateVehicleDocument;
    client = 'mdmQlClient';
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}

export type UpdateVehicleMutationVariables = Exact<{
    updateVehicle: UpdateVehicleInput;
}>;

export type UpdateVehicleMutation = {
    __typename?: 'Mutation';
    updateVehicle: {
        __typename?: 'Vehicle';
        companyId?: any;
        id: any;
        licensePlate?: string;
        model?: string;
        ownerId?: any;
        vendor?: string;
        version: any;
    };
};

export const UpdateVehicleDocument = gql`
    mutation UpdateVehicle($updateVehicle: UpdateVehicleInput!) {
        updateVehicle(vehicle: $updateVehicle) {
            id
            licensePlate
            model
            vendor
            version
        }
    }
`;

export type CreateUserMutationVariables = Exact<{
    createUser: CreateUserInput;
}>;

export type CreateUserMutation = {
    __typename?: 'Mutation';
    createUser: {
        id: any;
        version: any;
        name: string;
    };
};

export const CreateUserDocument = gql`
    mutation CreateUser($createUser: CreateUserInput!) {
        createUser(user: $createUser) {
            address {
                country
                houseNumber
                street
                town
                zipCode
            }
            email
            firstName
            lastName
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class CreateUserGQL extends Apollo.Mutation<
    CreateUserMutation,
    CreateUserMutationVariables
> {
    document = CreateUserDocument;
    client = 'mdmQlClient';
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}

@Injectable({
    providedIn: 'root',
})
export class UpdateUserGQL extends Apollo.Mutation<
    UpdateUserMutation,
    UpdateUserMutationVariables
> {
    document = UpdateUserDocument;
    client = 'mdmQlClient';
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}

export type UpdateUserMutationVariables = Exact<{
    updateUser: UpdateUserInput;
}>;

export type UpdateUserMutation = {
    __typename?: 'Mutation';
    updateIdToken: {
        __typename?: 'User';
        id: any;
        version: any;
    };
};

export const UpdateUserDocument = gql`
    mutation UpdateUser($updateUser: UpdateUserInput!) {
        updateUser(user: $updateUser) {
            id
            version
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class DeleteUserGQL extends Apollo.Mutation<
    DeleteUserMutation,
    DeleteUserMutationVariables
> {
    document = DeleteUserDocument;
    client = 'mdmQlClient';
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}

export const DeleteUserDocument = gql`
    mutation DeleteIdTokens($userId: [UUID!]!) {
        deleteUser(ids: $userId)
    }
`;

export type DeleteUserMutationVariables = Exact<{
    userId: Array<Scalars['UUID']> | Scalars['UUID'];
}>;

export type DeleteUserMutation = {
    __typename?: 'Mutation';
    deleteUser: Array<any>;
};
