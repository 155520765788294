import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table/table.component';
import { NgbdSortableHeaderDirective } from './table/data-sort-directive';
import { TranslateModule } from '@ngx-translate/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ButtonBarComponent } from './button-bar/button-bar.component';

@NgModule({
    declarations: [TableComponent, ButtonBarComponent, NgbdSortableHeaderDirective],
    imports: [CommonModule, TranslateModule, NgbDropdownModule, RouterModule, FormsModule],
    exports: [TableComponent, ButtonBarComponent, NgbdSortableHeaderDirective],
})
export class SharedModule {}
