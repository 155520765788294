<div class="grid sortable" id="header">
  <div scope="col">
    <input type="checkbox" [(ngModel)]="allChecked" id="driverSelection" (click)="clickedOnCheckAll()" />
  </div>
  <div scope="col" sortable *ngFor="let colName of colNames; index as i" (sort)="onSort($event, i + 1)">
    {{ colName | translate }}
  </div>
  <div></div>
</div>
<div class="grid" id="contents" *ngFor="let row of data[0]; index as i">
  <div>
    <input type="checkbox" [ngModel]="checked.includes(row[0])" (change)="check(row[0])" />
  </div>
  <div *ngFor="let item of row | slice : 1">{{ item }}</div>
  <div>
    <div ngbDropdown class="d-inline-block">
      <button type="button" class="menu" id="actionDropdown" ngbDropdownToggle>
        <img src="assets/icon-contextmenu.svg" width="17px" height="3px" alt="action dropdown" />
      </button>
      <div ngbDropdownMenu aria-labelledby="actionDropdown">
        <button ngbDropdownItem class="dropdown-item" [routerLink]="row[0]">
          {{ 'options.details' | translate }}
        </button>
        <button *ngIf="data[1][i]" ngbDropdownItem (click)="clickedOnEdit(row[0])">
          {{ 'options.edit' | translate }}
        </button>
        <button *ngIf="data[1][i]" ngbDropdownItem class="delete" (click)="clickedOnDelete(row[0])">
          {{ 'options.delete' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
