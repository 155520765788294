import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, VERSION } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { SUPPORTED_LANGUAGES } from './shared/predefined-values';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

/**
 * Root component with the tag <code>app-root</code>.
 */
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    /**
     * Observe the result if the maximum width is 599.99px in portrait mode or 959.99px in landscape mode.
     */
    isHandset$: Observable<boolean> = this.breakpointObserver
        .observe(Breakpoints.Handset)
        .pipe(map(result => result.matches));

    /**
     * Creates an instance of AppComponent and sets the initial/default language.
     * @param translate Service for setting the initial/default language.
     * @param breakpointObserver Observes for {@link isHandset$} to match the handset sizes.
     * @param logger Service for logging.
     */
    constructor(
        private readonly translate: TranslateService,
        private readonly breakpointObserver: BreakpointObserver,
        private readonly logger: NGXLogger,
        private modalConfig: NgbModalConfig,
    ) {
        logger.debug('AppComponent.constructor()');
        logger.debug(`Angular ${VERSION.full}: The web application is started`);

        translate.addLangs(SUPPORTED_LANGUAGES.map(lang => lang.code));

        const browserLang = window.navigator.language;
        translate.use(SUPPORTED_LANGUAGES.some(lang => lang.code === browserLang) ? browserLang : 'en').subscribe();

        this.modalConfig.centered = true;
        this.modalConfig.backdrop = 'static';
        this.modalConfig.keyboard = false;
        this.modalConfig.ariaLabelledBy = 'modal-basic-title';
    }
}
