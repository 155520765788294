import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-accounting-loading-spinner',
    template: `
        <div class="d-flex flex-column justify-content-center align-items-center text-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Laden</span>
            </div>
            <p *ngIf="loadingMessage" class="spinner-subtitle mt-3">{{loadingMessage}}</p>
        </div>
    `,
    styleUrls: ['../accounting.scss']
})
export class LoadingSpinnerComponent {
    @Input() loadingMessage: string | undefined
}
