import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BreadcrumbService } from 'src/app/layout/breadcrumb/breadcrumb.service';
import { IdToken } from '../../graphql/generated/mdm_graphql_types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IdTokenService } from '../id-token-list.service';
import { EditIdTokenDialogComponent } from '../edit-id-token-dialog/edit-id-token-dialog.component';


@Component({
    selector: 'app-details-id-token',
    templateUrl: './details-id-token.component.html',
    styleUrls: ['./details-id-token.component.scss']
})
export class DetailsIdTokenComponent implements OnInit {
    idToken: IdToken | undefined;

    constructor(
        private readonly idTokenService: IdTokenService,
        private readonly route: ActivatedRoute,
        private readonly logger: NGXLogger,
        private breadcrumbService: BreadcrumbService,
        private modalService: NgbModal,
    ) {
        logger.debug('DetailsDriverComponent.constructor()');
        logger.debug(
            'DetailsDriverComponent.constructor(): this.idToken=',
            this.idToken,
        );
    }


    ngOnInit() {
        const id = this.route.snapshot.paramMap.get('id') ?? '';
        this.breadcrumbService.masterDataCrumbs(3, "id-tokens", id)
        this.logger.debug('DetailsDriverComponent.ngOnInit(): id=', id);
        this.idTokenService.getIdToken(id).subscribe((result: any) => {
            this.idToken = result;
        });
    }


    openDialogEdit() {
        this.logger.debug('idToken-ListComponent.openDialogEdit()');
        let editDialogRef = this.modalService.open(EditIdTokenDialogComponent, {
            centered: true,
            ariaLabelledBy: 'modal-basic-title',
            backdrop: 'static',
            keyboard: false,
            windowClass: 'modal',
        });
        editDialogRef.componentInstance.idTokenId = this.idToken?.id;
    }


}
