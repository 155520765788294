import { Directive, EventEmitter, Input, Output } from '@angular/core';

export type SortDirection = 'asc' | 'desc' | 'init';
const rotate: { [key: string]: SortDirection } = {
    asc: 'desc',
    desc: 'init',
    init: 'asc',
};

@Directive({
    selector: 'div[sortable]',
    host: {
        '[class.asc]': 'direction === "asc"',
        '[class.desc]': 'direction === "desc"',
        '[class.init]': 'direction === "init"',
        '(click)': 'rotate()',
    },
})
export class NgbdSortableHeaderDirective {
    @Input() direction: SortDirection = 'init';
    @Output() sort = new EventEmitter<SortDirection>();

    rotate() {
        this.direction = rotate[this.direction];
        this.sort.emit(this.direction);
    }
}
