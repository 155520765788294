import {Component, OnInit, signal} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ReportingService} from "../reporting.service";
import {Observable, of} from "rxjs";
import {GetTrackedTransactionQuery} from "../graphql/generated/reporting_graphql_types";
import {PaginatedCollection} from "../../accounting/util/pagination.composable";

@Component({
  selector: 'app-transaction-detail',
  templateUrl: './transaction-detail.component.html',
  styleUrls: ['../reporting.scss', './transaction-detail.component.scss']
})
export class TransactionDetailComponent implements OnInit {
    transaction: GetTrackedTransactionQuery['trackedTransaction'];

    eventsDisplayed = signal<any[]>([]);
    pages: PaginatedCollection<any> = PaginatedCollection.EMPTY;

    constructor(private readonly route: ActivatedRoute,
                private readonly service: ReportingService) {
    }

    ngOnInit() {
        const transactionId = this.route.snapshot.params['transactionId'];
        this.service.getTrackedTransactionById(transactionId).subscribe(value => {
            this.transaction = value;
            this.pages = new PaginatedCollection<any>(this.transaction?.events ?? [], 5);
            this.eventsDisplayed.set(this.pages.page);
        });
    }

    get transferredEnergy(): Observable<number> {
        return of(0);
    }

    get durationValue(): Observable<number> {
        return of(0);
    }

    get batteryBefore(): Observable<number> {
        return of(0);
    }

    get batteryAfter(): Observable<number> {
        return of(0);
    }

    previous() {
        this.pages.pageChange(-1);
        this.eventsDisplayed.set(this.pages.page);
    }

    next() {
        this.pages.pageChange(1);
        this.eventsDisplayed.set(this.pages.page);
    }
}
