import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbComponent } from './breadcrumb.component';

@NgModule({
    imports: [CommonModule, RouterModule, TranslateModule],
    declarations: [BreadcrumbComponent],
    exports: [BreadcrumbComponent],
})
export class BreadcrumbModule {
    static forRoot(): ModuleWithProviders<BreadcrumbModule> {
        return {
            ngModule: BreadcrumbModule,
            providers: [],
        };
    }
}
