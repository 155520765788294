import { Component, Input } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

/**
 * Component for the representation of an error message by the tag
 * <code>app-error-message [text]="..."</code>;
 */
@Component({
    selector: 'app-error-message',
    templateUrl: './error-message.component.html',
    styleUrls: ['./error-message.component.scss'],
})
export class ErrorMessageComponent {
    /**
     * The error message given from the {@link ChargingPointListComponent}.
     */
    @Input()
    text: string | undefined;

    /**
     * Creates an instance of ErrorMessageComponent.
     * @param logger Service for logging.
     */
    constructor(private readonly logger: NGXLogger) {
        logger.debug('ErrorMessageComponent.constructor()');
    }
}
