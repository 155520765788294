/* eslint-disable max-classes-per-file, no-useless-constructor, no-empty-function */

/**
 * Defines a classes for a find error.
 */
export class FindError {
    /**
     * Creates an instance of FindError.
     * @param statuscode The statuscode of the given error.
     * @param cause The cause of the given error.
     */
    constructor(readonly statuscode: number, readonly cause?: Error) {}
}

/**
 * Defines a classes for a save error.
 */
export class SaveError {
    /**
     * Creates an instance of SaveError.
     * @param statuscode The statuscode of the given error.
     * @param cause The cause of the given error.
     */
    constructor(readonly statuscode: number, readonly cause?: Error | string) {}
}

/**
 * Defines a classes for a update error.
 */
export class UpdateError {
    /**
     * Creates an instance of UpdateError.
     * @param statuscode The statuscode of the given error.
     * @param cause The cause of the given error.
     */
    constructor(readonly statuscode: number, readonly cause?: Error | string) {}
}

/**
 * Defines a classes for a delete error.
 */
export class DeleteError {
    /**
     * Creates an instance of DeleteError.
     * @param statuscode The statuscode of the given error.
     */
    constructor(readonly statuscode: number) {}
}

/* eslint-enable max-classes-per-file, no-useless-constructor, no-empty-function */
