import { Component, signal } from '@angular/core';
import { buildTrackedTransactionTableModels, TrackedTransactionTableModel } from "./tracked-transaction.table.model";
import { PaginatedCollection } from "../../accounting/util/pagination.composable";
import { ReportingService } from "../reporting.service";
import { Router } from "@angular/router";
import { BreadcrumbService } from 'src/app/layout/breadcrumb/breadcrumb.service';

@Component({
    selector: 'app-tracked-transactions',
    templateUrl: './tracked-transactions.component.html',
    styleUrls: ['../reporting.scss', './tracked-transactions.component.scss']
})
export class TrackedTransactionsComponent {
    transactions: Array<TrackedTransactionTableModel> = [];
    transactionsDisplayed = signal<TrackedTransactionTableModel[]>([]);
    pages: PaginatedCollection<TrackedTransactionTableModel> = PaginatedCollection.EMPTY;

    constructor(private readonly service: ReportingService,
        private readonly router: Router,
        private breadcrumbService: BreadcrumbService) {
    }

    ngOnInit() {
        this.breadcrumbService.reportingCrumbs(2, "tracked");
        this.service.getTrackedTransactions().subscribe(value => {
            this.transactions = buildTrackedTransactionTableModels(value);
            this.pages = new PaginatedCollection<TrackedTransactionTableModel>(this.transactions, 5);
            this.transactionsDisplayed.set(this.pages.page);
        });
    }

    ngOnDestroy(): void {
        this.breadcrumbService.reportingCrumbs(0);
    }

    previous() {
        this.pages.pageChange(-1);
        this.transactionsDisplayed.set(this.pages.page);
    }

    next() {
        this.pages.pageChange(1);
        this.transactionsDisplayed.set(this.pages.page);
    }

    toDetail(transaction: TrackedTransactionTableModel) {
        this.router.navigate(['reporting', 'detail', 'tracked', transaction.root.id]);
    }
}
