import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { DriverService } from '../driver-list.service';
import { EditDriverDialogComponent } from '../edit-driver-dialog/edit-driver-dialog.component';
import { NGXLogger } from 'ngx-logger';
import { BreadcrumbService } from 'src/app/layout/breadcrumb/breadcrumb.service';
import { User } from '../../graphql/generated/mdm_graphql_types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

/**
 * Component for the tag <code>app-details-driver</code>
 * to displaying the details of a company.
 */
@Component({
    selector: 'app-details-driver',
    templateUrl: './details-driver.component.html',
    styleUrls: ['./details-driver.component.scss'],
})
export class DetailsDriverComponent implements OnInit {
    user: User | undefined;

    constructor(
        private readonly driverService: DriverService,
        private readonly route: ActivatedRoute,
        private readonly logger: NGXLogger,
        private breadcrumbService: BreadcrumbService,
        private modalService: NgbModal,
    ) {
        logger.debug('DetailsDriverComponent.constructor()');
        logger.debug(
            'DetailsDriverComponent.constructor(): this.driver=',
            this.user,
        );
    }


    ngOnInit() {
        const id = this.route.snapshot.paramMap.get('id') ?? '';
        this.breadcrumbService.masterDataCrumbs(3, "drivers", id)
        this.logger.debug('DetailsDriverComponent.ngOnInit(): id=', id);
        this.driverService.getUser(id).subscribe((result: any) => {
            this.user = result;
        });
    }


    openDialogEdit() {
        this.logger.debug('driver-ListComponent.openDialogEdit()');
        let editDialogRef = this.modalService.open(EditDriverDialogComponent, {
            centered: true,
            ariaLabelledBy: 'modal-basic-title',
            backdrop: 'static',
            keyboard: false,
            windowClass: 'modal',
        });
        editDialogRef.componentInstance.userId = this.user?.id;
    }
}
