<div class="wrapper-top">
  <h3 class="fw-semibold">{{ chargepointDetails.name }}</h3>
  <div *ngIf="chargepointDetails.owner.__typename == 'User'">
    <img class="user-img" src="assets/icon-user.svg" alt="icon-user" />
    {{ chargepointDetails.owner.firstName }} {{ chargepointDetails.owner.lastName }}
  </div>
  <div *ngIf="chargepointDetails.owner.__typename == 'Company'">
    <img class="company-img" src="assets/icon-company.svg" alt="icon-company" />
    {{ chargepointDetails.owner.name }}
  </div>
  <div *ngIf="chargepointDetails.owner.__typename != 'Company' && chargepointDetails.owner.__typename != 'User'">
    <img class="company-img" src="assets/icon-globe.svg" alt="icon-globe" />
  </div>
  <button class="btn edit" (click)="openDialogEdit(chargepointDetails.id)">
    <img src="assets/button-edit.svg" alt="edit" />
  </button>
</div>

<div class="wrapper">
  <div class="left-container">
    <div>
      <div class="entryKeys">
        {{ 'chargepointDetails.lastTransaction' | translate }}
      </div>
      <div class="entryValues">{{ lastTransaction }}</div>
    </div>
    <div>
      <div class="entryKeys">
        {{ 'chargepointDetails.location' | translate }}
      </div>
      <div class="entryValues">{{ chargepointDetails.location?.name }}</div>
    </div>
    <div>
      <div class="entryKeys">
        {{ 'chargepointDetails.status' | translate }}
      </div>
      <div class="entryValues">{{ chargepointDetails.connectionStatus }}</div>
    </div>
    <div>
      <div class="entryKeys">
        {{ 'chargepointDetails.model' | translate }}
      </div>
      <div class="entryValues">{{ chargepointDetails.device?.model }}</div>
    </div>
    <div>
      <div class="entryKeys">
        {{ 'chargepointDetails.serialnumber' | translate }}
      </div>
      <div class="entryValues">{{ chargepointDetails.serialnumber }}</div>
    </div>
    <div>
      <div class="entryKeys">
        {{ 'chargepointDetails.firmware' | translate }}
      </div>
      <div class="entryValues">{{ chargepointDetails.firmware }}</div>
    </div>
    <div>
      <div class="entryKeys">
        {{ 'chargepointDetails.vendor' | translate }}
      </div>
      <div class="entryValues">{{ chargepointDetails.device?.vendor }}</div>
    </div>
    <div>
      <div class="entryKeys">
        {{ 'chargepointDetails.chargedEnergy' | translate }}
      </div>
      <div class="entryValues">{{ chargepointDetails.device?.chargingPower }} kWh</div>
    </div>
    <div>
      <div class="entryKeys">
        {{ 'chargepointDetails.chargePointId' | translate }}
      </div>
      <div class="entryValues">{{ chargepointDetails.id }}</div>
    </div>
  </div>
  <ng-container>
    <div class="right-container">
      <h1 class="charged-body">150 kWh</h1>
    </div>
  </ng-container>
</div>
