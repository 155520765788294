<div class="quickinfo">
  <div class="quickinfo-element">
    <h6 class="desc">{{ 'overviewCard.badgeCount' | translate }}</h6>
    <h3 class="value">1 {{ 'overviewCard.of' | translate }} x</h3>
  </div>
  <div class="quickinfo-element">
    <h6 class="desc">{{ 'overviewCard.myRanking' | translate }}</h6>
    <h3 class="value">12 <span>&nbsp;(75 {{ 'overviewCard.pt' | translate }}.)</span></h3>
  </div>
  <div class="quickinfo-element rank">
    <h6 class="desc">{{ 'overviewCard.myTitel' | translate }}</h6>
    <h3 class="value">{{ 'gamification.greenthinker' | translate }}</h3>
    <img class="badge" src="assets/illu-badge-gruendenkerin.svg" alt=""/>
  </div>
</div>

<div class="dashboard">
  <div class="card dashboard-element my-chargings">
    <div class="card-header">
      <h4>{{ 'dashboard.user.myChargingsToday' | translate }}</h4>
    </div>
    <div class="card-body">
      <h1>1</h1>
      <p class="duration">
        {{ 'dashboard.user.duration' | translate }} <span>&nbsp; 1,75 h</span>
      </p>
      <p class="consumption">
        {{ 'dashboard.user.consumption' | translate }} <span>&nbsp; 79 kWh</span>
      </p>
    </div>
  </div>
  

  <div class="card dashboard-element my-reservations">
    <div class="card-header">
      {{ 'dashboard.user.myReservations' | translate }}
    </div>
    <div class="card-body">
      <div class="charging-stations">
        <h5>{{ 'dashboard.user.chargingPoints' | translate }}</h5>
      </div>
      <div class="vehicles">
        <h5>{{ 'dashboard.user.vehicles' | translate }}</h5>
      </div>
    </div>
  </div>
  

  <div class="card dashboard-element top-3-driver">
    <div class="card-header">{{ 'dashboard.user.top3' | translate }}</div>
    <div class="card-body">
      <img class="illu-top3" src="assets/illu-top3.svg" alt=""/>
      <div class="ranking">
        <div class="entry">
          <h3>1.</h3>
          <div>
            <h6 class="rank">{{ 'gamification.chargeguard' | translate }}</h6>
            <p class="user">Frank Tengel</p>
          </div>
          <p class="points">700 {{ 'dashboard.user.points' | translate }}</p>
        </div>
        <div class="entry">
          <h3>2.</h3>
          <div>
            <h6 class="rank">{{ 'gamification.greenthinker' | translate }}</h6>
            <p class="user">Christopher Krüger</p>
          </div>
          <p class="points">650 {{ 'dashboard.user.points' | translate }}</p>
        </div>
        <div class="entry">
          <h3>3.</h3>
          <div>
            <h6 class="rank">{{ 'gamification.chargeguard' | translate }}</h6>
            <p class="user">Volkan Güllü</p>
          </div>
          <p class="points">600 {{ 'dashboard.user.points' | translate }}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="card dashboard-element my-badges">
    <div class="card-header">{{ 'dashboard.user.myBadges' | translate }}</div>
    <div class="card-body">
      <p>{{ 'dashboard.user.myBadgesInfo1' | translate }}</p>
      <p>{{ 'dashboard.user.myBadgesInfo2' | translate:{points: '45'} }}</p>

      <div class="badges-content">
        <div class="badges-achieved">
          <p>{{ 'dashboard.user.whatIachieved' | translate }}</p>
          <div class="badges-rank">
            <img src="assets/mini-walbox.svg" alt=""/>
            <h6>Schnelllader Silber</h6>
            <p>Toll, du hast 3x geladen!</p>
          </div>
          <div class="badges-rank">
            <img src="assets/mini-walbox.svg" alt=""/>
            <h6>Schnelllader Silber</h6>
            <p>Toll, du hast 3x geladen!</p>
          </div>
        </div>
        <div class="badges-next-achievement">
          <p>{{ 'dashboard.user.whatIwillAchieved' | translate }}</p>
          <div class="badges-rank">
            <img src="assets/mini-walbox.svg" alt=""/>
            <h6>Schnelllader Silber</h6>
            <p>3/50 der Aufgabe erfüllt</p>
          </div>
          <div class="badges-rank">
            <img src="assets/mini-walbox.svg" alt=""/>
            <h6>Schnelllader Silber</h6>
            <p>3/50 der Aufgabe erfüllt</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card dashboard-element maintenance">
    <div class="card-header">
      <h4>{{ 'dashboard.user.recentMaintenance' | translate }}</h4>
    </div>
    <div class="card-body">
      <div class="no-maintenance">{{ 'dashboard.user.noMaintenance' | translate }}</div>
    </div>
  </div>  
</div>
