import { ChargepointsModule } from '../chargepoints/chargepoints.module';
import { CommonModule } from '@angular/common';
import { ContentBackofficeComponent } from './content/content-backoffice/content-backoffice.component';
import { ContentComponent } from './content/content.component';
import { ContentUserComponent } from './content/content-user/content-user.component';
import { DashboardComponent } from './dashboard-main/dashboard.component';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
    declarations: [
        ContentBackofficeComponent,
        ContentComponent,
        ContentUserComponent,
        DashboardComponent,
    ],
    imports: [
        ChargepointsModule,
        CommonModule,
        TranslateModule,
        PipesModule,
    ],
})
export class DashboardModule {}
