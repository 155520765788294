import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SymbolBarComponent } from './symbol-bar.component';
import { TranslateModule } from '@ngx-translate/core';
import { SymbolBarService } from './symbol-bar.service';

@NgModule({
    declarations: [SymbolBarComponent],
    imports: [CommonModule, TranslateModule],
    exports: [SymbolBarComponent],
    providers: [SymbolBarService],
})
export class SymbolBarModule {}
