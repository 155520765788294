import { QueryRef } from 'apollo-angular';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import {
    DeleteChargePointGQL,
    AllChargePointsGQL,
    OneChargePointGQL,
    AllChargePointsQuery,
    OneChargePointQuery,
    Exact,
    CreateChargePointInput,
    CreateChargePointGQL,
    UpdateChargePointInput,
    UpdateChargePointGQL,
    ChargePoint,
    EvseStatus,
    ChargePointSort,
    ChargepointFilterInput,
} from '../graphql/generated/cp-graphql_types';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { UserDataService } from 'src/app/user-data.service';

export interface CPQueryVariables {
    first?: number | undefined;
    after?: string | undefined;
    last?: number | undefined;
    before?: string | undefined;
    sort?: ChargePointSort;
    filter?: ChargepointFilterInput | undefined;
}

@Injectable()
export class ChargepointService {
    filter$ = new BehaviorSubject<ChargepointFilterInput>({} as ChargepointFilterInput);
    flatFilterChips$ = new BehaviorSubject<Map<string, string | undefined>>(new Map<string, string>());
    pollQueryVariables$ = new BehaviorSubject({} as CPQueryVariables);
    private chargepointsQuery?: QueryRef<
        AllChargePointsQuery,
        Exact<{
            first?: number;
            after?: string;
            last?: number;
            before?: string;
            sort?: ChargePointSort;
            filter?: ChargepointFilterInput;
        }>
    >;
    // Mapping between Chip string and the actual Filter string
    chip2filter = new Map<string, string>([
        ['filter.search', 'search'],
        ['filter.createdAfter', 'createdAt.after'],
        ['filter.createdBefore', 'createdAt.before'],
        ['filter.lastActivityAfter', 'lastActivity.after'],
        ['filter.lastActivityBefore', 'lastActivity.before'],
        ['filter.connectionStatus', 'connectionStatus'],
    ]);

    constructor(
        private logger: NGXLogger,
        private allChargepointsGQL: AllChargePointsGQL,
        private oneChargepointGQL: OneChargePointGQL,
        private createChargePointGQL: CreateChargePointGQL,
        private deleteChargePointGQL: DeleteChargePointGQL,
        private updateChargePointGQL: UpdateChargePointGQL,
        private readonly translateService: TranslateService,
        public userDataService: UserDataService,
    ) {}

    flattenChargepoints(chargepoint: ChargePoint[]) {
        const { currentLang } = this.translateService;
        const ngPipe = new DatePipe(currentLang);
        let flatChargepointArray = [];
        let permissionArray = [];
        let owner;
        for (let i = 0; i < chargepoint.length; i++) {
            if (chargepoint[i].owner.__typename == 'User') {
                owner = chargepoint[i].owner.firstName + ' ' + chargepoint[i].owner.lastName;
            } else {
                owner = chargepoint[i].owner.name;
            }
            flatChargepointArray.push([
                chargepoint[i].id,
                chargepoint[i].name,
                owner,
                ngPipe.transform(chargepoint[i].lastActivity, 'mediumDate'),
                ngPipe.transform(chargepoint[i].createdAt, 'mediumDate'),
                chargepoint[i].occupancy,
            ]);
            permissionArray.push(this.hasPermission(chargepoint[i]));
        }
        return [flatChargepointArray, permissionArray];
    }

    getChargepoints(qVars: CPQueryVariables) {
        this.logger.debug(`ChargepointListService.getChargepoints()`);
        return this.allChargepointsGQL.watch(qVars).valueChanges.pipe(map(result => result.data.chargePoints));
    }

    getChargepoint(chargepointId: string): Observable<OneChargePointQuery['chargePoint']> {
        return this.oneChargepointGQL
            .watch({ cpId: chargepointId })
            .valueChanges.pipe(map(result => result.data.chargePoint));
    }

    refresh() {
        this.chargepointsQuery?.refetch();
    }

    deleteChargepoints(cpIds: string[]): Observable<any> {
        this.logger.debug('call service deleteChargepoints with ids:', cpIds);
        return this.deleteChargePointGQL.mutate({ cpIds: cpIds });
    }

    createChargepoint(chargePoint: CreateChargePointInput): Observable<any> {
        this.logger.debug('call service create chargePoint with chargePointName', chargePoint);
        return this.createChargePointGQL.mutate({
            createChargePoint: chargePoint,
        });
    }

    updateChargepoint(chargePoint: UpdateChargePointInput): Observable<any> {
        this.logger.debug('call service update Chargepoint with Chargepoint-ID', chargePoint);
        return this.updateChargePointGQL.mutate({
            updateChargePoint: chargePoint,
        });
    }

    hasIdleEvse(chargePoint: ChargePoint): boolean {
        const evseStates = chargePoint.evses?.map(evse => evse?.status);
        const freeEvses = evseStates?.filter(value => value === EvseStatus.Idle)?.length;
        return freeEvses === undefined || freeEvses > 0;
    }

    hasPermission(chargepoint: ChargePoint) {
        if (this.userDataService.checkPermission('chargepoint:modify:client')) {
            return true;
        } else if (
            this.userDataService.checkPermission('chargepoint:modify:company') &&
            chargepoint.owner.__typename === 'Company' &&
            chargepoint.owner.company?.id === this.userDataService.companyId()
        ) {
            return true;
        } else if (
            this.userDataService.checkPermission('chargepoint:modify:own') &&
            chargepoint.owner.id == this.userDataService.userId()
        ) {
            return true;
        }
        return false;
    }

    deleteFilter(chipKey: string) {
        let filterKey: string | undefined = this.chip2filter.get(chipKey);
        let tmp = Object.fromEntries(Object.entries(this.filter$.getValue()).filter(([k, _]) => k != filterKey));

        if (filterKey?.includes('.')) {
            let [key, nestedKey] = filterKey.split('.');
            delete tmp[key][nestedKey];
        }
        this.filter$.next(tmp);
        this.createChips(tmp);
    }

    createChips(filter: ChargepointFilterInput) {
        let flatFilter = new Map<string, string | undefined>();
        filter.search?.contains?.length ? flatFilter.set('filter.search', filter.search?.contains) : '';
        filter.createdAt?.after ? flatFilter.set('filter.createdAfter', filter.createdAt.after.toString()) : '';
        filter.createdAt?.before ? flatFilter.set('filter.createdBefore', filter.createdAt.before.toString()) : '';
        filter.lastActivity?.after
            ? flatFilter.set('filter.lastActivityAfter', filter.lastActivity.after.toString())
            : '';
        filter.lastActivity?.before
            ? flatFilter.set('filter.lastActivityBefore', filter.lastActivity.before.toString())
            : '';
        filter.connectionStatus ? flatFilter.set('filter.connectionStatus', filter.connectionStatus?.toString()) : '';
        this.flatFilterChips$.next(flatFilter);
    }
}
