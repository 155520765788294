import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {DatePipe} from "@angular/common";

export type LocalizedDatePattern = 'time' | 'date' | 'date-time-short' | 'date-time-long' | 'date-month-year'

@Pipe({
    name: 'localizedDate'
})
export class LocalizedDatePipe implements PipeTransform {
    constructor(private readonly translateService: TranslateService,
                private readonly datePipe: DatePipe) {
    }

    transform(value: Date | string | number | null | undefined , pattern: LocalizedDatePattern): string {
        if(value === undefined ||value === null){
            return '-';
        }
        if (typeof value === 'string' || typeof value === 'number') {
            value = new Date(value);
        }
        const formatKey = `date-format.${pattern}`;
        const currentLang = this.translateService.currentLang;
        const translation = this.translateService.instant(formatKey);
        const formatted = this.datePipe.transform(value, translation, undefined, currentLang);
        if (!formatted) {
            return '-';
        }
        return formatted;
    }
}
