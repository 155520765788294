import { Component, Injectable, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

export interface DeleteDialogData {
    title: string;
    question: string;
    items: string[];
}

@Component({
    standalone: true,
    imports: [CommonModule, TranslateModule],
    selector: 'app-delete-dialog',
    templateUrl: './delete-dialog.component.html',
    styleUrls: ['./delete-dialog.component.scss'],
})
@Injectable()
export class DeleteDialogComponent {
    @Input() data = {} as DeleteDialogData;

    constructor(private logger: NGXLogger, public activeModal: NgbActiveModal) {
        this.logger.debug('DeleteDialogComponent.constructor()');
    }
}
