import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { getMonthName } from 'src/app/accounting/util/date.composable';

export interface Breadcrumb {
    text: string;
    path: string;
}
interface User {
    firstName: string;
    lastName: string;
}
@Injectable({
    providedIn: 'root',
})
export class BreadcrumbService {
    crumbs$ = new BehaviorSubject<Breadcrumb[]>([{ text: '', path: '' }]);
    constructor() { }

    chargepointCrumbs(depth: number, selection?: string, id?: string) {
        let crumbs: Breadcrumb[] = [
            { text: 'crumbs.chargepoints', path: 'chargepoints/chargepoints' },
            { text: `${selection}`, path: `chargepoints/chargepoints/${id}` },
        ];
        this.crumbs$.next(crumbs.slice(0, depth));
    }
    wallboxesCrumbs(depth: number, selection?: string, id?: string) {
        let crumbs: Breadcrumb[] = [
            { text: 'crumbs.wallboxes', path: 'chargepoints/wallboxes' },
            { text: `${selection}`, path: `chargepoints/wallboxes/${id}` },
        ];
        this.crumbs$.next(crumbs.slice(0, depth));
    }

    compatibleWallboxesCrumbs(depth: number, selection?: string, id?: string) {
        let crumbs: Breadcrumb[] = [
            { text: 'crumbs.compatibleWallboxes', path: 'chargepoints/compatible-wallboxes' },
            { text: `${selection}`, path: `chargepoints/compatible-wallboxes/${id}` },
        ];
        this.crumbs$.next(crumbs.slice(0, depth));
    }

    reportingCrumbs(depth: number, selection?: string) {
        let crumbs: Breadcrumb[] = [
            { text: 'crumbs.reporting', path: 'reporting/all' },
            { text: `reporting.tab.${selection}`, path: `reporting/${selection}` },
        ];
        this.crumbs$.next(crumbs.slice(0, depth));
    }

    masterDataCrumbs(depth: number, selection?: string, id?: string) {
        let crumbs: Breadcrumb[] = [
            { text: 'crumbs.masterData', path: 'master-data/' },
            { text: `crumbs.${selection}`, path: `master-data/${selection}` },
            { text: `crumbs.details`, path: `master-data/${selection}/${id}` },
        ];
        this.crumbs$.next(crumbs.slice(0, depth));
    }

    accountingCrumbs(
        depth: number,
        user?: User,
        userId?: string,
        date = [0, 0],
        invoiceId?: string,
        exportUrl?: string,
    ) {
        let crumbs: Breadcrumb[] = [
            { text: 'crumbs.users', path: 'accounting/users' },
            {
                text: `${user?.lastName}, ${user?.firstName}`,
                path: `accounting/user/${userId}/invoices/`,
            },
            {
                text: `${this.translateMonth(date[0])} ${date[1]}`,
                path: `accounting/user/${userId}/invoices/${invoiceId}`,
            },
            { text: 'crumbs.export', path: `${exportUrl}` },
        ];
        this.crumbs$.next(crumbs.slice(0, depth));
    }

    private translateMonth(month: number): string {
        const monthIndex = month ?? 0;
        return getMonthName(monthIndex);
    }
}
