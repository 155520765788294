<div class="row flex-wrap justify-content-start align-items-center g-4">
  <app-accounting-loading-spinner *ngIf="loadingPeriods else showPeriods"
                                  [loadingMessage]="'accounting.loadingInvoices' | translate"></app-accounting-loading-spinner>
</div>
<ng-template #showPeriods>
  <h5 class="text-danger" *ngIf="invoices && invoices.length === 0 else showPeriodList">{{ 'accounting.noInvoices' | translate }}</h5>
</ng-template>
<ng-template #showPeriodList>
  <div *ngFor="let invoice of invoices" class="col-12 col-sm-6 col-lg-4 col-xl-3">
    <app-accounting-user-period [invoice]="invoice"></app-accounting-user-period>
  </div>
</ng-template>
