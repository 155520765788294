import { Injectable } from '@angular/core';
import { QueryRef } from 'apollo-angular';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    AllVehiclesGQL,
    AllVehiclesQuery,
    OneVehicleGQL,
    OneVehicleQuery,
    CreateVehicleGQL,
    CreateVehicleInput,
    DeleteVehicleGQL,
    UpdateVehicleGQL,
    UpdateVehicleInput,
    Vehicle,
} from '../graphql/generated/mdm_graphql_types';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class VehicleService {
    vehicleQueryRef: QueryRef<AllVehiclesQuery, {}> | undefined;
    constructor(
        private logger: NGXLogger,
        private allVehiclesGql: AllVehiclesGQL,
        private oneVehicleGql: OneVehicleGQL,
        public delVehicleGql: DeleteVehicleGQL,
        private createVehicleGQL: CreateVehicleGQL,
        private updateVehicleGQL: UpdateVehicleGQL,
        private readonly translateService: TranslateService
    ) {}

    flattenVehicles(vehicles: Vehicle[]) {
        const { currentLang } = this.translateService;
        const ngPipe = new DatePipe(currentLang);

        let flatVehicleArray = [];
        let permissionArray = [];
        let name;
        for (let i = 0; i < vehicles.length; i++) {
            if (vehicles[i].company) {
                name = vehicles[i].company?.name;
            } else {
                let firstName = vehicles[i].owner?.firstName || '';
                let lastName = vehicles[i].owner?.lastName || '';
                name = firstName + ' ' + lastName;
            }

            flatVehicleArray.push([
                vehicles[i].id,
                name,
                vehicles[i].licensePlate,
                vehicles[i].vendor,
                vehicles[i].model,
                ngPipe.transform(vehicles[i].createdAt, 'mediumDate'),
            ]);
            permissionArray.push(1);
        }
        return [flatVehicleArray, permissionArray];
    }

    getVehicles(): Observable<AllVehiclesQuery['vehicles']> {
        this.vehicleQueryRef = this.allVehiclesGql.watch();
        return this.vehicleQueryRef.valueChanges.pipe(map(result => result.data.vehicles));
    }

    getVehicle(vehicleId: string): Observable<OneVehicleQuery['vehicle']> {
        return this.oneVehicleGql.watch({ vehicleId: vehicleId }).valueChanges.pipe(map(result => result.data.vehicle));
    }

    refresh() {
        this.logger.debug('call refresh for reloading data');
        this.vehicleQueryRef?.refetch();
    }

    deleteVehicle(vehicles: string[]): Observable<any> {
        return this.delVehicleGql.mutate({ vehicleId: vehicles });
    }

    createVehicle(vehicle: CreateVehicleInput): Observable<any> {
        this.logger.debug('call service create vehicle with vehicleName', vehicle);
        return this.createVehicleGQL.mutate({ createVehicle: vehicle });
    }

    updateVehicle(vehicle: UpdateVehicleInput): Observable<any> {
        this.logger.debug('call service update vehicle with vehicle-ID', vehicle);
        return this.updateVehicleGQL.mutate({ updateVehicle: vehicle });
    }
}
