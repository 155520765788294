import { Component, computed, OnDestroy, OnInit, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountingService } from '../service/accounting.service';
import { Subscription } from 'rxjs';
import { User } from '../graphql/generated/accounting_graphql_types';
import { getMonthName } from '../util/date.composable';
import { TranslateService } from '@ngx-translate/core';
import { buildTableInvoiceTransaction, TableInvoiceTransaction } from './table-invoice';
import { NGXLogger } from 'ngx-logger';
import { BreadcrumbService } from 'src/app/layout/breadcrumb/breadcrumb.service';

@Component({
    selector: 'app-accounting-user-period-invoice-list',
    templateUrl: './user-period-invoice-list.component.html',
    styleUrls: ['./user-period-invoice-list.component.scss'],
})
export class UserPeriodInvoiceListComponent implements OnInit, OnDestroy {
    invoiceId: string = this.route.snapshot.params['invoiceId'];
    userId: string = this.route.snapshot.params['id'];

    loadingInvoice: boolean = true;
    user!: User;
    month_invoice!: number;
    year_invoice!: number;

    private transactionsSubscription: Subscription | undefined;

    pageId: number = 0;
    pageSize: number = 10;
    totalPages: number = 0;
    page: TableInvoiceTransaction[] = [];
    selected = signal<TableInvoiceTransaction[]>([]);

    readonly pageSizeOptions = [10, 20, 30, 40, 50, -1];

    readonly hasSelected = computed<boolean>(() => this.selected().length > 0);

    constructor(
        private readonly router: Router,
        private readonly accountingService: AccountingService,
        private readonly route: ActivatedRoute,
        private readonly translateService: TranslateService,
        private readonly logger: NGXLogger,
        private breadcrumbService: BreadcrumbService,
    ) {}

    ngOnInit() {
        this.getInvoiceById(this.invoiceId, this.pageId, this.pageSize, true, true).then((date: any) => {
            this.breadcrumbService.accountingCrumbs(3, this.user, this.userId, date, this.invoiceId);
            this.loadingInvoice = false;
        });
    }

    getInvoiceById(invoiceId: string, pageId: number, pageSize: number, info: boolean, pageSizeChange: boolean) {
        const promise = new Promise((resolve, reject) => {
            this.accountingService.getInvoiceById(invoiceId, pageId, pageSize, info, pageSizeChange).subscribe({
                next: (value: any) => {
                    this.user = { firstName: value.user.firstName, lastName: value.user.lastName } as User;
                    this.year_invoice = value.year;
                    this.month_invoice = value.month;
                    this.totalPages = value.recordedPositions.totalPages;
                    let recordedPositions = value.recordedPositions.content.map((transaction: any) =>
                        buildTableInvoiceTransaction(transaction),
                    );
                    this.page = recordedPositions;

                    resolve([value.month, value.year]);
                },
                error: e => {
                    this.logger.debug(e);
                    reject();
                },
            });
        });
        return promise;
    }

    getInvoiceTransactions(
        invoiceId: string,
        pageId: number,
        pageSize: number,
        info: boolean,
        pageSizeChange: boolean,
    ) {
        this.accountingService.getInvoiceById(invoiceId, pageId, pageSize, info, pageSizeChange).subscribe({
            next: (value: any) => {
                if (pageSizeChange) {
                    this.totalPages = value.recordedPositions.totalPages;
                }
                let recordedPositions = value.recordedPositions.content.map((transaction: any) =>
                    buildTableInvoiceTransaction(transaction),
                );
                this.page = recordedPositions;
            },
            error: e => {
                this.logger.debug(e);
            },
        });
    }

    ngOnDestroy() {
        this.transactionsSubscription?.unsubscribe();
        this.breadcrumbService.accountingCrumbs(0);
    }

    public selectAll(event: Event) {
        const target = event.target as HTMLInputElement;
        if (!target) {
            return;
        }
        this.modifySelected(this.page, target.checked);
    }

    public select(event: Event, transaction: TableInvoiceTransaction) {
        const target = event.target as HTMLInputElement;
        if (!target) {
            return;
        }
        this.modifySelected(transaction, target.checked);
    }

    private modifySelected(modify: TableInvoiceTransaction[] | TableInvoiceTransaction, select: boolean) {
        if (select) {
            if (Array.isArray(modify)) {
                this.selected.update(value => [...modify, ...value]);
            } else {
                this.selected.update(value => [modify, ...value]);
            }
        } else {
            if (Array.isArray(modify)) {
                this.selected.update(value => value.filter(value => !modify.includes(value)));
            } else {
                this.selected.update(value => value.filter(value => value !== value));
            }
        }
    }

    public pageChange(offset: number) {
        const newPageId = this.pageId + offset;
        if (newPageId < 0 || newPageId >= this.totalPages) {
            return;
        }
        this.pageId = newPageId;
        this.getInvoiceTransactions(this.invoiceId, newPageId, this.pageSize, false, false);
    }

    public pageSizeUpdate(event: Event) {
        const element = event.target as HTMLSelectElement;
        if (!element) {
            return;
        }
        this.pageSize = parseInt(element.value);
        this.pageId = 0;
        this.getInvoiceTransactions(this.invoiceId, this.pageId, this.pageSize, false, true);
    }

    public export() {
        const transactionsSelected = btoa(JSON.stringify(this.selected()));
        this.router.navigate([
            'accounting',
            'user',
            this.userId,
            'invoices',
            this.invoiceId,
            'export',
            transactionsSelected,
        ]);
    }

    public delete() {
        this.selected().forEach(invoice => {
            this.logger.log('Delete-Invoice: ', invoice);
        });
    }

    public get month(): string {
        const month = this.month_invoice ?? 0;
        return this.translateService.instant(getMonthName(month));
    }

    public get year(): number {
        return this.year_invoice ?? 0;
    }
}
