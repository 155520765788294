import { CommonModule } from '@angular/common';
import { LocalizedDatePipe } from './localized-date.pipe';
import { NgModule } from '@angular/core';
import {DurationPipe} from "./duration.pipe";

@NgModule({
    declarations: [LocalizedDatePipe, DurationPipe],
    imports: [CommonModule],
    exports: [LocalizedDatePipe, DurationPipe],
})
export class PipesModule {}
