import {Pipe, PipeTransform} from '@angular/core';
import {User} from "../graphql/generated/accounting_graphql_types";

@Pipe({
    name: 'userName'
})
export class UserNamePipe implements PipeTransform {
    transform(value: Pick<User, 'firstName' | 'lastName' | 'email'> | Pick<User, 'id' | 'firstName' | 'lastName'> | null, separated: boolean): string {
        if (!value) {
            return '/';
        }
        if (separated) {
            return `${value.lastName}, ${value.firstName}`;
        }
        return `${value.firstName} ${value.lastName}`
    }
}
