import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserDataService } from '../../../user-data.service';
import { User } from '../../user';
import { ProfileEditDialogComponent } from '../../profile-edit-dialog/profile-edit-dialog.component';
import { NGXLogger } from 'ngx-logger';

@Component({
    selector: 'app-personal-data',
    templateUrl: './personal-data.component.html',
    styleUrls: ['./personal-data.component.scss'],
})
export class PersonalDataComponent {
    readonly user: User;
    company: any;

    constructor(
        private readonly userService: UserDataService,
        private readonly logger: NGXLogger,
        private modalService: NgbModal,
    ) {
        logger.debug('PersonalDataComponent.constructor()');
        this.user = this.userService.getUser();
        this.userService.getCompanyQuery().subscribe((company: any) => {
            this.company = company;
        });
    }

    openEditDialog() {
        this.logger.debug('PersonalDataComponent.openEditDialog()');
        this.modalService.open(ProfileEditDialogComponent);
    }
}
