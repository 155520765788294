import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeyedCSV, toCSV } from '../util/csv.composable';
import { fileDownload } from '../util/file.composable';
import { Invoice, User, UserAddress } from '../graphql/generated/accounting_graphql_types';
import { TableInvoiceTransaction } from '../user-period-invoice-list/table-invoice';
import { getMonthName } from '../util/date.composable';
import { TranslateService } from '@ngx-translate/core';
import { AccountingService } from '../service/accounting.service';
import { BreadcrumbService } from 'src/app/layout/breadcrumb/breadcrumb.service';

@Component({
    selector: 'app-accounting-user-invoice-export',
    templateUrl: './user-invoice-export.component.html',
    styleUrls: ['./user-invoice-export.component.scss'],
})
export class UserInvoiceExportComponent {
    address!: UserAddress;
    user!: User;
    invoice!: Invoice;
    transactions: TableInvoiceTransaction[] = [];
    invoiceId: string = this.route.snapshot.params['invoiceId'];
    userId: string = this.route.snapshot.params['id'];
    crumbs$ = this.breadcrumbService.crumbs$;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly accountingService: AccountingService,
        private breadcrumbService: BreadcrumbService,
        private readonly translateService: TranslateService,
    ) {}

    ngOnInit(): void {
        this.transactions = this.loadTransactionsSelected();
        this.getInvoiceById(this.invoiceId, 0, 10, true, true).then((date: any) => {
            this.breadcrumbService.accountingCrumbs(4, this.user, this.userId, date, this.invoiceId, this.router.url);
        });
    }
    ngOnDestroy() {
        this.breadcrumbService.accountingCrumbs(0);
    }

    getInvoiceById(invoiceId: string, pageId: number, pageSize: number, info: boolean, pageSizeChange: boolean) {
        const promise = new Promise((resolve, reject) => {
            this.accountingService.getInvoiceById(invoiceId, pageId, pageSize, info, pageSizeChange).subscribe({
                next: (value: any) => {
                    this.invoice = value;
                    this.user = value.user;
                    this.address = value.user.address;
                    resolve([value.month, value.year]);
                },
                error: e => reject(),
            });
        });
        return promise;
    }

    public downloadSelectedInvoices() {
        const fileName = `invoice_${this.user.firstName.toLowerCase()}_${this.user.lastName.toLowerCase()}.csv`;
        const fileContent = toCSV(this.transactions, [
            new KeyedCSV('Kennzeichen', 'plate'),
            new KeyedCSV('Beginn', 'timeStart'),
            new KeyedCSV('Ende', 'timeStop'),
            new KeyedCSV('Dauer', 'duration'),
            new KeyedCSV('Standort', 'location'),
            new KeyedCSV('Subjekt', 'chargePoint'),
            new KeyedCSV('Kosten', 'cost'),
        ]);
        const fileType = 'text/csv';
        fileDownload(fileName, fileContent, fileType);
    }

    private loadTransactionsSelected(): TableInvoiceTransaction[] {
        const transactionsSelected = this.route.snapshot.params['transactionsSelected'];
        if (!transactionsSelected) {
            return [];
        }
        return JSON.parse(atob(transactionsSelected)) as Array<TableInvoiceTransaction>;
    }

    public get month(): string {
        const month = this.invoice?.month ?? 0;
        return this.translateService.instant(getMonthName(month));
    }

    public get year() {
        return this.invoice?.year ?? 0;
    }

    public get totalCost(): number {
        return this.transactions.map(transaction => transaction.cost).reduce((previous, current) => previous + current);
    }
}
