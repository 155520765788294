import {Component, signal} from '@angular/core';
import { BreadcrumbService } from 'src/app/layout/breadcrumb/breadcrumb.service';
import {PaginatedCollection} from "../../accounting/util/pagination.composable";
import {ReportingService} from "../reporting.service";
import {buildManualTransactionTableModels, ManualTransactionTableModel} from "./manual-transaction.table.model";

@Component({
    selector: 'app-manual-transactions',
    templateUrl: './manual-transactions.component.html',
    styleUrls: ['../reporting.scss', './manual-transactions.component.scss']
})
export class ManualTransactionsComponent {
    transactions: Array<ManualTransactionTableModel> = [];
    transactionsDisplayed = signal<ManualTransactionTableModel[]>([]);
    pages: PaginatedCollection<ManualTransactionTableModel> = PaginatedCollection.EMPTY;

    constructor(private readonly service: ReportingService, private breadcrumbService: BreadcrumbService) {
    }

    ngOnInit() {
        this.breadcrumbService.reportingCrumbs(2, "manual")
        this.service.getManualTransactions().subscribe(value => {
            this.transactions = buildManualTransactionTableModels(value);
            this.pages = new PaginatedCollection<ManualTransactionTableModel>(this.transactions, 5);
            this.transactionsDisplayed.set(this.pages.page);
        });
    }

    ngOnDestroy(): void {
        this.breadcrumbService.reportingCrumbs(0);
    }

    previous() {
        this.pages.pageChange(-1);
        this.transactionsDisplayed.set(this.pages.page);
    }

    next() {
        this.pages.pageChange(1);
        this.transactionsDisplayed.set(this.pages.page);
    }
}
