import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    AllCompaniesGQL,
    AllCompaniesQuery,
    AllIdTokenGroupsGQL,
    AllUsersGQL,
    AllUsersQuery,
    IdToken,
    IdTokenGroup,
    IdTokenType,
} from '../../graphql/generated/mdm_graphql_types';
import { IdTokenService } from '../id-token-list.service';
import { DriverService } from '../../driver-list/driver-list.service';
import { BehaviorSubject } from 'rxjs';

/**
 * Component for the tag <code>app-edit-id-token-dialog</code>
 * to display a dialog to add a id-token.
 */

@Component({
    selector: 'app-edit-id-token-dialog',
    templateUrl: './edit-id-token-dialog.component.html',
    styleUrls: ['./edit-id-token-dialog.component.scss'],
})
export class EditIdTokenDialogComponent implements OnInit {
    @Input() idTokenId: string | undefined;
    companies: Observable<AllCompaniesQuery['companies']>;
    users: Observable<AllUsersQuery['users']>;
    selectedCompanyIdTokenGroups: any = [];
    initialToken: IdToken;
    editing = new BehaviorSubject(false);
    idTokenForm: FormGroup;
    date = new Date();
    idTokenGroups: IdTokenGroup[] = [];
    setOwner: string = '';
    /**
     * Creates an instance of AddIdTokenDialogComponent.
     * @param idTokenService
     * @param logger Service for logging.
     * @param translate
     * @param companiesQl
     * @param usersQl
     * @param fb
     * @param activeModal
     */
    constructor(
        private idTokenService: IdTokenService,
        private driverService: DriverService,
        private logger: NGXLogger,
        private companiesQl: AllCompaniesGQL,
        private usersQl: AllUsersGQL,
        private idTokenGroupsQl: AllIdTokenGroupsGQL,
        private fb: FormBuilder,
        public activeModal: NgbActiveModal,
    ) {
        logger.debug(
            'EditIdTokenDialogComponent.constructor() with data:',
            this.idTokenId,
        );
        this.initialToken = {} as IdToken;
        this.idTokenForm = this.fb.group({
            active: [true],
            name: ['', [Validators.required, Validators.minLength(2)]],
            idTag: ['', [Validators.required, Validators.minLength(2)]],
            owner: ['user'],
            company: ['', [Validators.minLength(2)]],
            groupId: ['', [Validators.required, Validators.minLength(2)]],
            validAt: [
                {
                    year: this.date.getFullYear(),
                    month: this.date.getMonth() + 1,
                    day: this.date.getDate(),
                },
            ],
            validUntil: [null],
            type: [IdTokenType.Iso14443],
        });

        this.companies = companiesQl
            .watch()
            .valueChanges.pipe(map(result => result.data.companies));

        this.users = usersQl
            .watch()
            .valueChanges.pipe(map(result => result.data.users));

        idTokenGroupsQl
            .watch()
            .valueChanges.pipe(
                map(
                    (result: any) =>
                        (this.idTokenGroups = result.data.idTokenGroups),
                ),
            )
            .subscribe();
    }

    ngOnInit() {
        if (
            this.idTokenId !== undefined &&
            this.idTokenId !== '' &&
            this.idTokenId !== null
        ) {
            this.logger.debug('Load IdToken from server ID=', this.idTokenId);
            this.idTokenService
                .getIdToken(this.idTokenId)
                .subscribe((result: any) => {
                    this.initialToken = result;
                    this.setFormData(this.initialToken);
                });
        }
    }

    getDateFromForm(formField: string) {
        let ngbDate = this.idTokenForm.get(formField)?.value;
        if (ngbDate) {
            return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
        }
        return null;
    }

    dateToNgbDateStruct(dateString: string) {
        if (dateString) {
            let date: Date = new Date(dateString);
            return {
                year: date.getFullYear(),
                month: date.getMonth() + 1,
                day: date.getDate(),
            };
        }
        return '';
    }

    get idTokenName() {
        return this.idTokenForm.controls.name;
    }

    get idTag() {
        return this.idTokenForm.controls.idTag;
    }

    get owner() {
        return this.idTokenForm.controls.owner;
    }

    get company() {
        return this.idTokenForm.controls.company;
    }

    get groupId() {
        return this.idTokenForm.controls.groupId;
    }

    get validAt() {
        return this.idTokenForm.controls.validAt;
    }

    get validUntil() {
        return this.idTokenForm.controls.validUntil;
    }

    addIdToken() {
        this.idTokenService
            .createIdToken({
                active: this.idTokenForm.value.active,
                groupId: this.idTokenForm.value.groupId,
                idTag: this.idTokenForm.value.idTag,
                name: this.idTokenForm.value.name,
                type: this.idTokenForm.value.type,
                validAt: this.getDateFromForm('validAt'),
                validUntil: this.getDateFromForm('validUntil'),
            })
            .subscribe((data: {}) => {
                this.logger.debug('create new Id-Token RC=', data);
                this.idTokenService.refresh();
                this.driverService.refresh();
            });
    }

    editIdToken() {
        this.idTokenService
            .updateIdToken({
                active: this.idTokenForm.value.active,
                groupId: this.idTokenForm.value.groupId,
                id: this.initialToken.id,
                idTag: this.idTokenForm.value.idTag,
                name: this.idTokenForm.value.name,
                type: this.idTokenForm.value.type,
                validAt: this.getDateFromForm('validAt'),
                validUntil: this.getDateFromForm('validUntil'),
                version: this.initialToken.version,
            })
            .subscribe((data: {}) => {
                this.logger.debug('update Id-Token RC=', data);
                this.idTokenService.refresh();
                this.driverService.refresh();
            });
        this.isEditing(false);
    }

    onCompanySelectionChange(selectedCompanyId: any) {
        this.logger.debug(
            'Company selection changed. new selection=',
            selectedCompanyId,
        );
        this.selectedCompanyIdTokenGroups = this.idTokenGroups.filter(
            idToken => idToken.company?.id == selectedCompanyId,
        );
    }

    onIdTokenGroupChange(selectedIdTokenGroupId: string) {
        this.logger.debug(
            'IdTokenGroup selection changed. new selection=',
            selectedIdTokenGroupId,
        );
    }

    isEditing(editStatus: boolean) {
        this.editing.next(editStatus);
    }

    private setFormData(idToken: IdToken) {
        this.isEditing(true);
        this.setOwner = idToken.group.company ? 'company' : 'user';
        this.onCompanySelectionChange(idToken.group.company?.id);
        this.date = idToken.validAt;
        this.idTokenForm.patchValue({
            active: idToken.active,
            name: idToken.name,
            idTag: idToken.idTag,
            owner: this.setOwner,
            company: idToken.group.company?.id,
            groupId: idToken.group.id,
            validAt: this.dateToNgbDateStruct(idToken.validAt),
            validUntil: this.dateToNgbDateStruct(idToken.validUntil),
            type: idToken.type,
        });
    }
}
