import {
    Directive,
    EventEmitter,
    HostListener,
    Input,
    Output,
} from '@angular/core';
import { TransactionHistory } from './chargepoint-details.component';
export type SortColumn = keyof TransactionHistory | '';
export type SortDirection = 'asc' | 'desc' | 'init';
const rotate: { [key: string]: SortDirection } = {
    asc: 'desc',
    desc: 'init',
    init: 'asc',
};

export interface SortEvent {
    column: SortColumn;
    direction: SortDirection;
}

@Directive({
    selector: 'th[sortable]',
    host: {
        '[class.asc]': 'direction === "asc"',
        '[class.desc]': 'direction === "desc"',
        '[class.init]': 'direction === "init"',
    },
})
export class NgbdSortableHeaderDirective {
    @Input() sortable: SortColumn = '';
    @Input() direction: SortDirection = 'init';
    @Output() sort = new EventEmitter<SortEvent>();

    @HostListener('click') rotate() {
        this.direction = rotate[this.direction];
        this.sort.emit({ column: this.sortable, direction: this.direction });
    }
}
