<article class="container">
  <h4>{{ 'profileData.editPersonalData' | translate}}</h4>
  <form [formGroup]="editForm" class="needs-validation">
    <main>
      <section>
        <label class="form-label required" for="first-name">
          {{ 'profileData.firstName' | translate}}
        </label>
        <input
          id="first-name"
          type="text"
          formControlName="firstName"
          class="form-control"
          [ngClass]="{
          'is-invalid': firstName.invalid && firstName.touched
          }">
      </section>
      <section>
        <label class="form-label required" for="last-name">
          {{ 'profileData.lastName' | translate}}
        </label>
        <input
          id="last-name"
          type="text"
          formControlName="lastName"
          class="form-control"
          [ngClass]="{
          'is-invalid': lastName.invalid && lastName.touched
          }">
      </section>
      <section>
        <label class="form-label required" for="street">
          {{ 'profileData.street' | translate}}
        </label>
        <input
          id="street"
          type="text"
          formControlName="street"
          class="form-control"
          [ngClass]="{
          'is-invalid': street.invalid && street.touched
          }">
      </section>
      <section>
        <label class="form-label required" for="house-number">
          {{ 'profileData.houseNumber' | translate}}
        </label>
        <input
          id="house-number"
          type="text"
          formControlName="houseNumber"
          class="form-control"
          [ngClass]="{
          'is-invalid': houseNumber.invalid && houseNumber.touched
          }">
      </section>
      <section>
        <label class="form-label required" for="postal-code">
          {{ 'profileData.postalCode' | translate}}
        </label>
        <input
          id="postal-code"
          type="text"
          formControlName="zipCode"
          class="form-control"
          [ngClass]="{
          'is-invalid': zipCode.invalid && zipCode.touched
          }">
      </section>
      <section>
        <label class="form-label required" for="town">
          {{ 'profileData.town' | translate}}
        </label>
        <input
          id="town"
          type="text"
          formControlName="town"
          class="form-control"
          [ngClass]="{
          'is-invalid': town.invalid && town.touched
          }">
      </section>
    </main>
    <footer>
      <button
        class="bs-primary-button btn-block btn"
        (click)="updateUser()"
        type="submit"
      >
        {{ 'profileData.save' | translate}}
      </button>
      <button
        class="bs-secondary-button btn-block btn"
        (click)="activeModal.dismiss('cancel')"
        type="button"
      >
        {{ 'profileData.cancel' | translate}}
      </button>
    </footer>
  </form>
</article>
