import {Language} from "./language";

export const SUPPORTED_LANGUAGES: Language[] = [
    {code: 'de', label: 'language.german'},
    {code: 'en', label: 'language.english'},
];

export const MONTHS = [
    {name: 'january', label: 'general.january'},
    {name: 'february', label: 'general.february'},
    {name: 'march', label: 'general.march'},
    {name: 'april', label: 'general.april'},
    {name: 'may', label: 'general.may'},
    { name: 'june', label: 'general.june' },
    { name: 'july', label: 'general.july' },
    { name: 'august', label: 'general.august' },
    { name: 'september', label: 'general.september' },
    { name: 'october', label: 'general.october' },
    { name: 'november', label: 'general.november' },
    { name: 'december', label: 'general.december' },
];
