import {CommonModule, NgOptimizedImage} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {ProfileEditDialogComponent} from './profile-edit-dialog/profile-edit-dialog.component';
import {ProfileViewComponent} from './profile-view/profile-view.component';
import {NGXLogger} from 'ngx-logger';
import {ProfileComponent} from './profile.component';
import {PersonalDataComponent} from './profile-view/personal-data/personal-data.component';
import {SettingsComponent} from './profile-view/settings/settings.component';
import {PipesModule} from "../pipes/pipes.module";

@NgModule({
    declarations: [
        ProfileEditDialogComponent,
        ProfileViewComponent,
        ProfileComponent,
        PersonalDataComponent,
        SettingsComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        PipesModule,
        NgOptimizedImage,
    ],
})
export class ProfileModule {
    constructor(private readonly logger: NGXLogger) {
        logger.debug(`Create ProfileModule`);
    }
}
