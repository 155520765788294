export function getMonthName(month: number) {
    switch (month) {
        case 1:
            return 'general.january';
        case 2:
            return 'general.february';
        case 3:
            return 'general.march';
        case 4:
            return 'general.april';
        case 5:
            return 'general.may';
        case 6:
            return 'general.june';
        case 7:
            return 'general.july';
        case 8:
            return 'general.august';
        case 9:
            return 'general.september';
        case 10:
            return 'general.october';
        case 11:
            return 'general.november';
        case 12:
            return 'general.december';
        default:
            return 'error'
    }
}
