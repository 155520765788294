<div id="accountingModal" class="modal" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content p-3">
      <div class="modal-header border-0">
        <h5 class="modal-title fw-bolder">Auswahl exportieren oder löschen</h5>
      </div>
      <div class="modal-body mb-5">
        <p>Möchtest du die Auswahl exportieren oder löschen?</p>
      </div>
      <div class="modal-footer border-0 justify-content-between">
        <button type="button" class="btn btn-elastic-outlined">
          <span>Löschen</span>
        </button>
        <div class="d-flex justify-content-end align-items-center gap-3">
          <button type="button" class="btn btn-elastic-outlined" data-bs-dismiss="modal">
            <span>Abbrechen</span>
          </button>
          <button type="button" class="btn btn-elastic" (click)="toInvoiceExport()">
            <span>Exportieren</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
