import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DriverService } from './driver-list.service';
import { EditDriverDialogComponent } from './edit-driver-dialog/edit-driver-dialog.component';
import { NGXLogger } from 'ngx-logger';
import { UserDataService } from '../../user-data.service';
import { ErrorHandler } from '../shared/md-error-handler.service';
import { User } from '../graphql/generated/mdm_graphql_types';
import { BreadcrumbService } from 'src/app/layout/breadcrumb/breadcrumb.service';
import { DeleteDialogComponent, DeleteDialogData } from 'src/app/shared/delete-dialog/delete-dialog.component';

/**
 * Component for the tag <code>app-driver-list</code> to display
 * the table of drivers.
 */
@Component({
    selector: 'app-driver-list',
    templateUrl: './driver-list.component.html',
    styleUrls: ['./driver-list.component.scss'],
})
export class DriverListComponent implements OnInit, OnDestroy {
    users: User[] = [];
    flatUsers: any[] = [];
    colNames: string[] = [
        'drivers.firstName',
        'drivers.lastName',
        'drivers.vehicles',
        'drivers.chargepoints',
        'drivers.idTokens',
    ];
    addDialog = 'drivers.addDriver';
    loading = false;
    checked: Array<string> = [];

    constructor(
        private driverService: DriverService,
        private logger: NGXLogger,
        private modalService: NgbModal,
        public errorHandler: ErrorHandler,
        private userDataService: UserDataService,
        private breadcrumbService: BreadcrumbService,
    ) {
        logger.debug('DriverListComponent.constructor()');
    }

    /**
     * Starts the search for drivers.
     */
    ngOnInit() {
        this.breadcrumbService.masterDataCrumbs(2, 'drivers');
        this.getDrivers();
    }
    updateChecked(checked: string[]) {
        this.checked = checked;
    }

    getDrivers() {
        this.logger.debug('DriverListComponent.getDrivers()');
        this.loading = true;
        this.driverService.getUsers().subscribe((result: any) => {
            this.users = result;
            this.flatUsers = this.driverService.flattenUsers(this.users);
            this.logger.debug('Drivers loaded: ', this.users.length);
            this.loading = false;
        });
    }

    delete(userIds: string[]) {
        let data = {} as DeleteDialogData;
        let users = this.users.filter(user => userIds.includes(user.id));
        this.logger.debug('Delete Drivers: ', users);
        data.items = users.map(user => user.firstName);

        if (users.length === 1) {
            data.title = 'driverDialog.titleDeleteOneDriver';
            data.question = 'driverDialog.questionDeleteSingle';
        } else {
            data.title = 'driverDialog.titleDeleteMultipleDriver';
            data.question = 'driverDialog.questionDeleteMulti';
        }

        const dialogRef = this.modalService.open(DeleteDialogComponent);
        dialogRef.componentInstance.data = data;
        dialogRef.result
            .then(() => {
                this.driverService.deleteUser(userIds).subscribe({
                    next: delResponse => this.logger.debug('Result of delete: ', delResponse),
                    error: (err: unknown) => this.logger.debug('An Error occured: ', err),
                    complete: () => {
                        this.checked = [];
                        this.driverService.refresh();
                    },
                });
                return 0;
            })
            .catch(cancel => {
                this.logger.debug('Dialog', cancel);
            });
    }

    /**
     * Opens the dialog to add a driver.
     */
    openDialogAdd() {
        this.logger.debug('Driver-ListComponent.openDialogAdd()');
        this.modalService.open(EditDriverDialogComponent);
    }

    openDialogEdit(userId: any) {
        this.logger.debug('driver-ListComponent.openDialogEdit()');
        let editDialogRef = this.modalService.open(EditDriverDialogComponent);
        editDialogRef.componentInstance.userId = userId;
    }

    ngOnDestroy(): void {
        this.breadcrumbService.masterDataCrumbs(0);
    }
}
