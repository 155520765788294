import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

/**
 * Converts a date and time format to a specific format at runtime.
 * Default is the English format.
 */
@Pipe({
    name: 'localizedDate',
    // eslint-disable-next-line @angular-eslint/no-pipe-impure
    pure: false,
})
export class LocalizedDatePipe implements PipeTransform {
    // eslint-disable-next-line no-useless-constructor, no-empty-function
    constructor(private readonly translateService: TranslateService) {}

    /**
     * Performs the transformation of the date and time format at runtime.
     * @param value This should be transformed.
     * @param [pattern=''] The pattern for the date and time format to transform to.
     * @return The transformed date and time.
     */
    public transform(
        value: Date | string,
        pattern = 'mediumDate',
    ): string | null {
        const { currentLang } = this.translateService;
        const ngPipe = new DatePipe(currentLang);
        return ngPipe.transform(value, pattern);
    }
}
